/* eslint-disable import/order */
// routes
// theme
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import Routes from './routes';
import { SignIn } from './AuthConfig';

export default function App() {
  const msalInstance = new PublicClientApplication(SignIn);
  return (
    <MsalProvider instance={msalInstance}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <ProgressBarStyle />
            <ScrollToTop />
            <Routes />
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </MsalProvider>
  );
}
