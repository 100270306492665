/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable arrow-body-style */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-unresolved */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Container, Grid, Stack, Typography, useTheme } from '@mui/material';
// utils
// eslint-disable-next-line import/no-unresolved
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// eslint-disable-next-line import/no-unresolved
import useSettings from 'src/hooks/useSettings';
// eslint-disable-next-line import/no-unresolved
import Page from 'src/components/Page';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved

// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import AxiosAll from 'src/service/AxiosAll';
import * as Yup from 'yup';

import EditProfileSkeleton from 'src/components/skeleton/editProfileSkeleton';
// import WorkingTimeDialog from 'src/sections/mui/dialog/workingTime';
import { yupResolver } from '@hookform/resolvers/yup';
import WorkingHoursDialog from 'src/sections/mui/dialog/workingHours';
import { FormProvider, RHFSelect, RHFTextField } from '../../components/hook-form';
// import Image from 'src/components/Image';
// import { imagesAll } from 'src/assets/Images_index';
// eslint-disable-next-line import/no-unresolved

// ----------------------------------------------------------------------
const label = { inputProps: { 'aria-label': 'Switch demo' } };
export default function MangeProfile() {
  // Initialize state variables
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const Organisation = ['Marketing', 'Sales', 'Digital', 'IT'];
  // const { enqueueSnackbar } = useSnackbar();
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const [teamDetail, setTeamDetail] = useState();
  const [loadingPage, setLoadingPage] = useState(false);
  const setting = localStorage.getItem('settings');
  const [memberDetail, setMemberDetail] = useState();
  const [uploadurl, setUploadUrl] = useState(false);
  const [imageError, setImageError] = useState('');
  const [checkMToF, setMToF] = useState(false);
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [day, setDay] = useState('MtoF');
  const [trip, setTrip] = useState('B');
  const [organisationOption, setOrganisationOption] = useState([]);
  const [days, setDays] = useState([]);

  const location = useLocation();
  const memberId = location?.state?.memberId;
  const iconshow = location?.state?.iconshow;

  const firstDayOfWeek = days?.length ? days[0]?.day : null;
  const lastDayOfWeek = days?.length && days?.length !== 1 ? days[days?.length - 1]?.day : null;
  const MemberDetailfirstDayOfWeek = memberDetail?.days?.length ? memberDetail?.days[0]?.day : null;
  const MemberDetaillastDayOfWeek =
    memberDetail?.days?.length && memberDetail?.days?.length !== 1
      ? memberDetail?.days[memberDetail?.days.length - 1]?.day
      : null;
  // Define default form values
  const getDayOfWeek = (day) => {
    switch (day) {
      case 1:
        return 'Sunday';
      case 2:
        return 'Monday';
      case 3:
        return 'Tuesday';
      case 4:
        return 'Wednesday';
      case 5:
        return 'Thursday';
      case 6:
        return 'Friday';
      case 7:
        return 'Saturday';
      default:
        return 'null';
    }
  };
  // Define functions to get the day of the week and its range
  const getDaysOfWeek = (firstDay, lastDay) => {
    const firstDayName = getDayOfWeek(firstDay);
    const lastDayName = getDayOfWeek(lastDay);
    return `${lastDayName === null ? `${firstDayName}` : `${firstDayName} - ${lastDayName}`}`;
  };

  // Define a Yup validation schema for form validation
  const manageProfileValidation = Yup.object().shape({
    firstName: Yup.string().required('First name is required').max(50, 'First name must be less then 50 characters'),
    lastName: Yup.string().required('Last name is required').max(50, 'Last name must be less then 50 characters'),
    jobTitle: Yup.string().required('Job title is required').max(50, 'Job title must be less then 50 characters'),
  });

  // Define default form values
  const defaultValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    BusinessUnit: '',
    addressLine1: '',
    addressLine2: '',
    zipcode: '',
    city: '',
    state: '',
    country: '',
    avatarUrl: '',
    email: '',
  };
  // Create default edit values using useMemo
  const defaultEditValues = useMemo(
    () => ({
      firstName: memberDetail?.firstName,
      lastName: memberDetail?.lastName,
      jobTitle: memberDetail?.jobTitle,
      BusinessUnit: memberDetail?.businessTargetUnit,
      avatarUrl: memberDetail?.photoURL ? memberDetail?.photoURL : '',
      email: memberDetail?.userEmail,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memberDetail]
  );
  // Effect to reset form values when memberDetail changes
  useEffect(() => {
    if (memberDetail) {
      reset(defaultEditValues);
    }
  }, [memberDetail]);
  // Initialize form methods using useForm
  const methods = useForm({
    defaultValues,
    defaultEditValues,
    resolver: yupResolver(manageProfileValidation),
  });
  // Effect to update days when memberDetail or days change
  useEffect(() => {
    if (days?.length) {
      setDays(days);
    } else {
      setDays(memberDetail?.days);
    }
  }, [memberDetail, days]);
  // Destructure form methods and values
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  // Function to get the list of organizations
  const getOrganisationList = async () => {
    const res = await AxiosAll('get', `/api/function/get-organizations`, {}, token);

    if (res?.status === 200) {
      setOrganisationOption(res?.data?.data);
    }
  };

  // Effect to fetch organization list on component mount
  useEffect(() => {
    getOrganisationList();
  }, []);

  // Function to get member details
  const getMemberDetail = async (id) => {
    setLoadingPage(true);
    const res = await AxiosAll('get', `/api/user/member-detail?userId=${id}`, {}, token);

    if (res?.status === 200) {
      setLoadingPage(false);

      setMemberDetail(res.data.data);
    }
    setLoadingPage(false);
  };
  const values = watch();
  // Fetch member details when memberId changes
  useEffect(() => {
    if (memberId) {
      getMemberDetail(memberId);
    }
  }, [memberId]);
  // Function to handle avatar image upload
  const handleUpload = async () => {
    setUploadUrl(true);
    setImageError('');
    const formData = new FormData();
    formData.append('file', values?.avatarUrl);
    try {
      const res = await AxiosAll('post', '/api/utilities/upload-image', formData, token, 'multipart/form-data');
      if (res?.status === 200) {
        setValue('avatarUrl', res?.data?.data?.imageUrl);
        setImageError('');
        setUploadUrl(false);
      } else {
        setUploadUrl(false);
        setImageError(res?.response?.data?.message);
        setValue('avatarUrl', memberDetail?.photoURL);
        const timer1 = setTimeout(() => setImageError(''), 3 * 1000);
        return () => {
          clearTimeout(timer1);
        };
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Form submission handler
  const onSubmit = async (values) => {
    try {
      const createPayload = {
        userId: memberDetail?.userId,
        userEmail: memberDetail?.userEmail,
        jobTitle: values?.jobTitle,
        name: memberDetail?.name,
        firstName: values?.firstName,
        lastName: values?.lastName,
        teamName: memberDetail?.teamName,
        roleName: memberDetail?.roleName,
        teamId: memberDetail?.teamId,
        businessTargetUnit: values?.BusinessUnit,
        isAllownotification: memberDetail?.isAllownotification,
        photoURL: typeof values?.avatarUrl === 'object' ? memberDetail?.photoURL : values?.avatarUrl,
        days: days,
      };

      const res = await AxiosAll('post', '/api/user/update-member-detail', createPayload, token);
      if (res?.status === 200) {
        setImageError('');
        navigate('/profile', { state: { memberId: memberId, iconshow: true } });
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Effect to handle avatar upload when the avatarUrl value changes
  // useEffect(() => {
  //   if (typeof values?.avatarUrl === 'object') {
  //     handleUpload();
  //   }
  // }, [values?.avatarUrl]);

  // Function to handle dropped files
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );
  // Effect to determine if all start times are equal
  useEffect(() => {
    if (days?.length) {
      const allStartTimesEqual = days?.every((event) => event?.startTime === days[0]?.startTime);
      setMToF(allStartTimesEqual);
    } else {
      const allStartTimesEqual = memberDetail?.days?.every(
        (event) => event?.startTime === memberDetail?.days[0].startTime
      );
      setMToF(allStartTimesEqual);
    }
  }, [memberDetail, days]);

  return (
    <Page title="Manage Profile" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Manage Profile"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Manage profile', href: '/manage-profile' },
            { name: 'Edit profile details' },
          ]}
        />
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {loadingPage ? (
            <EditProfileSkeleton />
          ) : (
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={3}>
                <Box sx={{ mb: 3 }} >
                <div className="detail-page-profile">
                    <Avatar  />
                  </div>

                  <RHFUploadAvatar
                    name="avatarUrl"
                    maxSize={3145728}
                    profile={true}
                    EditTeamUrl={teamDetail?.photoUrl}
                    onDrop={handleDrop}
                    className="test"
                    helperText={
                      <Typography
                        variant="caption"
                        className="heading12_edit"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png
                        <br /> max size of {fData(3145728)}
                      </Typography>
                    }
                  />
                </Box>
                {imageError !== '' && <p className="error-message profile_error_message">{imageError}</p>}
              </Grid> */}
              <Grid item xs={12} md={9}>
                <div className="manage-profile-fieldset">
                  <fieldset>
                    <legend style={{ textAlign: 'left' }}>
                      <Typography variant="h4" sx={{ textTransform: '' }} className="heading12_edit">
                        Personal Details (editing)
                      </Typography>
                    </legend>

                    <Card sx={{ boxShadow: 'none', p: 3, backgroundColor: 'transparent' }}>
                      <Stack spacing={0} className="form-first-input-pad">
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 3 }}>
                          <RHFTextField name="firstName" label="First name" placeholder="" />
                          <RHFTextField name="lastName" label="Last name" placeholder="" />
                        </Stack>
                        <RHFTextField name="jobTitle" label="Job title" placeholder="" sx={{ mb: 2 }} />
                        <RHFTextField disabled name="email" label="Email" placeholder="" sx={{ mb: 2 }} />

                        <RHFSelect
                          disabled
                          select
                          name="BusinessUnit"
                          className=" select_input_field style={{marginTop:'0 !important'}}"
                        >
                          <option value={''} className="select-range-text">
                            Select Business Unit
                          </option>
                          {organisationOption?.map((data, index) => (
                            <option
                              key={index}
                              value={data?.id}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                              }}
                            >
                              {data?.name}
                            </option>
                          ))}{' '}
                        </RHFSelect>

                        <Typography variant="body2" sx={{ mt: 3, mb: 3, pl: 2 }} className="mainly-working">
                          {/* Set default work hours (include commuting hours) */}
                          Please set your working hours (include commuting hours):
                        </Typography>
                        {checkMToF ? (
                          <div className="edit_hour_section">
                            <card className="sethour-new-box">
                              <div className="time-new-box">
                                <Typography variant="subtitle1" className="header_dark_heading font600">
                                  {days?.length
                                    ? getDaysOfWeek(firstDayOfWeek, lastDayOfWeek)
                                    : memberDetail?.days?.length
                                    ? getDaysOfWeek(MemberDetailfirstDayOfWeek, MemberDetaillastDayOfWeek)
                                    : 'Monday - Friday'}
                                </Typography>

                                <Typography variant="subtitle2" className="header_dark_heading font600">
                                  {days?.length
                                    ? `${days[0]?.startTime.replace(/^0|:00/g, '')}- ${days[0]?.endTime.replace(
                                        /^0|:00/g,
                                        ''
                                      )}`
                                    : memberDetail?.days?.length
                                    ? `${memberDetail?.days[0]?.startTime.replace(
                                        /^0|:00/g,
                                        ''
                                      )} - ${memberDetail?.days[0]?.endTime.replace(/^0|:00/g, '')}`
                                    : '7:00 am - 7:00 pm'}
                                </Typography>
                              </div>

                              {checkMToF && (
                                <WorkingHoursDialog
                                  setNewDays={setDays}
                                  legnth={memberDetail?.days?.length}
                                  newdays={days}
                                  token={token}
                                />
                              )}
                            </card>
                          </div>
                        ) : days?.length ? (
                          <div className="custom-new-box">
                            <div className="custom-card">
                              {days?.length &&
                                days?.map((data, key) => {
                                  return (
                                    <div className="custom-time-box" key={key}>
                                      <Typography variant="subtitle1" sx={{ mb: 0.8 }}>
                                        {data?.day === 2
                                          ? 'Mon'
                                          : data?.day === 3
                                          ? 'Tue'
                                          : data?.day === 4
                                          ? 'Weds'
                                          : data?.day === 5
                                          ? 'Thu'
                                          : data?.day === 6
                                          ? 'Fri'
                                          : data?.day === 7
                                          ? 'Sat'
                                          : 'Sun'}
                                      </Typography>
                                      <Typography variant="body">
                                        {' '}
                                        {data?.startTime.replace(/^0|:00/g, '')}-{data?.endTime.replace(/^0|:00/g, '')}
                                      </Typography>
                                    </div>
                                  );
                                })}
                            </div>
                            {days?.length && (
                              <WorkingHoursDialog
                                setNewDays={setDays}
                                legnth={memberDetail?.days?.length}
                                newdays={days}
                                token={token}
                              />
                            )}
                          </div>
                        ) : (
                          <div className="custom-new-box">
                            <div className="custom-card">
                              {memberDetail &&
                                memberDetail?.days?.map((data, key) => {
                                  return (
                                    <div className="custom-time-box" key={key}>
                                      <Typography variant="subtitle1" sx={{ mb: 0.8 }}>
                                        {data?.day === 2
                                          ? 'Mon'
                                          : data?.day === 3
                                          ? 'Tue'
                                          : data?.day === 4
                                          ? 'Weds'
                                          : data?.day === 5
                                          ? 'Thu'
                                          : data?.day === 6
                                          ? 'Fri'
                                          : data?.day === 7
                                          ? 'Sat'
                                          : 'Sun'}
                                      </Typography>
                                      <Typography variant="body">
                                        {' '}
                                        {data?.startTime.replace(/^0|:00/g, '')}-{data?.endTime.replace(/^0|:00/g, '')}
                                      </Typography>
                                    </div>
                                  );
                                })}
                            </div>
                            {memberDetail?.days?.length && (
                              <WorkingHoursDialog
                                setNewDays={setDays}
                                legnth={memberDetail?.days?.length}
                                newdays={days}
                                token={token}
                              />
                            )}
                          </div>
                        )}
                      </Stack>
                      <Stack direction={{ xs: 'row', sm: 'row' }} sx={{ mt: 3 }} spacing={2}>
                        <LoadingButton
                          size="medium"
                          variant="contained"
                          onClick={() => navigate('/profile', { state: { memberId: memberId, iconshow: iconshow } })}
                          className={
                            mode?.themeMode === 'dark' ? 'cancle_btn-team cancle_btn_shadow' : ' cancle_btn-team '
                          }
                        >
                          Cancel
                        </LoadingButton>

                        {uploadurl ? (
                          <LoadingButton size="medium" variant="contained" disabled className="save_btn">
                            Save
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            className="save_btn"
                          >
                            Save
                          </LoadingButton>
                        )}
                      </Stack>
                    </Card>
                  </fieldset>
                </div>
                <div className="manage-profile-fieldset">
                  <fieldset style={{ marginTop: '2rem' }}>
                    <legend style={{ textAlign: 'left' }}>
                      <Typography variant="h4" sx={{ textTransform: 'capitalize' }} className="heading12_edit">
                        TripShift Details
                      </Typography>
                    </legend>
                    <Card sx={{ boxShadow: 'none', pt: 2, backgroundColor: 'transparent' }}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                        <Box sx={{ pl: 1.5 }}>
                          <Typography variant="overline" sx={{ textTransform: 'initial' }} className="heading12_edit">
                            Role on TripShift Platform
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2 }}
                            className="heading12_edit"
                          >
                            {memberDetail?.roleName}
                          </Typography>
                          <Typography
                            variant="overline"
                            sx={{ textTransform: 'capitalize' }}
                            className="heading12_edit"
                          >
                            TripShift Team
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2 }}
                            className="heading12_edit"
                          >
                            {memberDetail?.teamName}
                          </Typography>
                        </Box>
                      </Stack>
                    </Card>
                  </fieldset>
                </div>
              </Grid>
            </Grid>
          )}
        </FormProvider>
      </Container>
    </Page>
  );
}
