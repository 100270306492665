import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// eslint-disable-next-line import/no-unresolved
import { FaChevronDown } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export default function AccordionUsage({ customClass, FAQs }) {
  return (
    <div className={customClass}>
      {FAQs.map((item) => (
        <Accordion key={item.id}>
          <AccordionSummary
            expandIcon={<FaChevronDown color="#637381" fontSize={11} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="body2" fontWeight={600}>
              {item?.question || ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={12} fontWeight={400}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: item?.answer }} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

AccordionUsage.propTypes = {
  customClass: PropTypes.string.isRequired,
  FAQs: PropTypes.string.isRequired,
};
