import { useState, useEffect } from 'react';

const useBrowserAndDeviceType = () => {
  const [browserType, setBrowserType] = useState('');
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    // Check browser type
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Firefox')) {
      setBrowserType('Firefox');
    } else if (userAgent.includes('Chrome')) {
      setBrowserType('Chrome');
    } else if (userAgent.includes('Safari')) {
      setBrowserType('Safari');
    } else if (userAgent.includes('Edge')) {
      setBrowserType('Edge');
    } else {
      setBrowserType('Unknown');
    }

    // Check device type
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const isTablet = /iPad/i.test(userAgent);

    if (isMobile) {
      setDeviceType('Mobile');
    } else if (isTablet) {
      setDeviceType('Tablet');
    } else {
      setDeviceType('Desktop');
    }
  }, []);

  return { browserType, deviceType };
};

export default useBrowserAndDeviceType;
