/* eslint-disable arrow-body-style */
/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-debugger */
/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import AxiosAll from 'src/service/AxiosAll';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import WorkingTimeDialog from './workingTime';
import CustomHoursDialog from './customHours';

export default function WorkingHoursDialog({ newdays, legnth, setNewDays, intialDay, token }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [open, setOpen] = React.useState(false);
  const [days, setDays] = React.useState([]);
  const [TimeOpne, setTimeOpne] = useState(false);
  const [MondayToFriday, setMondayToFriday] = useState(true);
  const [updateDays, setUpdateDays] = useState();
  const [customOpen, setCustomOpen] = useState(false);
  const [customeBack, setCustomeBack] = useState(false);
  const [selecteCustomDays, setSelectedCustomDays] = useState([
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 2,
      timeGroupId: 3,
      selected: false,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 3,
      timeGroupId: 3,
      selected: false,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 4,
      timeGroupId: 3,
      selected: false,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 5,
      timeGroupId: 3,
      selected: false,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 6,
      timeGroupId: 3,
      selected: false,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 7,
      timeGroupId: 3,
      selected: false,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 1,
      timeGroupId: 3,
      selected: false,
    },
  ]);

  const [selectedDays, setSelectedDays] = useState([
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 2,
      timeGroupId: 1,
      selected: true,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 3,
      timeGroupId: 1,
      selected: true,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 4,
      timeGroupId: 1,
      selected: true,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 5,
      timeGroupId: 1,
      selected: true,
    },
    {
      startTime: '07:00 am',
      endTime: '07:00 pm',
      day: 6,
      timeGroupId: 1,
      selected: true,
    },
  ]);
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();

  const handleChange = (event) => {
    if (event.target.name === 'MondayToFriday') {
      setSelectedDays(selectedDays);
      setMondayToFriday(event.target.checked);
      const updatedArray = selecteCustomDays?.map((obj) => {
        return {
          ...obj,
          selected: false,
        };
      });
      setSelectedCustomDays(updatedArray);
    } else {
      const updatedCheckboxes = selecteCustomDays?.map((checkbox) =>
        checkbox?.day === parseInt(event.target.name) ? { ...checkbox, selected: event.target.checked } : checkbox
      );
      setSelectedCustomDays(updatedCheckboxes);
      setMondayToFriday(false);
      setMondayToFriday(false);
    }
  };

  const getDays = async () => {
    const res = await AxiosAll('get', '/api/team/get-alltimegroup', {}, token);
    if (res?.status === 200) {
      setDays(res?.data?.data);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewDays(selectedDays);
  };

  React.useEffect(() => {
    getDays();
  }, []);

  React.useEffect(() => {
    if (intialDay === true) {
      setSelectedDays(selectedDays);
    }
  }, [intialDay]);

  const handleSaveDays = () => {
    if (MondayToFriday) {
      setUpdateDays([]);
      setUpdateDays(selectedDays);
      setOpen(false);
      setTimeOpne(true);
    } else {
      setUpdateDays([]);
      const newData1 = selecteCustomDays?.filter((item) => item?.selected);

      setUpdateDays(newData1);
      setCustomOpen(true);
      const newData = selecteCustomDays.map((item) => ({ ...item, selected: false }));
      setSelectedCustomDays(newData);
    }
  };

  React.useEffect(() => {
    if (newdays?.length) {
      const allStartTimesEqual = newdays?.every(
        (event) => event?.startTime === newdays[0]?.startTime && event?.timeGroupId === 1
      );
      if (allStartTimesEqual) {
        const data = newdays?.map((item) => ({ ...item, selected: true }));
        setSelectedDays(data);
        setMondayToFriday(true);
      } else {
        const updatedTimeSlots = selecteCustomDays?.map((slot1) => {
          const slot2 = newdays?.find((slot2) => slot2?.day === slot1?.day);
          return slot2 ? { ...slot2, selected: true } : slot1;
        });
        const finalTimeSlots = [
          ...updatedTimeSlots,
          ...newdays?.filter((slot2) => !selecteCustomDays?.find((slot1) => slot1?.day === slot2?.day)),
        ];
        setMondayToFriday(false);
        setSelectedCustomDays(finalTimeSlots);
      }
    } else {
      console.log('rerre');
    }
  }, [newdays]);

  React.useEffect(() => {
    if (customeBack?.length) {
      const updatedTimeSlots = selecteCustomDays?.map((slot1) => {
        const slot2 = customeBack?.find((slot2) => slot2?.day === slot1?.day);
        return slot2 ? { ...slot2, selected: true } : slot1;
      });
      const finalTimeSlots = [
        ...updatedTimeSlots,
        ...customeBack?.filter((slot2) => !selecteCustomDays?.find((slot1) => slot1?.day === slot2?.day)),
      ];
      setMondayToFriday(false);
      setSelectedCustomDays(finalTimeSlots);
    }
  }, [customeBack]);

  useEffect(() => {
    if (newdays?.length === 0) {
      setNewDays(selectedDays);
    }
  }, [newdays]);

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        variant="contained"
        style={{ textTransform: 'uppercase !important;' }}
      >
        EDIT
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="working-hour-dialogbox"
      >
        <DialogTitle id="alert-dialog-title">Please select your working days:</DialogTitle>

        <DialogContent>
          {days?.length ? (
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                className="mondaytofridaytext"
                control={<Checkbox checked={MondayToFriday} onChange={handleChange} name={'MondayToFriday'} />}
                label={days[0]?.name}
              />{' '}
            </Box>
          ) : null}
          <div className="working-hour-inner">
            <Typography variant="subtitle2" className="manually-text">
              {' '}
              Or select manually:
            </Typography>

            <FormControlLabel
              control={
                // eslint-disable-next-line no-unneeded-ternary
                <Checkbox
                  disabled={MondayToFriday ? true : false}
                  checked={selecteCustomDays[0]?.selected}
                  onChange={handleChange}
                  name="2"
                />
              }
              label="Monday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={MondayToFriday ? true : false}
                  checked={selecteCustomDays[1]?.selected}
                  onChange={handleChange}
                  name="3"
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={MondayToFriday ? true : false}
                  checked={selecteCustomDays[2]?.selected}
                  onChange={handleChange}
                  name="4"
                />
              }
              label="Wednesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={MondayToFriday ? true : false}
                  checked={selecteCustomDays[3]?.selected}
                  onChange={handleChange}
                  name="5"
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={MondayToFriday ? true : false}
                  checked={selecteCustomDays[4]?.selected}
                  onChange={handleChange}
                  name="6"
                />
              }
              label="Friday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={MondayToFriday ? true : false}
                  checked={selecteCustomDays[5]?.selected}
                  onChange={handleChange}
                  name="7"
                />
              }
              label="Saturday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={MondayToFriday ? true : false}
                  checked={selecteCustomDays[6]?.selected}
                  onChange={handleChange}
                  name="1"
                />
              }
              label="Sunday"
            />
          </div>
        </DialogContent>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{ mt: 3, px: 2, pb: 2 }}
          spacing={2}
          className="working_day_btnsection"
        >
          <Button
            size="medium"
            type="button"
            variant="contained"
            onClick={handleClose}
            className={mode?.themeMode === 'dark' ? 'cancle_btn-team cancle_btn_shadow' : ' cancle_btn-team '}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSaveDays}
            size="medium"
            variant="contained"
            className="next-select-btn"
            sx={{ textTransform: 'inherit' }}
          >
            Next: Select hours {'>'}
          </Button>
        </Stack>
      </Dialog>

      {customOpen === true ? (
        <CustomHoursDialog
          updateDays={updateDays}
          setUpdateDays={setUpdateDays}
          setTimeOpne={setTimeOpne}
          setNewDays={setNewDays}
          newdays={newdays}
          customOpen={customOpen}
          setCustomOpen={setCustomOpen}
          setDayOpen={setOpen}
          setCustomeBack={setCustomeBack}
        />
      ) : null}

      <WorkingTimeDialog
        setTimeOpne={setTimeOpne}
        TimeOpne={TimeOpne}
        setUpdateDays={setUpdateDays}
        updateDays={updateDays}
        setHourOpen={setOpen}
        setNewDays={setNewDays}
        newdays={newdays}
      />
    </div>
  );
}
