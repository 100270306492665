/* eslint-disable no-debugger */
/* eslint-disable import/no-unresolved */
import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';

// import { DatePicker } from '@mui/x-date-pickers';
// components
// eslint-disable-next-line import/order
// import AdminTeamLeadDelete from 'src/sections/mui/dialog/AdminTeamLeadDelete';

// import DatePickerFilterDialog from 'src/sections/mui/dialog/FilterDateModal';
// import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

ReportToolbar.propTypes = {
  filterName: PropTypes.string,
  filterService: PropTypes.string,
  // filterEndDate: PropTypes.instanceOf(Date),
  // filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  // onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  // onFilterStartDate: PropTypes.func,
  optionsService: PropTypes.arrayOf(PropTypes.string),
};

export default function ReportToolbar({ setFilterDateSelected, dateFilterSelected }) {
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');

  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleOpenDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const [dateFilter, setFilterDate] = useState([
    {
      startDate: userDetails?.data?.data?.signupDate ? new Date(userDetails?.data?.data?.signupDate) : '',
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    setFilterDateSelected(dateFilter);
  }, [dateFilter]);
  console.log('dateFilter', dateFilter);
  return (
    <>
      {/* new design  */}
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      {/* <div className="memberside-table report-inner-table" sx={{ p: 3 }} style={{ paddingTop: '0px' }}>
        <div>{''}</div>

        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }}>
          <Stack spacing={2} className="report-date-picker" direction={{ sm: 'row' }}>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              {' '}
              From:{' '}
            </Typography>
            <input
              name="startDate"
              placeholder="DD/MM/YYYY"
              className="range-date-picker"
              value={dateFilter[0]?.startDate ? moment(dateFilter[0]?.startDate)?.format('DD/MM/YYYY') : null}
              onClick={handleOpenDatePicker}
              readOnly
            />
          </Stack>

          <Stack spacing={2} className="report-date-picker" direction={{ sm: 'row' }}>
            {' '}
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              {' '}
              To:{' '}
            </Typography>
            <input
              name="endDate"
              placeholder="DD/MM/YYYY"
              value={dateFilter[0]?.endDate ? moment(dateFilter[0]?.endDate)?.format('DD/MM/YYYY') : null}
              onClick={handleOpenDatePicker}
              className="range-date-picker"
              readOnly
            />
          </Stack>
        </Stack>
        <div>
          <DatePickerFilterDialog
            startDatePlaceholder="DD/MM/YYYY"
            endDatePlaceholder="DD/MM/YYYY"
            dateDisplayFormat="dd/m/yyyy"
            showDateDisplay={false}
            openDatePicker={openDatePicker}
            FilterRanges={dateFilter}
            // eslint-disable-next-line react/jsx-boolean-value
            filter={true}
            setOpenDatePicker={setOpenDatePicker}
            setFilterSelectedDate={setFilterDate}
          />
        </div>
      </div> */}
    </>
  );
}
