/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-else-return */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Alert, Button, Fade, Menu, MenuItem, Snackbar, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
import { useSelector } from 'react-redux';
import moment from 'moment';
import Label from 'src/components/Label';
// import currentURL from '../../../../env';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';
import DeleteSuccess from 'src/sections/mui/dialog/DeleteSuccess';
import MemberDeleteDialog from 'src/sections/mui/dialog/MemberDeleteDialog';
import AxiosAll from 'src/service/AxiosAll';

// eslint-disable-next-line import/no-named-as-default

// ----------------------------------------------------------------------

ReportTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function ReportTableRow({ row, selected, setReaload, Index }) {
  const theme = useTheme();

  const { createdAt, toDate, fromDate, status, templateName, fileName, isRead, createdBy, detail, id } = row;
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);
  const [openMenu, setOpenMenuActions] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openstarsuceessModal, setOpenStarSuccessModal] = useState(false);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [openNudge, setOpenNudge] = useState(false);
  const [allocateTeam, setAllocateTeam] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle delete report generation
  const handleDeleteReport = async (id) => {
    const res = await AxiosAll('post', `/api/Report/delete-request-report?Id=${id}`, {}, token);

    if (res.status === 200) {
      setOpenSuccessModal(true);
      setReaload(true);
    }
  };

  //funcation start icon

  const handleStarReport = async (id) => {
    const res = await AxiosAll('post', `/api/Report/read-request-report?Id=${id}`, {}, token);

    if (res.status === 200) {
      setReaload(true);
    }
  };
  const pdfFileName = detail.find((e) => e.templateId === 4);

  const handleDownload = (name) => {
    // URL of the PDF file
    const pdfUrl = `${process.env.REACT_APP_SERVER}/StaticFiles/RequestReport/${name}`;

    // Fetch the file
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new object URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);

        // Append to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the file', error);
      });
  };
  //download pdf and CSV funcationality
  const downloadScussess = (name, id) => {
    handleDownload(name);
    // window.open(`${process.env.REACT_APP_SERVER}/StaticFiles/RequestReport/${name}`);
    if (isRead === true && createdBy == userDetails?.data?.userId) {
      handleStarReport(id);
      setReaload(true);
    } else {
      setReaload(true);
    }
  };
  return (
    <>
      {' '}
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <TableRow>
        <TableCell align="left" style={{ paddingLeft: 16 }}>
          {/* <Stack> */}
          <Typography variant="subtitle2" noWrap className="flex-star">
            {isRead === true && createdBy == userDetails?.data?.userId ? (
              <Image src={imagesAll.star} style={{ width: '15px', height: 'auto' }} />
            ) : (
              <div style={{ width: '15px' }} />
            )}
            {createdAt ? moment.utc(createdAt).local().format('DD/MM/YYYY') : ''}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {/* className="time-new-name" */}
          <Typography variant="subtitle2">
            {moment.utc(fromDate).local().format('DD/MM/YYYY')} - {moment.utc(toDate).local().format('DD/MM/YYYY')}
          </Typography>
        </TableCell>
        <TableCell align="left">
          {/* className="time-new-name" */}
          <Typography variant="subtitle2" align="left">
            {templateName}
          </Typography>
        </TableCell>
        <TableCell align="left" className="pending_tab_row">
          <Label
            variant={theme.palette.mode === 'light' ? 'outlined' : 'outlined'}
            color={(status === 1 && 'warning') || (status === 2 && 'success') || 'default'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status === 1 ? 'Processing' : 'Completed'}
          </Label>
        </TableCell>
        <TableCell align="left" className="csv-pdf-section">
          {/* className="time-new-name" */}
          {status === 2 ? (
            // <Button onClick={() => downloadScussess(pdfFileName?.fileName)} variant="contained" sx={{ mt: 2 }}>
            //   PDF Download
            // </Button>
            <Tooltip title="PDF Download">
              <Button>
                <Image
                  onClick={() => downloadScussess(pdfFileName?.fileName, id)}
                  src={imagesAll.pdf}
                  style={{ width: '25px', height: '25px' }}
                />
              </Button>
            </Tooltip>
          ) : null}
          {detail?.length > 2 && (
            // <Button
            //   id="fade-button"
            //   aria-controls={open1 ? 'fade-menu' : undefined}
            //   aria-haspopup="true"
            //   aria-expanded={open1 ? 'true' : undefined}
            //   onClick={handleClick}
            //   className="csv-new-btn"
            //   // style={{minWidth:'9.5vw' }}
            // >
            //   CSV Download
            // </Button>
            <Tooltip title="CSV Download">
              <Button>
                <Image onClick={handleClick} src={imagesAll.excle} style={{ width: '25px', height: '25px' }} />
              </Button>
            </Tooltip>
          )}
          {detail?.length > 2 ? (
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={open1}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              {detail?.map((data, index) => {
                if (index < 3) {
                  // Only render content for the first three elements
                  return (
                    <MenuItem key={index} onClick={() => downloadScussess(data?.fileName, id)}>
                      {data?.templateId === 1
                        ? 'Enagagement '
                        : data?.templateId === 2
                        ? 'Pending Members'
                        : data?.templateId === 3
                        ? 'Mixed Modes'
                        : ''}
                    </MenuItem>
                  );
                } else {
                  return null; // Return null for the fourth element, rendering nothing
                }
              })}
            </Menu>
          ) : (
            // <Button onClick={() => downloadScussess(detail[0]?.fileName)} id="fade-button" className="csv-new-btn">
            //   CSV Download
            // </Button>
            <Tooltip title="CSV Download">
              <Button>
                <Image
                  onClick={() => downloadScussess(detail[0]?.fileName, id)}
                  src={imagesAll.excle}
                  style={{ width: '25px', height: '25px' }}
                />
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <Button>
              <Image
                onClick={() => setOpenDeleteModal(true)}
                src={imagesAll.trash}
                style={{ width: '25px', height: '25px' }}
              />
            </Button>
          </Tooltip>
        </TableCell>
        <DeleteSuccess
          report={true}
          setOpenSuccessModal={setOpenSuccessModal}
          opensuceessModal={opensuceessModal}
          setReload={setReaload}
        />
        <MemberDeleteDialog
          report={true}
          id={id}
          setReload={setReaload}
          setOpenSuccessModal={setOpenSuccessModal}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
        />

        <DeleteSuccess
          star={true}
          setOpenSuccessModal={setOpenStarSuccessModal}
          opensuceessModal={openstarsuceessModal}
          setReload={setReaload}
        />
      </TableRow>
    </>
  );
}
