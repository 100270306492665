/* eslint-disable import/no-unresolved */
import { useState, useEffect } from 'react';
import Iconify from 'src/components/Iconify';
// @mui
import { Box, Dialog, Grid } from '@mui/material';
// components
// eslint-disable-next-line import/extensions
// import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function EmailConfirmDialog({ openAlert, setOpenAlert, emailValue, setEmailValue }) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(emails[1]);

  const handleClose = () => {
    setOpenAlert(false);
    setEmailValue('');
  };

  useEffect(() => {
    if (openAlert === true) {
      const timer1 = setTimeout(() => handleClose(), 3 * 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [openAlert]);

  return (
    <Box sx={{ textAlign: 'center' }} className="emailconfirmpage">
      <Dialog open={openAlert} onClose={handleClose} className="toast-dialogs">
        <Grid container spacing={2} className="MemberAlert">
          <Grid item xs={2}>
            <div className="checkicon-bg">
              <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
            </div>
          </Grid>
          <Grid item xs={8.5}>
            <div className="email-value">
              An email invitation has been <br />
              sent to:
              <div>{emailValue.replace(/[ ,]+/g, ', ')}</div>
            </div>
          </Grid>
          {/* <Grid item xs={1.5} className="cross_section">
            <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={handleClose} />
          </Grid> */}
        </Grid>
      </Dialog>
    </Box>
  );
}
