import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import TeamReducer from './teamReducer';

const rootReducer = combineReducers({
  loginReducer: LoginReducer,
  teamReducer: TeamReducer
});

export default rootReducer;
