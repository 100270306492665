/* eslint-disable camelcase */
/* eslint-disable no-debugger */
/* eslint-disable import/order */
/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */

import { styled } from '@mui/material';
// routes
// hooks
// eslint-disable-next-line import/no-unresolved
import LoadingOverlay from 'react-loading-overlay';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';

// _mock_
// components

import { Box, Container } from '@mui/system';
import Page from 'src/components/Page';

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
export default function GuidVarify() {
  // Import necessary dependencies and hooks

  return (
    <Page title="set up account" className="setup-loader">
      <LoadingOverlay
        // eslint-disable-next-line react/jsx-boolean-value
        active
        spinner
      >
        <RootStyle>
          <>
            <LogoOnlyLayout />
            <Container className="profile_section" sx={{}}>
              <ContentStyle className="container-style-profile">
                <Box sx={{ mb: 5 }} />
              </ContentStyle>
            </Container>{' '}
          </>
        </RootStyle>
      </LoadingOverlay>
    </Page>
  );
}
