/* eslint-disable import/no-unresolved */
/* eslintdisable import/nounresolved */
import { Container, Stack, Typography } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';

// import { Link as RouterLink } from 'reactrouterdom';
import { Box, styled } from '@mui/system';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';
// import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { getToken } from 'src/redux/actions/login';
import LoadingOverlay from 'react-loading-overlay';
import Page from 'src/components/Page';

// import { useEffect } from 'react';
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function SuccessFullComplateProfile() {
  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }));

  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const UserRegistration = params.get('Registration');
  const platform = params.get('platform');

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-debugger

  useLayoutEffect(() => {
    setLoading(true);
    dispatch(getToken(''));
    handleLogOutSSo();
  }, [accounts]);

  console.log('accounts', accounts);
  const handleLogOutSSo = () => {
    // instance.logoutRedirect();
    if (platform) {
      instance.logoutRedirect({
        postLogoutRedirectUri: `${`${window.location.origin}/successfull-complete-profile?platform=true`}`,
        mainWindowRedirectUri: `${window.location.origin}`, // Specify the main window redirect URI
      });
    } else {
      instance.logoutRedirect({
        postLogoutRedirectUri: `${`${window.location.origin}/successfull-complete-profile`}`,
        mainWindowRedirectUri: `${window.location.origin}`, // Specify the main window redirect URI
      });
    }
    setLoading(false);
  };
  // console.log("UserRegistration", UserRegistration)
  return (
    <>
      <Box className="publicprofile">
        {/* <LogoOnlyLayout /> */}
        <Container maxWidth="md">
          {platform ? (
            <ContentStyle sx={{ textAlign: 'center', maxWidth: '100%' }} className="toppadd_reset  containerstyle">
              <Image src={imagesAll.seo} className="success_img_demo" style={{ marginBottom: '10px' }} />

              <Box>
                <Typography sx={{ px: 2, mt: 0, mb: 2 }} variant="h3" className="main_heading">
                  Thank you for completing your TripShift profile!
                </Typography>

                <Typography sx={{ color: 'text.secondary', mb: 3 }} className="heading_16 thankyou_para_text">
                  Your TripShift account in all set. Download the TripShift app to start reducing your organisations
                  travel emissions. Use your sign-up credentials to log in!
                </Typography>

                {/* Congrats  button section  */}
                <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 3, mb: 4 }}>
                  <Image
                    src={imagesAll.newAppStore}
                    onClick={() => window.open('https://apps.apple.com/gb/app/tripshift/id1537958896')}
                    className="new_play_store"
                  />
                  <Image
                    src={imagesAll.newPlayStore}
                    onClick={() => window.open('https://play.google.com/store/apps/details?id=com.tripshift')}
                    className="new_play_store"
                  />
                </Stack>
              </Box>
            </ContentStyle>
          ) : (
            <Page title="set up account" className="setup-loader">
              <LoadingOverlay
                // eslint-disable-next-line react/jsx-boolean-value
                active={true}
                spinner
              >
                <RootStyle>
                  <>
                    {/* <LogoOnlyLayout /> */}
                    <Container className="profile_section" sx={{}}>
                      <ContentStyle className="container-style-profile">
                        <Box sx={{ mb: 5 }} />
                      </ContentStyle>
                    </Container>{' '}
                  </>
                </RootStyle>
              </LoadingOverlay>
            </Page>
          )}
        </Container>
      </Box>
    </>
  );
}
