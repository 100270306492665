/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
//
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  // maxWidth:'33.33vw',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Maintenance() {
  return (
    <Page title="Maintenance" className="error404">
      <LogoOnlyLayout />
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }} className="container-style">
          <Typography variant="h3" paragraph className="main_heading">
            Website currently <br /> under maintenance
          </Typography>

          <Typography sx={{ color: 'text.secondary' }} className="heading_16">
            We are currently working hard on this page!
          </Typography>
          <Image src={imagesAll.maintenance} className="error404_img" />
          <Button variant="contained" size="large" component={RouterLink} to="/dashboard">
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
