/* eslint-disable no-debugger */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable func-names */
/* eslint-disable import/extensions */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// form
// eslint-disable-next-line import/order
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import Iconify from 'src/components/Iconify';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import CurrentTeamTableRow from 'src/sections/@dashboard/invoice/list/CurrentTeamTableRow';
// eslint-disable-next-line import/no-unresolved
import CurrentTeamTableHead from 'src/sections/@dashboard/invoice/list/CurrentTeamTableHead';
// eslint-disable-next-line import/no-unresolved
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// eslint-disable-next-line import/no-unresolved
import useSettings from 'src/hooks/useSettings';
// eslint-disable-next-line import/no-unresolved
import { Container } from '@mui/system';
// eslint-disable-next-line import/no-unresolved
import Page from 'src/components/Page';
// eslint-disable-next-line import/no-unresolved
import SkipStep from 'src/sections/mui/SkipStep';
// eslint-disable-next-line import/no-unresolved
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AddTeamHeader from 'src/sections/@dashboard/invoice/list/AddTeamHeader';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
// eslint-disable-next-line import/no-unresolved
import { createTeam } from 'src/redux/actions/team';
// eslint-disable-next-line import/no-unresolved
import CreateTeamSkeleton from 'src/components/skeleton/createTeamSkeleton';
import Scrollbar from '../../components/Scrollbar';
// sections
// @mui
// eslint-disable-next-line import/no-duplicates
// utils
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-duplicates

// routes
import useTabs from '../../hooks/useTabs';

// eslint-disable-next-line import/no-duplicates
import { TableEmptyRows, TableNoData } from '../../components/table';
import useTable, { emptyRows, getComparator } from '../../hooks/useTable';
// import { PATH_DASHBOARD } from '.../../routes/paths';
// components
// eslint-disable-next-line import/no-unresolved
// import Iconify from '../../../../components/Iconify';
import { FormProvider } from '../../components/hook-form';
import TeamFlowAddTeamTableRow from 'src/sections/@dashboard/invoice/list/TeamFlowAddTeamTableRow';

// ----------------------------------------------------------------------

UserNewFormStepTwo.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD1 = [
  { id: 'Name', label: 'Name', align: 'left' },
  { id: 'TeamName', label: 'Team', align: 'left' },
  // { id: '', label: '', align: 'left' },
];

const TABLE_HEAD2 = [
  { id: 'status', label: 'Name', align: 'left' },
  { id: 'createDate', label: 'Team ', align: 'left' },
];

export default function UserNewFormStepTwo({ isEdit, currentUser }) {
  const navigate = useNavigate();
  // Destructuring values from useTable hook
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onCurrentSelectRow,
    currentSelected,
    setCurrentSelected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'status' });

  // Define some state variables
  const [tableData, setTableData] = useState([]);
  const [tableCurrentData, setTableCurrentData] = useState([]);
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const { CreateTeam } = useSelector((state) => state.teamReducer);
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const denseHeight = dense ? 56 : 76;
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const [orderValue, setOrder] = useState('asc');
  const [orderByValue, setOrderBy] = useState(null);
  const [currentFilterName, setCurrentFilterName] = useState();
  const [filterData, setFilterData] = useState();
  const [filterService, setFilterService] = useState('all');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [teamFlowdata, setTeamFlowData] = useState([]);
  const [addTeamBtn, setAddTeamBtn] = useState(false);
  const [nameOfTeam, setNameOfTeam] = useState();
  const [IdOfTeam, setIdOfTeam] = useState();

  const [removebtn, setRemovebtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentLoading, setCurrentLoading] = useState(true);
  const [selectedUser, setselectedUser] = useState('');
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState();
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');
  const location = useLocation();
  const edit = location?.state?.edit;
  const Teamid = location?.state?.id;
  const TeamName = location?.state?.teamName;
  const teamDetail = location?.state?.teamDetail;
  const AddMember = location?.state?.addMember;

  // Function to handle filter by name
  const handleFilterName = (filterName) => {
    // setFilterName(filterName);
    setPage(0);
  };

  // Function to handle current filter by name
  const handleCurrentFilterName = (string) => {
    setCurrentLoading(true);

    setCurrentFilterName(string);

    if (string) {
      const filterWords = string.toLowerCase().split(' ');

      const filterDataNew = tableCurrentData.filter((data) => {
        const fullName = `${data.firstName} ${data.lastName}`.toLowerCase();
        return filterWords.every((word) => fullName.includes(word));
      });
      setFilterData(filterDataNew);
      setCurrentLoading(false);
    } else {
      setFilterData(tableCurrentData);
      setCurrentLoading(false);
    }
  };

  // Function to handle filter by service
  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };
  // Apply sorting and filtering to table data
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterService,
    filterStatus,
    filterStartDate,
    filterEndDate,
  });
  // Function to get the member list
  const getMemberList = async () => {
    const data = {
      search,
      sortBy: orderByValue,
      sortOrder: orderValue === 'asc' ? 'ASC' : 'DESC',
      pageNo: page === 0 ? 1 : page,
      perPageLimit: 30,
    };

    setLoading(true);
    const res = await AxiosAll('post', '/api/user/team-member-list', data, token);
    setTableData([]);
    if (res?.status === 200) {
      const arrayUniqueByKey = [...new Map(res?.data?.data?.list.map((item) => [item.userId, item])).values()];
      setTableData(res?.data?.data?.list);
      setTeamFlowData(res?.data?.data?.list);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };
  // Effect to fetch member list when dependencies change
  useEffect(() => {
    getMemberList();
  }, [userDetails?.data, orderByValue, orderValue, search, page, rowsPerPage]);

  // Function to add a member to the team
  const AddMemberToTeam = async () => {
    const ids = CreateTeam?.id || teamDetail?.teamId || Teamid;
    if (selected && ids) {
      setAddTeamBtn(true);
      setselectedUser(selected);

      const data = {
        teamId: ids,
        userId: selected,
      };
      const res = await AxiosAll('post', '/api/team/update-team-members', data, token);
      if (res?.status === 200) {
        setAddTeamBtn(false);
        setSelected([]);
        getCurrentTeamMemberList();
      } else {
        setSelected([]);
        setAddTeamBtn(false);
      }
    }
  };
  // Function to remove a member from the team
  const RemoveMemberToTeam = async () => {
    const ids = CreateTeam?.id || teamDetail?.teamId || Teamid;

    if (currentSelected && ids) {
      setRemovebtn(true);
      const data = {
        teamId: ids,
        userId: currentSelected,
      };
      const res = await AxiosAll('post', '/api/team/update-team-members', data, token);
      if (res?.status === 200) {
        setCurrentSelected([]);
        setRemovebtn(false);
        getCurrentTeamMemberList();
        getMemberList();
      } else {
        setCurrentSelected([]);
        setRemovebtn(false);
        getCurrentTeamMemberList();
      }
    }
  };

  // Function to get the current team's member list
  const getCurrentTeamMemberList = async () => {
    const ids = CreateTeam?.id || teamDetail?.teamId || Teamid;
    setCurrentLoading(true);
    const res = await AxiosAll('get', `/api/team/team-detail?teamId=${ids}`, {}, token);
    if (res?.status === 200) {
      setCurrentLoading(false);

      setTableCurrentData(res?.data?.data?.listofMembers);
      setFilterData(res?.data?.data?.listofMembers);
      setNameOfTeam(res?.data?.data?.teamName);
      setIdOfTeam(res?.data?.data?.teamId);
      getMemberList();
    }
  };

  // Function to navigate to the previous step
  const PrevioesStep = () => {
    navigate('/create-team-stepOne', { state: { edit: true } });
  };
  // Function to save the team
  const SaveTeam = () => {
    dispatch(createTeam({}));
    navigate('/teams');
  };
  // Function to save the team details
  const SaveTeamDetail = () => {
    if (AddMember) {
      dispatch(createTeam({}));
      navigate('/teams');
    } else {
      const TeamId = teamDetail?.teamId;
      dispatch(createTeam({}));
      navigate('/team-detail', { state: { Id: TeamId, editIcon: true } });
    }
  };
  // Effect to fetch current team member list when teamDetail changes
  useEffect(() => {
    getCurrentTeamMemberList();
  }, [teamDetail]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText || '');
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  // Function to get users not in the list
  function getNotInList(list1, list2) {
    const result = list1.filter((user) => !list2.some((userInList2) => userInList2.id === user.userId));
    setTableData(result);
  }
  // Effect to check for users not in the list when tableData or tableCurrentData changes
  useEffect(() => {
    if (tableCurrentData?.length && tableData?.length) {
      getNotInList(tableData, tableCurrentData);
    }
  }, [tableData?.length, tableCurrentData?.length]);
  console.log('currentSelected', currentSelected);
  return (
    <Page title="Create a new team  >  create team (step 1 of 2)" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={edit === false ? 'Add Team Members (Step 2 of 2)' : 'Manage Teams'}
          subHeading="Skip this step"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Manage', href: '/members' },
            { name: `${edit ? 'Edit Team Members' : 'Allocate Team Members'}` },
          ]}
        />
        <FormProvider>
          <div className="boder-upper">
            <Grid container spacing={3} sx={{ py: 3, px: 3, pb: 0 }}>
              <Grid item xs={12} md={6} className="side-line-code">
                {edit === false ? (
                  <Typography
                    variant="h4"
                    sx={{ mb: 3 }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/team-detail', {
                        state: {
                          Id: IdOfTeam,
                          editIcon: true,
                        },
                      });
                    }}
                  >
                    {TeamName || teamDetail?.teamName} I Add team members
                  </Typography>
                ) : (
                  <Typography
                    variant="h4"
                    sx={{ mb: 3 }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/team-detail', {
                        state: {
                          Id: IdOfTeam,
                          editIcon: true,
                        },
                      });
                    }}
                  >
                    {teamDetail?.teamName} I Add Team Members
                  </Typography>
                )}
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                  <TextField
                    // sx={{ mb: 2 }}
                    className="search_bar"
                    size="small"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <Stack alignItems="flex-end" sx={{ mt: 3, mb: 3 }}> */}
                  {addTeamBtn ? (
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      sx={{ textTransform: 'initial', boxShadow: 'none' }}
                      loading
                    >
                      Add to team
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      variant="contained"
                      sx={{ textTransform: 'initial', boxShadow: 'none' }}
                      onClick={AddMemberToTeam}
                      loading={false}
                      size="medium"
                    >
                      Add to team
                    </LoadingButton>
                  )}
                </Stack>
                {/* </Stack> */}

                <Card
                  sx={{ p: 3, boxShadow: 'none', mb: 3 }}
                  className="boder-upper-inner first-section  edit-team-screen"
                >
                  <Scrollbar>
                    <TableContainer>
                      {loading ? (
                        <CreateTeamSkeleton />
                      ) : (
                        <Table
                          size={dense ? 'small' : 'medium'}
                          className="memberinner-table edit-section-table addbutton_design"
                        >
                          <AddTeamHeader
                            // order={order}
                            // orderBy={orderBy}
                            headLabel={TABLE_HEAD1}
                            rowCount={tableData?.length}
                            numSelected={selected?.length}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                          />

                          <TableBody>
                            {teamFlowdata.map((row, index) => (
                              <TeamFlowAddTeamTableRow
                                key={index}
                                row={row}
                                selected={selected.includes(row.userId)}
                                setFilterData={setFilterData}
                                selectedRow={selected}
                                filterName={search}
                                teamNameTeamFlow={teamDetail?.teamName}
                                id={CreateTeam?.id || teamDetail?.teamId || Teamid}
                                onSelectRow={() => onSelectRow(row.userId, 'user')}
                                setTableCurrentData={setTableCurrentData}
                                tableData={tableData}
                                setTableData={setTableData}
                                getMemberList={getMemberList}
                              />
                            ))}

                            <TableNoData isNotFound={!teamFlowdata?.length} />
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                {edit === false ? (
                  <Typography
                    variant="h4"
                    sx={{ mb: 3 }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/team-detail', {
                        state: {
                          Id: IdOfTeam,
                          editIcon: true,
                        },
                      });
                    }}
                  >
                    {TeamName || teamDetail?.teamName} I Current team
                  </Typography>
                ) : (
                  <Typography
                    variant="h4"
                    sx={{ mb: 3 }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/team-detail', {
                        state: {
                          Id: IdOfTeam,
                          editIcon: true,
                        },
                      });
                    }}
                  >
                    {teamDetail?.teamName} I Current team
                  </Typography>
                )}
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                  <TextField
                    // sx={{ mb: 2 }}
                    className="search_bar"
                    size="small"
                    value={currentFilterName}
                    onChange={(event) => handleCurrentFilterName(event.target.value)}
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {filterData?.length ? (
                    <Stack alignItems="flex-end" sx={{ mt: 3, mb: 3 }}>
                      {removebtn ? (
                        <LoadingButton
                          variant="contained"
                          // className={mode?.themeMode === 'dark' ? 'skip_btn-team cancle_btn_shadow' : ' skip_btn-team '}
                          sx={{ textTransform: 'initial' }}
                          loading
                          disabled
                        >
                          Remove from team
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          variant="contained"
                          className={mode?.themeMode === 'dark' ? 'skip_btn-team cancle_btn_shadow' : ' skip_btn-team'}
                          sx={{ textTransform: 'initial' }}
                          onClick={RemoveMemberToTeam}
                          loading={false}
                        >
                          Remove from team
                        </LoadingButton>
                      )}
                    </Stack>
                  ) : null}
                </Stack>
                <Card sx={{ p: 3, boxShadow: 'none' }} className="boder-upper-inner second-section">
                  <Scrollbar>
                    <TableContainer>
                      {' '}
                      {currentLoading ? (
                        <CreateTeamSkeleton />
                      ) : (
                        <Table size={dense ? 'small' : 'medium'} className="memberinner-table edit-section-table">
                          <CurrentTeamTableHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD2}
                            rowCount={tableData?.length}
                            numSelected={currentSelected?.length}
                            onSort={onSort}
                          />

                          <TableBody>
                            {filterData?.map((row, index) => (
                              <CurrentTeamTableRow
                                key={index}
                                row={row}
                                selected={currentSelected?.includes(row?.id)}
                                onSelectRow={() => onCurrentSelectRow(row?.id)}
                                nameOfTeam={nameOfTeam}
                                IdOfTeam={IdOfTeam}
                                selectedUser={selectedUser}
                                setselectedUser={setselectedUser}
                              />
                            ))}

                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(page, rowsPerPage, tableData?.length)}
                            />

                            <TableNoData isNotFound={!tableCurrentData?.length} />
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>
                </Card>
              </Grid>
            </Grid>
          </div>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'normal', sm: 'flex-end' }}
            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            sx={{ mt: 3 }}
            spacing={2}
          >
            {edit === false ? (
              <LoadingButton
                onClick={PrevioesStep}
                variant="contained"
                size="medium"
                className="previous_btn margrigt"
                style={{ textTransform: 'initial' }}
              >
                {'<'} Previous step
              </LoadingButton>
            ) : (
              ''
            )}
            {edit === false ? (
              // <LoadingButton
              //   variant="contained"
              //   size="medium"
              //   className={
              //     mode?.themeMode === 'dark' ? 'skip-this-team-btn cancle_btn_shadow' : ' skip-this-team-btn padding0'
              //   }
              //   style={{ textTransform: 'initial' }}
              // >
              <SkipStep />
            ) : (
              // </LoadingButton>
              ''
            )}
            {edit === false ? (
              <LoadingButton
                onClick={SaveTeam}
                variant="contained"
                size="medium"
                className="save-teamnew-btn margrigt"
                sx={{ textTransform: 'initial' }}
              >
                Save team
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={SaveTeamDetail}
                variant="contained"
                size="medium"
                className="save-teamnew-btn"
                sx={{ textTransform: 'initial' }}
              >
                Save team
              </LoadingButton>
            )}
          </Stack>
        </FormProvider>
      </Container>
    </Page>
  );
}

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item) =>
        item.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.invoiceTo.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  if (filterService !== 'all') {
    tableData = tableData.filter((item) => item.items.some((c) => c.service === filterService));
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter(
      (item) =>
        item.createDate.getTime() >= filterStartDate.getTime() && item.createDate.getTime() <= filterEndDate.getTime()
    );
  }

  return tableData;
}
