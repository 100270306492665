/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
// eslint-disable-next-line import/order
// import { imagesAll } from 'src/assets/Images_index';
import { imagesAll } from '../../assets/Images_index';
import { ResetPasswordForm } from 'src/sections/auth/reset-password';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  // width: '100%',
  maxWidth: 464,
  // maxWidth:'32.22vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 485,
  // maxWidth:'33.33vw',

  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page Page title="Reset Password" className="resetpassword">
      <div>
        <RootStyle>
          <div className="login">
            <HeaderStyle className="boxpadding ">
              <Logo />
            </HeaderStyle>
          </div>

          {mdUp && (
            <SectionStyle className="section-style">
              <Typography
                variant="h3"
                sx={{ px: 5, mt: 10, mb: 5 }}
                className="welcome_text main_heading welcome-forgot"
              >
                Welcome TripShifter!
              </Typography>
              <Image visibleByDefault disabledEffect src={imagesAll.authLogo} className="login_sectionimg" />
            </SectionStyle>
          )}
          <Container maxWidth="sm">
            <ContentStyle sx={{ textAlign: 'center' }} className="toppadd_reset  container-style">
              <Typography variant="h3" paragraph className="main_heading admin_heading">
                Forgot your password?
              </Typography>

              <Typography sx={{ color: 'text.secondary', mb: 5 }} className="heading_16 resetpara">
                Please enter the email address associated with your account, and we'll email you a link to reset your
                password.
              </Typography>

              <ResetPasswordForm />
            </ContentStyle>
          </Container>
        </RootStyle>
      </div>
    </Page>
  );
}
