import { Button, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import { imagesAll } from '../../assets/Images_index';

const BannerCard = ({ userName, para, onSubmitRequestClick }) => (
  <div className="resources-content">
    <div className="resource-side-card">
      <Typography
        variant="h4"
        sx={{
          pb: 1,
        }}
      >
        Hey {userName || 'Carlota'},<br /> need a hand?
      </Typography>
      <Typography
        variant="body2"
        sx={{
          pb: 2,
        }}
      >
        {para}
      </Typography>
      <Button
        type="submit"
        size="large"
        variant="contained"
        className="enable_btn_report"
        onClick={onSubmitRequestClick}
      >
        Set up manual
      </Button>
    </div>
    <dic className="img-banner">
      <Image src={imagesAll.resourceImg} />
    </dic>
  </div>
);

export default BannerCard;

BannerCard.propTypes = {
  userName: PropTypes.string.isRequired,
  para: PropTypes.string.isRequired,
  onSubmitRequestClick: PropTypes.string.isRequired,
};
