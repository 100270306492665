/* eslint-disable import/no-unresolved */
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography } from '@mui/material';

import VerifyCodeForm from 'src/sections/auth/verify-code';
// components
import Page from '../../components/Page';
// eslint-disable-next-line import/order
import Logo from 'src/components/Logo';
// sections

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  // maxWidth:'33.33vw',
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function VerifyCode() {
  return (
    <Page title="Verify Code" className="verifycode">
      {/* <LogoOnlyLayout /> */}
      <Logo />

      <Container>
        <ContentStyle sx={{ textAlign: 'center' }} className="paddtop container-style">
          <Typography variant="h3" paragraph className="main_heading">
            Please check your email!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }} className="heading_16 para16">
            We've emailed a 6-digit confirmation code to acb@domain, please enter the code in below box to verify your
            email.
          </Typography>

          <Box sx={{ mt: 5, mb: 3 }}>
            <VerifyCodeForm />
          </Box>

          <Typography variant="body2" className="heading_14">
            Don’t have a code? &nbsp;
            <Link variant="subtitle2" onClick={() => {}} className="heading_14">
              Resend code
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </Page>
  );
}
