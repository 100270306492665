/* eslint-disable import/no-unresolved */

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Card, Container, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
import useSettings from 'src/hooks/useSettings';
import useTable, { getComparator } from 'src/hooks/useTable';
import useTabs from 'src/hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { TableNoData } from 'src/components/table';
// sections

import RecycleBinTableRow from 'src/sections/@dashboard/invoice/list/RecycleBinTableRow';
import ReCycleBinTableTead from 'src/components/table/CompanyTableHead';
import RecycleBinToolebar from 'src/sections/@dashboard/invoice/list/RecycleBinToolebar';
import LoadingOverlay from 'react-loading-overlay';

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD = [
  { id: 'companyName', label: 'Customer Name', align: 'left' },
  { id: 'companyEmail', label: 'Primary Admin', align: 'left' },
  { id: 'tripshiftOwner', label: 'TripShift owner', align: 'left' },
  { id: 'totalLicence', label: 'Licences purchased', align: 'center', width: 140 },
  { id: 'remainingLicence', label: 'Licences remaining', align: 'center', width: 140 },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function RecycleBin() {
  const theme = useTheme();
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  // Destructure properties from useTable hook
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  // Initialize state variables
  const [tableData, setTableData] = useState([]);

  const [count, setCount] = useState('');
  const [orderValue, setOrder] = useState('asc');
  const [orderByValue, setOrderBy] = useState(null);
  const [filterService, setFilterService] = useState('all');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [reload, setReload] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');
  const [makeAdmin, setMakeAdmin] = useState(false);
  const [makeTeam, setMakeTeam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teamLength, setTeamLength] = useState();
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState();
  const setting = localStorage.getItem('settings');
  const [open, setOpen] = useState(false);
  const mode = JSON.parse(setting);

  // Function to handle filtering by name

  // Function to handle filtering by service
  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };
  // Apply sorting and filtering to the table data
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
  });
  // Check if data is not found
  const isNotFound = !dataFiltered?.length;
  const denseHeight = dense ? 56 : 76;
  const location = useLocation();
  const reloadAPI = location?.state?.reload;

  // Function to fetch customer list
  const getCustomerList = async () => {
    setLoading(true);

    const data = {
      // eslint-disable-next-line object-shorthand
      search: search,
      sortBy: orderByValue,
      sortOrder: orderValue === 'asc' ? 'ASC' : 'DESC',
      pageNo: 0,
      perPageLimit: rowsPerPage,
      RoleId: userRole?.data,
    };
    const res = await AxiosAll(
      'get',
      `/api/company/getalldeletedcustomer?pageNo=${page + 1}&perPageLimit=${rowsPerPage}&search=${
        search || ''
      }&sortOrder=${orderValue === 'asc' ? 'ASC' : 'DESC'}&sortBy=${orderByValue}`,
      {},
      token
    );
    setTableData([]);
    if (res?.status === 200) {
      setReload(false);
      setTableData(res?.data?.result);
      setCount(res?.data?.total);
      setLoading(false);
    } else {
      setLoading(false);
      setTableData([]);
    }
  };

  useEffect(() => {
    if (searchText === '') {
      setTableData([]);
    }
  }, [search, searchText]);

  useEffect(() => {
    const sortedData = [...tableData];
    // Create a copy of the original data to avoid mutating the original array
    sortedData.sort((p1, p2) => {
      const value1 = p1[orderBy];
      const value2 = p2[orderBy];

      // Check if the values are numbers
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(value1) && !isNaN(value2)) {
        // If both values are numbers, compare them directly
        return order === 'asc' ? value1 - value2 : value2 - value1;
        // eslint-disable-next-line no-else-return
      } else {
        // If values are not numbers, convert them to lowercase and compare as strings
        const lowerCaseValue1 = String(value1).toLowerCase();
        const lowerCaseValue2 = String(value2).toLowerCase();

        if (lowerCaseValue1 < lowerCaseValue2) {
          return order === 'asc' ? -1 : 1;
          // eslint-disable-next-line no-else-return
        } else if (lowerCaseValue1 > lowerCaseValue2) {
          return order === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    });

    // Update the state with the sorted data
    setTableData(sortedData);
  }, [order, orderBy]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText || '');
      setPage(page);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);
  // Fetch customer list when dependencies change
  useEffect(() => {
    getCustomerList();
  }, [token, userRole, search, page, rowsPerPage, reloadAPI]);

  return (
    <Page title="Recycle Bin" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div>
          <Typography
            variant="h4"
            sx={{ pl: 0.5 }}
            className={mode?.themeMode === 'dark' ? 'light_link_text' : 'header_dark_heading customer-text-heading'}
          >
            {userRole?.data === 4 ? 'TripShift Customer Management Portal' : null}
          </Typography>

          {userRole?.data === 4 ? (
            <Typography
              variant="h5"
              sx={{ mt: 4, mb: 4, pl: 0.5 }}
              // noWrap
              className={mode?.themeMode === 'dark' ? 'light_link_text' : 'header_dark_heading'}
            >
              Welcome {userDetails?.data?.data?.firstName} {userDetails?.data?.data?.lastName}{' '}
            </Typography>
          ) : null}
        </div>
        <HeaderBreadcrumbs heading="Recycle Bin" links={[]} />

        <Card>
          <RecycleBinToolebar
            filterName={searchText}
            filterService={filterService}
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            teamLength={teamLength}
            selected={selected?.length}
            tableData={selectedIds}
            MainTable={dataFiltered}
            teamName={dataFiltered[0]?.teamName}
            setSelected={setSelected}
            selectedRow={selected}
            setReload={setReload}
            makeAdmin={makeAdmin}
            makeTeam={makeTeam}
            user={userDetails}
            setFilterName={setSearchText}
            onFilterName={setSearchText}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
            optionsService={SERVICE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <LoadingOverlay active={loading} spinner>
                <Table size={'medium'} className="memberinner-table">
                  <ReCycleBinTableTead
                    onSort={onSort}
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected?.length}
                    setOrder={setOrder}
                    user={userDetails}
                    team={false}
                    setOrderBy={setOrderBy}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?.userId)
                      )
                    }
                  />

                  <>
                    <TableBody>
                      {tableData?.map((row, index) => (
                        <RecycleBinTableRow
                          key={index}
                          row={row}
                          user={userDetails}
                          selected={selected.includes(row?.userId)}
                          onSelectRow={() => onSelectRow(row?.userId)}
                          setReload={setReload}
                          getCustomerList={getCustomerList}
                        />
                      ))}

                      {isNotFound ? <TableNoData isNotFound={isNotFound} /> : null}
                    </TableBody>
                  </>
                </Table>
              </LoadingOverlay>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    tableData = tableData?.filter(
      (item) =>
        item.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.invoiceTo.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
