/* eslint-disable react/self-closing-comp */
// @mui
// eslint-disable-next-line import/no-duplicates
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// layouts
// eslint-disable-next-line import/no-unresolved
import NewPasswordForm from 'src/sections/auth/new-password';
// eslint-disable-next-line import/no-unresolved
import useResponsive from 'src/hooks/useResponsive';
// eslint-disable-next-line import/no-unresolved
import { imagesAll } from 'src/assets/Images_index';
// eslint-disable-next-line import/no-unresolved
import Image from 'src/components/Image';
import {
  Card,
  // eslint-disable-next-line import/no-duplicates
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import Logo from 'src/components/Logo';
// components
import Page from '../../components/Page';
// sections
// assets

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  // maxWidth:'32.22vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // maxWidth:'33.33vw',

  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function NewPassword() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  return (
    <Page title="New Password" className="newpassword">
      <RootStyle>
        <HeaderStyle className="boxpadding">
          <Logo />
        </HeaderStyle>
        {mdUp && (
          <SectionStyle className="section-style">
            <Typography variant="h3" sx={{ px: 5, mt: 20, mb: 5 }} className="welcome_text">
              Welcome TripShifter!
            </Typography>
            <Image visibleByDefault disabledEffect src={imagesAll.authLogo} className="login_sectionimg" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle sx={{ textAlign: 'center', mt: 10 }} className="toppadd_reset  container-style">
            <Typography variant="h3" gutterBottom className="main_heading">
              Reset Password
            </Typography>

            <Typography sx={{ color: 'text.secondary' }} className="heading_16 parapadding"></Typography>

            <Box sx={{ mt: 5, mb: 3 }}>
              <NewPasswordForm />
            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
