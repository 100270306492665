/* eslint-disable no-void */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import LoginForm from '../../sections/auth/login/LoginForm';
// eslint-disable-next-line import/order
import { imagesAll } from '../../assets/Images_index';
import LoadingOverlay from 'react-loading-overlay';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  // maxWidth:'32.22vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // maxWidth:'33.33vw',

  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const newPassword = location?.state?.newPassword;

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <LoadingOverlay
        // eslint-disable-next-line react/jsx-boolean-value
        active={loading}
        spinner
      >
        <div className="login">
          <RootStyle>
            <HeaderStyle className="boxpadding welcome_login">
              <Logo />
            </HeaderStyle>

            {mdUp && (
              <SectionStyle className="section-style">
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} className="welcome_text">
                  Welcome TripShifter!
                </Typography>
                <Image visibleByDefault disabledEffect src={imagesAll.authLogo} className="login_sectionimg" />
              </SectionStyle>
            )}
            <Container maxWidth="sm">
              <ContentStyle className="mobilepadd container-style">
                <Stack>
                  {newPassword ? (
                    <Box sx={{ flexGrow: 1, mb: 2 }}>
                      <Typography variant="h4" gutterBottom className="signin_text">
                        Your password has been changed. Please log in with your new credentials.
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h4" gutterBottom className="signin_text" style={{ textAlign: 'center' }}>
                        Welcome to TripShift!
                      </Typography>
                    </Box>
                  )}
                </Stack>

                <LoginForm setLoading={setLoading} loading={loading} />
              </ContentStyle>
            </Container>
          </RootStyle>
        </div>
      </LoadingOverlay>
    </Page>
  );
}
