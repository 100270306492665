import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import _ from 'lodash';
// @mui
// eslint-disable-next-line import/no-duplicates
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Card, CardHeader, Typography, Divider } from '@mui/material';
// utils
// eslint-disable-next-line import/no-unresolved
import Image from 'src/components/Image';
// components
// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

// ----------------------------------------------------------------------

AppTopAuthors.propTypes = {
  title: PropTypes.string,
  title1: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppTopAuthors({ title, title1, subheader, list, ...other }) {
  const theme = useTheme();
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);

  list = _.uniqBy(list, 'id');
  // console.log(list);

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        className={mode?.themeMode === 'dark' ? 'light_color_heading' : 'travel-mode'}
      />

      <Stack spacing={3} sx={{ p: 3 }}>
        {orderBy(list, ['favourite'], ['desc']).map((author, index) => (
          <AuthorItem key={author.id} author={author} index={index} />
        ))}
      </Stack>
      {/* <Typography variant="body1" className="heading_14 greenclo textstyle" sx={{textAlign:'end',mb:2, mr:2}}>Teams leaderboard ></Typography> */}
    </Card>
  );
}

// ----------------------------------------------------------------------

AuthorItem.propTypes = {
  author: PropTypes.shape({
    avatar: PropTypes.string,
    favourite: PropTypes.number,
    name: PropTypes.string,
  }),
  index: PropTypes.number,
};

function AuthorItem({ author, index }) {
  const imageuri = `${author?.avatar}`;
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} className="divider_mt">
        {/* <Avatar alt={author.name} src={author.avatar} /> */}
        <span className="icon-bg">
          {/* <Iconify icon={'healthicons:p'} sx={{ width: 20, height: 22 }} /> */}
          {author.name.charAt(0).toUpperCase()}
        </span>

        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2" className="author-name-new">
            {author.name}
          </Typography>

          {/* <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
          }}
        >
          <Iconify icon={'bx:run'} sx={{ width: 16, height: 16, mr: 0.5 }} />
          {fShortenNumber(author.favourite)}
        </Typography> */}
        </Box>
        <Typography variant="body1" className="heading_14 greenclo author-text-new" sx={{}}>
          {author.corbanEmission}
        </Typography>
        <IconWrapperStyle
          className="icon_top_div"
          // sx={{
          //   ...(index === 1 && {
          //     color: 'info.main',
          //     bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
          //   }),
          //   ...(index === 2 && {
          //     color: 'error.main',
          //     bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          //   }),
          // }}
          backgroundColor="none"
        >
          <Image src={imageuri} width={10} height={10} className="cycle_img" />
          {/* <Iconify icon={author?.avatar} width={20} height={20} /> */}
        </IconWrapperStyle>
      </Stack>
      <Divider className="divider_mt" />
    </>
  );
}
