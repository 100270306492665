/* eslint-disable import/no-unresolved */
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// eslint-disable-next-line import/no-unresolved
import AdminRegisterForm from 'src/sections/auth/admin-register';
import useResponsive from '../../hooks/useResponsive';
// routes
// components
import Image from '../../components/Image';
import Logo from '../../components/Logo';
import Page from '../../components/Page';
// eslint-disable-next-line import/order
import { imagesAll } from 'src/assets/Images_index';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AdminRegister() {
  // const { "" } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Register" className="register">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Already have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to={'/'}>
                Login
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle className="section-style">
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} className="main_heading admin-heading">
              Register and onboard your Team TripShift!
            </Typography>
            <Image alt="register" visibleByDefault disabledEffect src={imagesAll.adminRegister} />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle className="container-style">
            <Box sx={{ mb: 5, alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom className="heading_24 admin-rightsidepad">
                  Create your Team Admin account.
                </Typography>
                <Typography sx={{ color: 'text.secondary' }} className="heading_16">
                  Free forever. No credit card needed.
                </Typography>
              </Box>
            </Box>

            <AdminRegisterForm />

            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }} className="heading_14">
              By signing up, I agree to TripShift Ltd &nbsp;
              <Link
                underline="always"
                color="text.primary"
                href="https://tripshift.eco/terms-conditions/"
                target="_blank"
              >
                Terms of Service
              </Link>
              {''} and {''}
              <Link
                underline="always"
                color="text.primary"
                href="https://tripshift.eco/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Typography>

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?{' '}
                <Link variant="subtitle2" to={'/'} component={RouterLink}>
                  Login
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
