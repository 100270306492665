/* eslint-disable import/no-unresolved */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
// @mui
import moment from 'moment';

import { Button, TableCell, TableRow, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
// import currentURL from '../../../../env';

// utils
// components

// ----------------------------------------------------------------------

UploadCsvTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function UploadCsvTableRow({ row, selected }) {
  const theme = useTheme();
  const { createdAt, fileName, status, errorFileName, correctFileName, totalRows, totalErrorRows } = row;
  console.log(status);
  const handleDownload = (name) => {
    // URL of the PDF file
    const pdfUrl = `${process.env.REACT_APP_SERVER}/StaticFiles/UserImportFiles/${name}`;

    // Fetch the file
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new object URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);

        // Append to the document
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the file', error);
      });
  };
  const downloadScussess = (name) => {
    handleDownload(name);
    // window.open(`${process.env.REACT_APP_SERVER}/StaticFiles/UserImportFiles/${name}`);
  };
  return (
    <>
      {' '}
      <TableRow hover selected={selected}>
        <TableCell>{moment(createdAt).format('DD/MM/YYYY')}</TableCell>
        <TableCell className="file-name">{fileName}</TableCell>
        <TableCell>
          {' '}
          <Label
            variant={theme.palette.mode === 'light' ? 'outlined' : 'outlined'}
            color={
              (status === 0 && 'warning') ||
              (status === 2 && 'success') ||
              (status === 1 && 'warning') ||
              (status === 3 && 'error')
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {status === 0 ? 'Pending' : status === 1 ? 'Procssing' : status === 3 ? 'error' : 'success'}
          </Label>
        </TableCell>
        <TableCell style={{ paddingLeft: '5.5%' }}>{totalRows}</TableCell>
        <TableCell style={{ paddingLeft: '5.5%' }}>{totalErrorRows}</TableCell>
        {}{' '}
        <TableCell>
          <>
            {errorFileName ? (
              // <Button onClick={() => downloadScussess(errorFileName)} variant="contained" color="error" sx={{ mb: 2 }}>
              //   Download Error File
              // </Button>
              <Tooltip title="Download error csv">
                <Button onClick={() => downloadScussess(errorFileName)} color="error">
                  <Iconify
                    icon={'material-symbols:download-sharp'}
                    color="error"
                    style={{ cursor: 'pointer', width: '25px', height: '25px' }}
                  />
                </Button>
              </Tooltip>
            ) : null}
            {correctFileName ? (
              // <Button onClick={() => downloadScussess(correctFileName)} variant="contained">
              //   Download Correct File
              // </Button>
              <Tooltip title="Download uploaded csv">
                <Button onClick={() => downloadScussess(correctFileName)}>
                  <Iconify
                    icon={'material-symbols:download-sharp'}
                    style={{ cursor: 'pointer', width: '25px', height: '25px' }}
                  />
                </Button>
              </Tooltip>
            ) : null}
          </>
          {status === 2 ? (
            // <Button onClick={() => downloadScussess(fileName)} variant="contained" sx={{ mt: 2 }}>
            //   Download Original File
            // </Button>
            <Tooltip title=" Download original csv">
              <Button onClick={() => downloadScussess(fileName)} sx={{ mt: 0 }}>
                <Iconify
                  icon={'material-symbols:download-sharp'}
                  color="error"
                  style={{ cursor: 'pointer', width: '25px', height: '25px' }}
                />
              </Button>
            </Tooltip>
          ) : null}
        </TableCell>{' '}
      </TableRow>
    </>
  );
}
