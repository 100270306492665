/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Dialog, Grid } from '@mui/material';
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import Iconify from 'src/components/Iconify';

export default function NudgeDialoge({
  open,
  team,
  company,
  companyEmail,
  setOpen,
  setReload,
  multiSelect,
  dataFiltered,
  row,
}) {
  const navigate = useNavigate();

  const handleCloseSuccess = () => {
    if (team) {
      navigate('/teams');
      // setReload('198');

      setOpen(false);
    } else if (company) {
      navigate('/customer');
      // setReload('198');
      setOpen(false);
    } else {
      navigate('/members');
      // setReload('198');
      setOpen(false);
    }
  };
  useEffect(() => {
    if (open === true) {
      const timer1 = setTimeout(() => handleCloseSuccess(), 3 * 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [open]);
  return (
    <div>
      <Dialog open={open} onClose={() => handleCloseSuccess()} className="toast-dialogs">
        <Grid container spacing={2} className="MemberAlert">
          <Grid item xs={2}>
            <div className="checkicon-bg">
              <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="" />
            </div>
          </Grid>
          <Grid item xs={8.5}>
            {team && multiSelect ? (
              <div className="email-value">A reminder has been sent to all selected Team Leads</div>
            ) : multiSelect ? (
              <div className="email-value">A reminder has been sent to all selected members</div>
            ) : company ? (
              <div>A reminder has been sent to: {companyEmail}</div>
            ) : (
              <div>
                A reminder has been sent to:{' '}
                {row?.name || row?.teamLead === ' ' ? row?.userEmail : row?.name || row?.teamLead}
              </div>
            )}
          </Grid>
          {/* <Grid item xs={1.5} className="cross_section">
            <Iconify icon={'akar-icons:cross'} className="alert_cross" onClick={() => handleCloseSuccess()} />
          </Grid> */}
        </Grid>
      </Dialog>
    </div>
  );
}
