/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable react/button-has-type */
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useState, useEffect } from 'react';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography, useTheme } from '@mui/material';
import InputMask from 'react-input-mask';

export default function CustomHoursDialog({
  updateDays,
  customOpen,
  setCustomOpen,
  setUpdateDays,
  setTimeOpne,
  newdays,
  setDayOpen,
  setNewDays,
  setCustomeBack,
}) {
  const [open, setOpen] = useState(customOpen || false);
  const setting = localStorage.getItem('settings');
  const [startError, setStartError] = useState(Array(updateDays.length).fill(false));
  const [endError, setEndError] = useState(Array(updateDays.length).fill(false));
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const regex = /(am|pm)\s*(?=\1)/gi; // Create a regular expression that matches duplicate "am" or "pm" strings
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const timeRegex = /^([0]?[1-9]|1[0-2]):([0-5]\d) ([AP][M])?$/;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (customOpen) {
      setCustomOpen(false);
      setOpen(false);
      setDayOpen(false);
      setNewDays([]);
      setError2(false);
      setEndError(Array(updateDays.length).fill(false));
    } else {
      setOpen(false);
      setError2(false);
      setEndError(Array(updateDays.length).fill(false));
    }
  };

  const handleBack = () => {
    if (customOpen) {
      setCustomOpen(false);
      setCustomeBack(updateDays);
      setDayOpen(true);
      setOpen(false);
      setError2(false);
      setEndError(Array(updateDays.length).fill(false));
    } else {
      setOpen(false);
      setError2(false);
      setEndError(Array(updateDays.length).fill(false));
    }
  };

  const handleStartTimeChange = (index, event, period) => {
    const Value = event?.target?.value;
    const newTimeSlots = [...updateDays];
    if (Value === '') {
      newTimeSlots[index].startTime = '';
    } else {
      newTimeSlots[index].startTime = `${Value} ${period}`.replace(regex, '');
    }
    setUpdateDays(newTimeSlots);
    if (
      event?.target?.value === '0' ||
      event?.target?.value === '00' ||
      event?.target?.value === '00:' ||
      event?.target?.value === '00:0' ||
      event?.target?.value === '00:00' ||
      event?.target?.value === ''
    ) {
      setError1(true);
      setStartError((prevState) => [...prevState.slice(0, index), true, ...prevState.slice(index + 1)]);
      event.target.setCustomValidity('Please enter time');
    } else {
      const [hours, minutes, meridiem] = Value?.split(/[ :]/);
      if ((meridiem === 'PM' && hours < 12) || (meridiem === 'AM' && hours === '12') || hours > 12 || minutes > 59) {
        setStartError((prevState) => [...prevState.slice(0, index), true, ...prevState.slice(index + 1)]);
        event.target.setCustomValidity('Invalid time value');
        setError1(true);
      } else {
        setError1(false);
        setStartError((prevState) => [...prevState.slice(0, index), false, ...prevState.slice(index + 1)]);
        event.target.setCustomValidity('');
      }
    }
  };

  const handleEndTimeChange = (index, event, period) => {
    const Value = event?.target?.value;
    const newTimeSlots = [...updateDays];

    if (Value === '') {
      newTimeSlots[index].endTime = '';
    } else {
      newTimeSlots[index].endTime = `${Value} ${period}`.replace(regex, '');
    }
    setUpdateDays(newTimeSlots);
    const startValue = newTimeSlots[index].startTime;
    const EndValue = `${Value} ${period}`.replace(regex, '');

    if (
      event?.target?.value === '0' ||
      event?.target?.value === '00' ||
      event?.target?.value === '00:' ||
      event?.target?.value === '00:0' ||
      event?.target?.value === '00:00' ||
      event?.target?.value === ''
    ) {
      setError2(true);
      setEndError((prevState) => [...prevState.slice(0, index), true, ...prevState.slice(index + 1)]);
      event.target.setCustomValidity('Please enter time');
    } else {
      const [hours, minutes, meridiem] = EndValue?.split(/[ :]/);
      const [Starthours, Startminutes, Startmeridiem] = startValue?.split(/[ :]/);

      if (
        (meridiem === 'pm' && hours > 12) ||
        (meridiem === 'am' && hours === '12') ||
        hours > 12 ||
        minutes > 59 ||
        (meridiem === Startmeridiem && Starthours > hours) ||
        (meridiem === Startmeridiem && Starthours === hours)
      ) {
        setEndError((prevState) => [...prevState.slice(0, index), true, ...prevState.slice(index + 1)]);
        event.target.setCustomValidity('Invalid time value');
        setError2(true);
      } else {
        setError2(false);
        setEndError((prevState) => [...prevState.slice(0, index), false, ...prevState.slice(index + 1)]);
        event.target.setCustomValidity('');
      }
    }
  };

  const handleAmPmClick = (index, type, period) => {
    const newTimeSlots = [...updateDays];
    const time = newTimeSlots[index][type];
    const timeWithoutPeriod = time.replace(/ am| pm/, '');
    const formattedTime = `${timeWithoutPeriod} ${period}`.replace(regex, '');
    newTimeSlots[index][type] = formattedTime;

    const startTime = newTimeSlots[index].startTime;
    const endTime = newTimeSlots[index].endTime;

    const startHours = parseInt(startTime.split(':')[0]);
    const endHours = parseInt(endTime.split(':')[0]);

    const startAMPM = startTime.split(' ')[1];
    const endAMPM = endTime.split(' ')[1];

    if ((startAMPM === endAMPM && startHours > endHours) || (startAMPM === endAMPM && startHours === endHours)) {
      setEndError((prevState) => [...prevState.slice(0, index), true, ...prevState.slice(index + 1)]);
      //  event.target.setCustomValidity('Invalid time value');
      setError2(true);
      // You can display an error message or handle the error in your desired way.
    } else {
      setUpdateDays(newTimeSlots);
      setError2(false);
      setEndError((prevState) => [...prevState.slice(0, index), false, ...prevState.slice(index + 1)]);
    }
  };

  // const handleAmPmClick = (index, type, period) => {
  //   const newTimeSlots = [...updateDays];
  //   const time = newTimeSlots[index][type];
  //   const timeWithoutPeriod = time.replace(/ am| pm/, '');
  //   const formattedTime = `${timeWithoutPeriod} ${period}`.replace(regex, '');
  //   newTimeSlots[index][type] = formattedTime;
  //   setUpdateDays(newTimeSlots);
  // };

  const handleSaveTime = () => {
    const allStartTimesEqual = updateDays?.every(
      (event) => event?.startTime === updateDays[0]?.startTime && event?.timeGroupId === 1
    );
    if (allStartTimesEqual) {
      setNewDays(updateDays);
      setOpen(false);
      setTimeOpne(false);
    } else if (customOpen) {
      setDayOpen(false);

      const update = updateDays.filter((user) => user.selected === true);
      const updatedArray = update?.map((obj) => {
        return {
          ...obj,
          timeGroupId: 3,
        };
      });
      setNewDays(updatedArray);
      setOpen(false);
      setTimeOpne(false);
      setCustomOpen(false);
    } else {
      const update = updateDays.filter((user) => user.selected === true);
      const updatedArray = update?.map((obj) => {
        return {
          ...obj,
          timeGroupId: 3,
        };
      });
      setNewDays(updatedArray);
      setOpen(false);
      setTimeOpne(false);
    }
  };

  const handlecheckbox = (event) => {
    const newTimeSlots = [...updateDays];
    const updatedCheckboxes = newTimeSlots?.map((checkbox) =>
      checkbox?.day === parseInt(event.target.name) ? { ...checkbox, selected: event.target.checked } : checkbox
    );
    // const updateDays = updatedCheckboxes.filter((user) => user.selected === true);
    setUpdateDays(updatedCheckboxes);
  };

  useEffect(() => {
    const state = updateDays.some((event) => event.startTime === '' || event.endTime === '');
    if (state) {
      setError(true);
    } else {
      setError(false);
    }
  }, [updateDays]);
  return (
    <div>
      <Typography
        variant="body2"
        color="primary"
        sx={{ cursor: 'pointer', mt: 2, textDecoration: 'underline' }}
        onClick={handleClickOpen}
      >
        I work different hours each day {'>'}{' '}
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-hour-dialogbox"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mb: 2, ml: 1 }}>
          Please select your custom work days & hours (including your commute):
        </DialogTitle>
        <DialogContent>
          {/* <Stack spacing={3} direction={{ xs: 'column', sm: 'row'  }}> */}
          <div>
            {updateDays?.map((data, index) => {
              return (
                <div className="sethour-new-box" key={index}>
                  <div style={{ textAlign: 'center' }}>
                    <FormControlLabel
                      control={<Checkbox name={data?.day} checked={data?.selected} onChange={handlecheckbox} />}
                      label={
                        data?.day === 2
                          ? 'Monday'
                          : data?.day === 3
                          ? 'Tuesday'
                          : data?.day === 4
                          ? 'Wednesday'
                          : data?.day === 5
                          ? 'Thursday'
                          : data?.day === 6
                          ? 'Friday'
                          : data?.day === 7
                          ? 'Saturday'
                          : 'Sunday'
                      }
                    />
                  </div>

                  <Typography variant="body1" sx={{ mt: 1.5, mb: 0.5 }} className="header_dark_heading">
                    Start time
                  </Typography>
                  <div className="time-inner-box">
                    <InputMask
                      maskChar={null}
                      mask="99:99"
                      pattern={timeRegex}
                      value={data?.startTime}
                      maskChar={null}
                      disabled={data?.selected ? false : true}
                      maskPlaceholder="HH:MM"
                      onChange={(e) => handleStartTimeChange(index, e, data.startTime.includes('am') ? 'am' : 'pm')}
                    >
                      {(inputProps) => <TextField {...inputProps} fullWidth placeholder="HH:MM" />}
                    </InputMask>
                    <button
                      className={data.startTime.includes('am') ? 'ambtn activetime' : 'pmbtn disabletime'}
                      onClick={() => handleAmPmClick(index, 'startTime', data.startTime.includes('am') ? 'pm' : 'am')}
                      disabled={data?.selected ? false : true}
                    >
                      AM
                    </button>
                    <button
                      className={data.startTime.includes('am') ? ' pmbtn disabletime' : 'ambtn activetime'}
                      onClick={() => handleAmPmClick(index, 'startTime', data.startTime.includes('am') ? 'pm' : 'am')}
                      disabled={data?.selected ? false : true}
                    >
                      PM
                    </button>
                  </div>
                  {startError[index] ? (
                    <p className="error-message license-error" style={{ marginLeft: '14px' }}>
                      {'Please Enter Valid Time '}
                    </p>
                  ) : null}
                  <Typography variant="body1" sx={{ mt: 2, mb: 0.5 }} className="header_dark_heading">
                    End time
                  </Typography>
                  <div className="time-inner-box">
                    <InputMask
                      maskChar={null}
                      mask="99:99"
                      pattern={timeRegex}
                      value={data?.endTime}
                      maskChar={null}
                      maskPlaceholder="HH:MM"
                      disabled={data?.selected ? false : true}
                      onChange={(e) => handleEndTimeChange(index, e, data.endTime.includes('am') ? 'am' : 'pm')}
                    >
                      {(inputProps) => <TextField {...inputProps} fullWidth placeholder="HH:MM" />}
                    </InputMask>
                    <button
                      className={data.endTime.includes('am') ? 'ambtn activetime' : 'pmbtn disabletime'}
                      onClick={() => handleAmPmClick(index, 'endTime', data.endTime.includes('am') ? 'pm' : 'am')}
                      disabled={data?.selected ? false : true}
                    >
                      AM
                    </button>
                    <button
                      className={data.endTime.includes('am') ? ' pmbtn disabletime' : 'ambtn activetime'}
                      onClick={() => handleAmPmClick(index, 'endTime', data.endTime.includes('am') ? 'pm' : 'am')}
                      disabled={data?.selected ? false : true}
                    >
                      PM
                    </button>
                  </div>
                  {endError[index] ? (
                    <p className="error-message license-error" style={{ marginLeft: '14px' }}>
                      {'Please Enter Valid Time '}
                    </p>
                  ) : null}
                </div>
              );
            })}
          </div>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'normal', sm: 'flex-end' }}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
            sx={{ mt: 3, px: 2, pb: 2 }}
            spacing={2}
            // className="form-step-btnend"
          >
            <Button
              size="large"
              onClick={handleBack}
              variant="contained"
              className={mode?.themeMode === 'dark' ? 'cancle_btn-team cancle_btn_shadow' : ' cancle_btn-team '}
              sx={{ textTransform: 'inherit', boxShadow: 'none !important', mr: '0 !important' }}
            >
              Back
            </Button>
            {error === false && error1 === false && error2 === false ? (
              <Button
                type="button"
                onClick={handleSaveTime}
                size="large"
                variant="contained"
                className="next-select-btn"
                sx={{ textTransform: 'inherit', boxShadow: 'none' }}
              >
                Save changes
              </Button>
            ) : (
              <Button
                type="button"
                // onClick={handleSaveTime}
                size="large"
                disabled
                variant="contained"
                className="next-select-btn"
                sx={{ textTransform: 'inherit', boxShadow: 'none' }}
              >
                Save changes
              </Button>
            )}
            <Button
              size="large"
              onClick={handleClose}
              variant="contained"
              className={mode?.themeMode === 'dark' ? 'cancle_btn-team cancle_btn_shadow' : ' cancle_btn-team '}
              sx={{ textTransform: 'inherit', boxShadow: 'none !important' }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
