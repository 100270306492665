/* eslint-disable no-debugger */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Stack } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../../routes/paths';
// components
import { useState } from 'react';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/service/AxiosAll';
import { Link as RouterLink } from 'react-router-dom';

import { FormProvider, RHFTextField } from '../../../components/hook-form';
// ----------------------------------------------------------------------
export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const [message, setMessage] = useState();
  const [type, setType] = useState('');
  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });
  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: '' },
  });
  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const onSubmit = async (data) => {
    // eslint-disable-next-line no-debugger
    setMessage('');
    const data1 = {
      email: data?.email,
      origin: window?.location?.origin,
    };
    try {
      const res = await AxiosAll('post', '/api/user/forgot-password', data1, token);
      if (res?.status === 200) {
        setType('success');
        setMessage(res?.data?.message);
      } else {
        setType('error');
        setMessage(res?.response?.data?.title || res?.response?.data?.message);
      }
    } catch (error) {
      console.error(error);
      // reset();
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Reset Password
        </LoadingButton>
        <Button fullWidth size="large" component={RouterLink} to={'/login'} sx={{ mt: 1 }} className="backbtn">
          Back
        </Button>
        {message ? <Alert severity={type}>{message}</Alert> : null}
      </Stack>
    </FormProvider>
  );
}
