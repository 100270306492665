import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IoMdDownload } from 'react-icons/io';
import Image from '../Image';

export default function PdfCard({ title, pdfUrl, imgUrl }) {
  // eslint-disable-next-line no-unused-vars
  const onIconPress = () => {
    return;
    // eslint-disable-next-line no-unreachable
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${title}.pdf`; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="pdf-card">
      <Typography variant="body2" fontWeight={600} sx={{ pb: 0.5 }}>
        {title}
      </Typography>
      <div className="pdf-container">
        <Link to={pdfUrl} target="_blank" rel="noopener noreferrer">
          <Image src={imgUrl} className="pdf-img" />
          <IoMdDownload size={45} color="#fff" className="icon" />
        </Link>
      </div>
    </div>
  );
}

PdfCard.propTypes = {
  title: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
};
