/* eslint-disable no-debugger */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/service/AxiosAll';
import { updateRole } from 'src/redux/actions/login';
// components
// eslint-disable-next-line import/no-unresolved
// import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function RemoveLeadConfirm({
  openConfirmationRemoveLead,
  setOpenConfirmationRemoveLead,
  row,
  deleteFromRow,
  setReload,
  setOpenSuccessModal,
  setSuccessRemove,
  selectedUserIds,
  setRoleKey,
  userName,
  userEmail,
  userId,
  roleId,
  DeleteUserIds,
}) {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleUpdateRole = async () => {
    if (userId) {
      const dataObject = {
        userId,
        roleId: roleId === 5 ? 1 : 3,
      };

      const res = await AxiosAll('post', '/api/user/update-role', [dataObject], token);
      if (res?.status === 200) {
        selectedUserIds?.push(userId);
        dispatch(updateRole(1));
        setRoleKey('Remove Team Tead');
        setOpenConfirmationRemoveLead(false);
        setSuccessRemove(true);
        setOpenSuccessModal(true);
      }
    }
  };
  const onSubmit = async () => {
    // eslint-disable-next-line no-debugger
    if (userId) {
      const dataObject = {
        userId: [userId],
      };
      const res = await AxiosAll('post', '/api/user/delete-member', dataObject, token);
      if (res?.status === 200) {
        DeleteUserIds?.push(userId);
        setOpenConfirmationRemoveLead(false);
        setSuccessRemove(true);
        setOpenSuccessModal(true);
      }
    }
  };
  const name = userName === ' ' ? userEmail : userName;
  return (
    <>
      {/* //delete confirmation  */}
      <Dialog open={openConfirmationRemoveLead}>
        <DialogContent className="delete-alert">
          {/* <DialogContentText id="alert-dialog-description"> */}
          {deleteFromRow ? (
            `You are about to delete ${userName} from the TripShift Platform. Confirm?`
          ) : (
            <Typography
              variant="body1"
              className={mode?.themeMode === 'dark' ? 'heading_18_dark heading_18 boldttext' : 'heading_18 boldttext'}
            >
              Confirm removal of {userName} as Team Lead?
            </Typography>
          )}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions className="btn-section-alert">
          {deleteFromRow ? (
            <Button onClick={onSubmit} variant="contained" size="medium" className="confirm-btn">
              Confirm
            </Button>
          ) : (
            <Button onClick={handleUpdateRole} variant="contained" size="medium" className="confirm-btn">
              Confirm
            </Button>
          )}
          <Button
            onClick={() => setOpenConfirmationRemoveLead(false)}
            variant="contained"
            className="cancle_btn"
            size="medium"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
