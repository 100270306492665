import {
  LOGIN,
  LOGOUT,
  UPDATEIMAGE,
  UPDATEROLE,
  UPDATENAME,
  TOKEN,
  EMAIL,
  EXPIREON,
  USERTOKEN,
  RESET,
} from '../constant';

const initialState = {
  userDetails: {},
  userRole: '',
  profilePic: null,
  profileName: null,
  token: null,
  AllToken: '',
  email: '',
  userguid: '',
};

// eslint-disable-next-line default-param-last
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, userDetails: { ...action.data } };
    case LOGOUT:
      return { ...state, userDetails: {}, userRole: '' };
    case UPDATEROLE:
      return { ...state, userRole: action };
    case UPDATEIMAGE:
      return { ...state, profilePic: action?.data };
    case UPDATENAME:
      return { ...state, profileName: action?.data };
    case TOKEN:
      return { ...state, token: action?.data };
    case EXPIREON:
      return { ...state, AllToken: action?.data };
    case USERTOKEN:
      return { ...state, userguid: action?.data };
    case EMAIL:
      return { ...state, email: action?.data };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;
