/* eslint-disable import/no-unresolved */
/* eslint-disable no-unneeded-ternary */
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// import { imagesAll } from 'src/assets/Images_index';

// eslint-disable-next-line import/no-unresolved

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_8],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { userDetails, userRole, profilePic, profileName } = useSelector((state) => state.loginReducer);

  const RoleCheck = (role) => {
    switch (role) {
      case 1:
        return 'Admin';
      case 2:
        return 'Team Lead';
      case 5:
        return 'Admin';
      default:
        return 'null';
    }
  };

  function App(props) {
    const { role } = props;
  }
  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar src={userDetails?.photoURL} alt="Rayan Moran" />
        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap className="heading_14">
            {profileName ? profileName : `${userDetails?.data?.data?.firstName} ${userDetails?.data?.data?.lastName}`}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }} className="heading_14">
            {/* {userRole?.data === 1 || userRole?.data === 5 ? 'Admin' : 'Team Lead'} */}
            {RoleCheck(userRole?.data)}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
