/* eslint-disable import/order */
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, Stack, Divider, CardHeader, Typography } from '@mui/material';
// hooks
// eslint-disable-next-line import/no-unresolved
import { imagesAll } from 'src/assets/Images_index';
// eslint-disable-next-line import/no-unresolved
import Image from 'src/components/Image';
import useResponsive from '../../../../hooks/useResponsive';
// components
import { BaseOptionChart } from '../../../../components/chart';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  '& .apexcharts-legend': {
    width: 240,
    margin: 'auto',
    justifyContent: 'flex-start !important',
    [theme.breakpoints.up('sm')]: {
      // flexWrap: 'wrap',
      height: '315',
      width: '100%',
    },
  },
  '& .apexcharts-datalabels-group': {
    display: 'none',
  },
}));

// ----------------------------------------------------------------------

BankingExpensesCategories.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartColors: PropTypes.array.isRequired,
};

export default function BankingExpensesCategories({ title, subheader, chartColors, chartData, ...other }) {
  const theme = useTheme();
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const isDesktop = useResponsive('up', 'sm');
  const { userDetails, userRole } = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();

  const chartLabels = chartData?.map((i) => i.label);

  const chartSeries = chartData?.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    labels: chartLabels,
    colors: chartColors,
    stroke: {
      colors: [theme.palette.background.paper],
    },
    fill: { opacity: 0.8 },

    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
    },
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        options: {
          legend: {
            position: 'bottom',
            horizontalAlign: 'left',
          },
        },
      },
    ],
  });

  const TeamPage = () => {
    navigate('/members');
  };

  return (
    <RootStyle {...other} className="eight-card banking_text_section">
      {subheader || title ? (
        <CardHeader
          title={title}
          subheader={subheader}
          className={mode?.themeMode === 'dark' ? 'light_color_heading' : 'travel-mode'}
        />
      ) : null}

      <Box sx={{ mb: 2, mt: 2 }} dir="ltr">
        {chartData?.length ? (
          <ReactApexChart type="polarArea" series={chartSeries} options={chartOptions} height={isDesktop ? 350 : 380} />
        ) : (
          <>
            {' '}
            <Typography variant="h6" sx={{ ml: 4 }}>
              Looking empty?
            </Typography>
            {userRole?.data === 2 ? (
              <>
                <Image src={imagesAll.maintenance} sx={{ mt: 5 }} className="error404_img" />

                <Typography
                  onClick={TeamPage}
                  variant="body1"
                  className="heading_14 greenclo textstyle"
                  sx={{ textAlign: 'end', mt: 6, pr: 3 }}
                >
                  Invite team members{' '}
                </Typography>
              </>
            ) : (
              <Image src={imagesAll.activity_level} sx={{ mt: 5 }} className="error404_img" />
            )}
          </>
        )}
      </Box>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} />
    </RootStyle>
  );
}
