/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-debugger */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
// import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// form
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  // Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
// hooks
import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Page from 'src/components/Page';
// import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';

// _mock
// components
import { SignIn } from 'src/AuthConfig';
import Iconify from 'src/components/Iconify';
import { addLogin, getAccessToken, getEmail, getToken, updateImage, updateRole } from 'src/redux/actions/login';
import WorkingHoursDialog from 'src/sections/mui/dialog/workingHours';
import AxiosAll from 'src/service/AxiosAll';
import useAuth from '../../../../hooks/useAuth';
// import { fData } from '../../../../utils/formatNumber';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';

// ----------------------------------------------------------------------
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFF !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF !important',
    color: '#919EAB !important',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function AccountGeneral({ useremail, setLoading, GUID, UserToken, platForm, Member, userData }) {
  // Initialize variables and states
  const location = useLocation();
  const UserEmail = location?.state?.Email;
  const LoginToken = location?.state?.LoginToken;
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, userguid } = useSelector((state) => state.loginReducer);
  console.log('userguid', userguid);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [aboutOption, setAboutOption] = useState([]);
  const [imageError, setImageError] = useState('');
  const [uploadurl, setUploadUrl] = useState(false);
  const [show, setShow] = useState(false);
  const [days, setDays] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const { instance, accounts } = useMsal();
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const msalInstance = new PublicClientApplication(SignIn);

  const queryParams = new URLSearchParams(location.hash.slice(1)); // Remove the '#' and create URLSearchParams

  const idToken = queryParams.get('id_token');
  const error = queryParams.get('error');

  // Define a Yup schema for updating user information
  // added max char check
  const UpdateUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'First name should not contain a number')
      .required('First name is required')
      .max(50, 'First name must be less then 50 characters'),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, 'Last name should not contain a number')
      .required('Last name is required')
      .max(50, 'Last name must be less then 50 characters'),
    jobTitle: Yup.string().required('Job title is required').max(50, 'Job title must be less then 50 characters'),
    travelMethod: Yup.string().when('commuteDaysInWeek', {
      is: (commuteDaysInWeek) => commuteDaysInWeek !== 'true',
      then: Yup.string().required('Travel method is required'),
      otherwise: Yup.string().notRequired(),
    }),
    TermAndCondition: Yup.bool().oneOf([true], 'Please accept the Terms &    Conditions'),
    DataAndPolicy: Yup.bool().oneOf([true], 'Please accept the Data Policy'),
    commuteDaysInWeek: Yup.string().required('Commute days per week is required'),
    // added validation for distance
    commuteDistance: Yup.string().when('commuteDaysInWeek', {
      is: (commuteDaysInWeek) => commuteDaysInWeek !== 'true',
      then: Yup.string().required('Commute distance is required'),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const firstDayOfWeek = days?.length ? days[0]?.day : null;
  const lastDayOfWeek = days?.length && days?.length !== 1 ? days[days?.length - 1]?.day : null;

  // Effect hook to decode and set GUID when 'idToken' changes

  // Effect hook to handle errors and navigate to the login page when 'error' changes
  useEffect(() => {
    if (error) {
      navigate('/login');
      dispatch(getToken(''));
    }
  }, [error]);
  // Define a function to get the name of the day of the week
  const getDayOfWeek = (day) => {
    switch (day) {
      case 1:
        return 'Sunday';
      case 2:
        return 'Monday';
      case 3:
        return 'Tuesday';
      case 4:
        return 'Wednesday';
      case 5:
        return 'Thursday';
      case 6:
        return 'Friday';
      case 7:
        return 'Saturday';
      default:
        return 'null';
    }
  };
  // Define a function to get a formatted string of days of the week
  const getDaysOfWeek = (firstDay, lastDay) => {
    const firstDayName = getDayOfWeek(firstDay);
    const lastDayName = getDayOfWeek(lastDay);
    return `${lastDayName === null ? `${firstDayName}` : `${firstDayName} - ${lastDayName}`}`;
  };

  function splitString(string) {
    if (string.includes('-')) {
      return string.split('-')[0];
    }
    return string;
  }
  // Define default values for the form
  const getModebase = async () => {
    // eslint-disable-next-line no-debugger
    const language = splitString(navigator?.language || 'en');
    const res = await AxiosAll('get', `/api/function/get-moderef?language=${language}`, {});
    if (res?.status === 200) {
      setAboutOption(res?.data?.data);
    } else {
      // setOpen(true)
    }
  };

  // Define default values for the form
  const defaultValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    jobTitle: userData?.jobTitle || '',
    travelMethod: '',
    photoUrl: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png',
    commuteDistanceType: 'Mi',
    commuteDistance: '',
    commuteDaysInWeek: '',
    isWFH: false,
    TermAndCondition: false,
    DataAndPolicy: false,
  };

  // Initialize a form using React Hook Form with Yup validation resolver
  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
    mode: 'onChange',
  });

  // Extract form methods and state
  const {
    reset,
    setValue,
    setError,
    setFieldTouched,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();
  //   console.log('dataObject', dataObject);
  // Define a function to handle form submission
  const onSubmit = async (values) => {
    const UnauthorizedToken = `Bearer ${LoginToken}`;
    // const GUID = idToken !== null && jwtDecode(idToken);
    setUpdateLoading(true);
    const dataObject = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: UserEmail,
      jobTitle: values?.jobTitle,
      photoURL: typeof values?.photoUrl === 'object' ? '' : values?.photoUrl,
      // about: values?.about,
      days: days,
      travelMethod: values?.travelMethod === '' ? '0' : values?.travelMethod,
      commuteDistanceType: values?.commuteDistanceType,
      commuteDistance: values?.commuteDistance ? values?.commuteDistance : 0,
      commuteDaysInWeek:
        values?.commuteDaysInWeek === 'true' || values?.commuteDaysInWeek === '' ? '0' : values?.commuteDaysInWeek,
      isWFH: values?.commuteDaysInWeek === 'true' ? true : false,
      userGuid: GUID,
      IsDataPolicy: values?.DataAndPolicy,
      IsTermsConditions: values?.TermAndCondition,
    };

    if (values?.firstName && values?.lastName) {
      try {
        const res = await AxiosAll('post', '/api/user/setup-account-guid', dataObject, UserToken);
        if (res?.status === 200) {
          updateIsProfileComplete();
        } else {
          setErrorMessage(res?.response?.data?.message || res?.response?.data?.title);
          setUpdateLoading(false);
          setValue('isWFH', false);
        }
        dispatch(getToken(''));
        dispatch(getEmail(''));
      } catch (error) {
        console.error(error);
        setUpdateLoading(false);
        dispatch(getToken(''));
        dispatch(getEmail(''));
      }
    }
  };

  const updateIsProfileComplete = async () => {
    setLoading(true);
    try {
      const res = await AxiosAll('post', `/api/profile/${GUID}/${UserEmail}`, '', UserToken);
      console.log('res', res);
      if (res?.status === 204) {
        if (platForm && UserToken && Member === false) {
          LoginAzure();
        } else if (platForm && Member === true) {
          // platfrom view
          navigate(`/successfull-complete-profile?platform=${platForm}`);
          setUpdateLoading(false);
        } else {
          // Android, IOS view
          navigate(`/successfull-complete-profile`);
          setUpdateLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const LoginAzure = async (authToken) => {
    try {
      const response = await AxiosAll('post', '/api/user/loginazure', '', UserToken);
      // eslint-disable-next-line no-debugger
      if (response?.status === 200) {
        if (response?.data?.isDeleted === false) {
          await dispatch(addLogin(response));
          await dispatch(updateRole(response?.data?.data?.roleId));
          await dispatch(updateImage(response?.data?.data?.photoURL));
          await dispatch(getAccessToken(response?.data?.data?.token));

          if (
            response?.data?.data?.roleId === 2 &&
            response?.data?.data?.assignTeamId === response?.data?.data?.defaultTeamId
          ) {
            await navigate('/create-team-stepOne', { state: { createNew: true } });
          } else if (
            response?.data?.data?.roleId === 2 &&
            response?.data?.data?.assignTeamId !== response?.data?.data?.defaultTeamId
          ) {
            await navigate('/dashboard');
          } else {
            await navigate('/dashboard');
          }
        } else {
          setErrorMessage(
            'You do not have access to this page. Please contact TripShift support for further assistance'
          );
        }
      }
      setUpdateLoading(false);
    } catch (error) {
      setUpdateLoading(false);
      setErrorMessage(error?.response?.data?.message);
      console.error(error);
    }
  };

  // Define a function to handle image upload
  const handleUpload = async () => {
    // eslint-disable-next-line no-debugger
    setUpdateLoading(true);
    setUploadUrl(true);
    const formData = new FormData();
    formData.append('file', values?.photoUrl);
    // eslint-disable-next-line no-debugger
    try {
      const res = await AxiosAll('post', '/api/utilities/upload-image', formData, token, 'multipart/form-data');
      if (res?.status === 200) {
        setValue('photoUrl', res?.data?.data?.imageUrl);
        setUploadUrl(false);
        setUpdateLoading(false);
      } else {
        setImageError(res?.response?.data?.message);
        setUploadUrl(false);
        setValue('photoUrl', '');
        setUpdateLoading(false);
      }
    } catch (error) {
      setUploadUrl(false);
      console.error(error);
      setUpdateLoading(false);
    }
  };

  // Effect hook to handle image upload when 'values.photoUrl' changes
  // useEffect(() => {
  //   if (typeof values?.photoUrl === 'object') {
  //     handleUpload();
  //   }
  // }, [values?.photoUrl]);

  // Define a function to handle dropping an image file
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'photoUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }));

  // Effect hook to clear error message after a timeout
  useEffect(() => {
    const timer1 = setTimeout(() => setErrorMessage(''), 3 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [errorMessage]);

  // Effect hook to fetch data when 'idToken' changes
  useEffect(() => {
    getModebase();
  }, []);

  // Define a function to handle single sign-out
  useEffect(() => {
    if (idToken !== null) {
      const GUID = jwtDecode(idToken);
      fetchData(GUID);
    }
  }, [idToken]);

  // Define a function to fetch data based on 'USERGUID'
  const fetchData = async (USERGUID) => {
    if (USERGUID) {
      setLoading(true);
      try {
        const response = await AxiosAll(
          'post',
          `/api/user/update-user-status?email=${USERGUID?.emails[0]}&userguid=${USERGUID?.sub}`,
          ''
        );
        if (response.status === 200) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  // useEffect(() => {
  //   if (values?.commuteDaysInWeek === 'true') {
  //     setValue('isWFH', true);
  //     methods.handleSubmit()(); // Calling the handleSubmit function with the onSubmit callback
  //   } else {
  //     setValue('isWFH', false);
  //   }
  // }, [values?.commuteDaysInWeek]);

  useEffect(() => {
    if (values?.commuteDaysInWeek === 'true') {
      setError('travelMethod', '');
      setError('commuteDistance', '');
    }
  }, [values?.commuteDaysInWeek]);

  return (
    <>
      {updateLoading ? (
        <Page title="set up account" className="setup-loader">
          <LoadingOverlay
            // eslint-disable-next-line react/jsx-boolean-value
            active={updateLoading}
            spinner
          >
            <RootStyle>
              <>
                {/* <LogoOnlyLayout /> */}
                <Container className="profile_section" sx={{}}>
                  <ContentStyle className="container-style-profile">
                    <Box sx={{ mb: 5 }} />
                  </ContentStyle>
                </Container>{' '}
              </>
            </RootStyle>
          </LoadingOverlay>
        </Page>
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={3.5}>
              <Card sx={{ py: 10, px: 1 }}>
                <RHFUploadAvatar
                  sx={{ textAlign: 'center' }}
                  name="photoUrl"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  user
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
              </Card>
              {imageError !== '' && <p className="error-message profile_error_message">{imageError}</p>}
            </Grid> */}

            <Grid item xs={12} md={8.5}>
              <Card sx={{ p: 3 }}>
                <Typography sx={{ mb: 2 }} variant="h6" className="heading_14">
                  *Required fields
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField name="firstName" label="First Name*" />
                  <RHFTextField name="lastName" label="Last Name*" />
                  <RHFTextField name="jobTitle" label="Job title*" />
                  <div className="mobilehide"> </div>
                  <div>
                    <LabelStyle
                      variant="subtitle2"
                      sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}
                      className={
                        mode?.themeMode === 'dark' ? ' white_color commute_text_top' : 'black_color commute_text_top'
                      }
                    >
                      <snap className="commute_text_new">
                        {' '}
                        How many days per week do you commute?
                        {'*'}
                      </snap>
                      <snap className="info_tool_tip mobileshow">
                        <BootstrapTooltip
                          title="Please input the average number of days you commute per week."
                          open={open3}
                          placement="top-start"
                        >
                          <IconButton onClick={() => setOpen3(!open3)}>
                            <Iconify icon={'material-symbols:info'} />
                          </IconButton>
                        </BootstrapTooltip>
                      </snap>
                      <snap className="info_tool_tip mobilehide">
                        <BootstrapTooltip title="Please input the average number of days you commute per week.">
                          <IconButton>
                            <Iconify icon={'material-symbols:info'} />
                          </IconButton>
                        </BootstrapTooltip>
                      </snap>
                    </LabelStyle>
                    <RHFSelect
                      style={{ marginTop: '8px' }}
                      fullWidth
                      name="commuteDaysInWeek"
                      className="select_input_field"
                    >
                      <option value={''} disabled>
                        Select days
                      </option>
                      <option value={'true'}>I usually work from home</option>
                      <option value={'1'}>1</option>
                      <option value={'2'}>2</option>
                      <option value={'3'}>3</option>
                      <option value={'4'}>4</option>
                      <option value={'5'}>5</option>
                      <option value={'6'}>6</option>
                      <option value={'7'}>7</option>
                    </RHFSelect>
                  </div>

                  <div>
                    <LabelStyle
                      variant="subtitle2"
                      sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}
                      className={
                        mode?.themeMode === 'dark' ? ' white_color commute_text_new' : 'black_color commute_text_top'
                      }
                    >
                      <snap className="commute_text_new">
                        How far do you commute?
                        {values?.commuteDaysInWeek === 'true' ? '' : '*'}
                      </snap>
                      <snap className="info_tool_tip mobileshow">
                        <BootstrapTooltip
                          placement="top-start"
                          title="Please input an estimation of the distance of your commute (one way)."
                          open={open2}
                        >
                          <IconButton onClick={() => setOpen2(!open2)}>
                            <Iconify icon={'material-symbols:info'} />
                          </IconButton>
                        </BootstrapTooltip>
                      </snap>
                      <snap className="info_tool_tip mobilehide">
                        <BootstrapTooltip
                          placement="bottom"
                          title="Please input an estimation of the distance of your commute (one way)."
                        >
                          <IconButton>
                            <Iconify icon={'material-symbols:info'} />
                          </IconButton>
                        </BootstrapTooltip>
                      </snap>
                    </LabelStyle>

                    <RHFTextField
                      style={{ marginTop: '8px' }}
                      name="commuteDistance"
                      endAdornment={
                        <InputAdornment position="end">
                          <Button
                            onClick={() => setValue('commuteDistanceType', 'Mi')}
                            className={
                              values?.commuteDistanceType === 'Mi' ? 'kmbtn activekmbtn' : 'kmbtn disablekmbtn'
                            }
                          >
                            mi
                          </Button>
                          <Button
                            onClick={() => setValue('commuteDistanceType', 'Km')}
                            className={
                              values?.commuteDistanceType === 'Km' ? 'kmbtn activekmbtn' : 'kmbtn disablekmbtn'
                            }
                          >
                            km
                          </Button>
                        </InputAdornment>
                      }
                    />
                  </div>

                  <div>
                    <LabelStyle
                      variant="subtitle2"
                      sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}
                      className={
                        mode?.themeMode === 'dark' ? ' white_color commute_text_top' : 'black_color commute_text_top'
                      }
                    >
                      <snap className="commute_text_new">
                        {' '}
                        How do you commute to work?
                        {values?.commuteDaysInWeek === 'true' ? '' : '*'}
                      </snap>

                      <snap className="info_tool_tip mobileshow">
                        <BootstrapTooltip
                          title="Please input the mode which takes up the largest distance in your commute. "
                          open={open1}
                          placement="top-start"
                        >
                          <IconButton onClick={() => setOpen1(!open1)}>
                            <Iconify icon={'material-symbols:info'} />
                          </IconButton>
                        </BootstrapTooltip>
                      </snap>
                      <snap className="info_tool_tip mobilehide">
                        <BootstrapTooltip title="Please input the mode which takes up the largest distance in your commute. ">
                          <IconButton>
                            <Iconify icon={'material-symbols:info'} />
                          </IconButton>
                        </BootstrapTooltip>
                      </snap>
                    </LabelStyle>
                    <RHFSelect
                      style={{ marginTop: '8px' }}
                      fullWidth
                      name="travelMethod"
                      className="select_input_field"
                    >
                      <option disabled value={''}>
                        Select mode
                      </option>
                      {aboutOption?.map((data, index) => (
                        <option value={data?.id} key={index}>
                          {data?.name}
                        </option>
                      ))}
                    </RHFSelect>
                  </div>

                  <div>
                    <Typography
                      variant="body2"
                      className="mobilehide asked_text_new"
                      onClick={() => setShow(!show)}
                      style={{ cursor: 'pointer' }}
                      sx={{ color: '#00AB55', textDecoration: 'underline', fontWeight: '600', mt: 4 }}
                    >
                      Why am I being asked this?
                    </Typography>
                    {show && (
                      <Typography
                        onClick={() => setShow(!show)}
                        variant="body2"
                        className="mobilehide"
                        sx={{ color: '#919EAB', fontWeight: '400', fontSize: '0.725rem', mt: 1 }}
                      >
                        Asking for your commuting information helps us to forecast your organisations emissions and
                        improve the accuracy of the TripShift app.
                      </Typography>
                    )}
                  </div>
                </Box>
                {/* <FormControlLabel
                  sx={{ mt: 2 }}
                  control={
                    <Checkbox
                      id={'my-checkbox'}
                      checked={values?.TermAndCondition}
                      onChange={(e) => {
                        setValue('isWFH', e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography variant="overline" className="policy-heading">
                      I mainly work from home
                    </Typography>
                  }
                /> */}

                <>
                  {' '}
                  <Typography variant="body2" sx={{ mt: 3, mb: 3 }} className="mainly-working">
                    Set your working hours (include commuting hours)
                    {/* Please set your working hours (include commuting hours): */}
                  </Typography>
                  {days?.length && days[0]?.timeGroupId === 1 ? (
                    <div className="edit_hour_section">
                      <card className="sethour-new-box">
                        <div className="time-new-box">
                          <Typography variant="subtitle1">
                            {days?.length ? getDaysOfWeek(firstDayOfWeek, lastDayOfWeek) : 'Monday - Friday'}
                          </Typography>

                          <Typography variant="subtitle1">
                            {days?.length
                              ? `${days[0]?.startTime.replace(/^0|:00/g, '')}- ${days[0]?.endTime.replace(
                                  /^0|:00/g,
                                  ''
                                )}`
                              : '7:00 am - 7:00 pm'}
                          </Typography>
                        </div>

                        <WorkingHoursDialog intialDay={true} setNewDays={setDays} newdays={days} token={UserToken} />
                      </card>
                    </div>
                  ) : days?.length && days[0]?.timeGroupId === 3 ? (
                    <div className="custom-new-box">
                      <div className="custom-card">
                        {days?.length &&
                          days?.map((data, key) => {
                            return (
                              <div className="custom-time-box" key={key}>
                                <Typography variant="subtitle1" sx={{ mb: 0.8 }}>
                                  {data?.day === 2
                                    ? 'Mon'
                                    : data?.day === 3
                                    ? 'Tue'
                                    : data?.day === 4
                                    ? 'Wed'
                                    : data?.day === 5
                                    ? 'Thu'
                                    : data?.day === 6
                                    ? 'Fri'
                                    : data?.day === 7
                                    ? 'Sat'
                                    : 'Sun'}
                                </Typography>
                                <Typography variant="body">
                                  {' '}
                                  {data?.startTime.replace(/^0|:00/g, '')}-{data?.endTime.replace(/^0|:00/g, '')}
                                </Typography>
                              </div>
                            );
                          })}
                      </div>
                      <WorkingHoursDialog setNewDays={setDays} newdays={days} token={UserToken} />
                    </div>
                  ) : (
                    <div className="edit_hour_section">
                      <card className="sethour-new-box">
                        <div className="time-new-box">
                          <Typography variant="subtitle1">Monday - Friday</Typography>
                          <Typography variant="subtitle1">7:00 am - 7:00 pm</Typography>
                        </div>

                        <WorkingHoursDialog setNewDays={setDays} newdays={days} token={UserToken} />
                      </card>
                    </div>
                  )}
                </>

                <FormControlLabel
                  sx={{ mt: 2 }}
                  control={
                    <Checkbox
                      id={'my-checkbox'}
                      checked={values?.TermAndCondition}
                      onChange={(e) => {
                        setValue('TermAndCondition', e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography variant="overline" className="policy-heading">
                      I have read and accept the TripShift Ltd{' '}
                      <Link
                        sx={{ textDecoration: 'underline' }}
                        href="https://tripshift.eco/terms-conditions/"
                        className={mode?.themeMode === 'dark' ? 'link-tag-text-dark' : 'link-tag-text'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        Terms & Conditions
                      </Link>
                    </Typography>
                  }
                />
                {errors.TermAndCondition && values?.TermAndCondition === false && (
                  <p className="error-message">{errors.TermAndCondition?.message}</p>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      id={'my-checkbox1'}
                      checked={values?.DataAndPolicy}
                      onChange={(e) => {
                        setValue('DataAndPolicy', e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography variant="overline" className="policy-heading">
                      I have read and consent to the TripShift Ltd
                      <Link
                        sx={{ textDecoration: 'underline' }}
                        href="https://tripshift.eco/privacy-policy/"
                        className={mode?.themeMode === 'dark' ? 'link-tag-text-dark' : 'link-tag-text'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        Data Policy
                      </Link>
                    </Typography>
                  }
                />
                {errors.DataAndPolicy && values?.DataAndPolicy === false && (
                  <p className="error-message">{errors.DataAndPolicy?.message}</p>
                )}
                <Typography
                  variant="body2"
                  onClick={() => setShow(!show)}
                  style={{ cursor: 'pointer' }}
                  className="mobileshow"
                  sx={{ color: '#00AB55', textDecoration: 'underline', fontWeight: '600', mt: 3, cursor: 'pointer' }}
                >
                  Why am I being asked this?
                </Typography>
                {show && (
                  <Typography
                    onClick={() => setShow(!show)}
                    variant="body2"
                    className="mobileshow"
                    sx={{ color: '#919EAB', fontWeight: '400', fontSize: '0.725rem', mt: 1 }}
                  >
                    Asking for your commuting information helps us to forecast your organisations emissions and improve
                    the accuracy of the TripShift app.
                  </Typography>
                )}
                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                  {uploadurl ? (
                    <LoadingButton type="submit" variant="contained" disabled>
                      Complete profile
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      sx={{ textTransform: 'inherit' }}
                    >
                      Complete profile
                    </LoadingButton>
                  )}
                </Stack>
              </Card>
              <div>
                {errorMessage ? (
                  <Alert severity="error" sx={{ mb: 3 }}>
                    <strong>{errorMessage}</strong>
                  </Alert>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
