/* eslint-disable import/no-unresolved */
/* eslintdisable import/nounresolved */
import { Button, Container, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';

// import { Link as RouterLink } from 'reactrouterdom';
import { Box, styled } from '@mui/system';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';
// import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { getToken } from 'src/redux/actions/login';

// import { useEffect } from 'react';

export default function SuccessfulSetup() {
  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  }));

  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const UserRegistration = params.get('Registration');
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-debugger

  useEffect(() => {
    setLoading(true);
    dispatch(getToken(''));
    handleLogOutSSo();
  }, [accounts]);
  const handleLogOutSSo = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: `${`${window.location.origin}/successfull-setup`}`,
      mainWindowRedirectUri: `${window.location.origin}`, // Specify the main window redirect URI
    });
    setLoading(false);
  };
  // console.log("UserRegistration", UserRegistration)
  return (
    <>
      <Box className="publicprofile">
        {/* <LogoOnlyLayout /> */}
        <Container maxWidth="md">
          <ContentStyle sx={{ textAlign: 'center', maxWidth: '100%' }} className="toppadd_reset  containerstyle">
            <Image src={imagesAll.seo} className="success_img_demo" style={{ marginBottom: '10px' }} />

            <Box>
              <Typography sx={{ mt: 1, mb: 2 }} variant="h3" className="main_heading">
                Thank you for registering on the TripShift platform!
              </Typography>

              <Typography
                sx={{ color: 'text.secondary', mb: 3, display: 'block' }}
                variant="paragraph"
                className="heading_14 thankyou_para_text"
              >
                Your TripShift account is all set. Download the TripShift app and complete your profile now to start
                reducing your organisations travel emissions. Use your sign-up credentials to log in!
              </Typography>

              <Box>
                <Button
                  size="large"
                  sx={{ mt: 4, mb: 4, textTransform: 'inherit' }}
                  className="backbtn"
                  variant="contained"
                  onClick={() => navigate('/login')}
                >
                  {' '}
                  Log in and complete profile
                </Button>
                <Stack direction="row" spacing={3} justifyContent="center" sx={{ mb: 4 }}>
                  <Image
                    src={imagesAll.newAppStore}
                    onClick={() => window.open('https://apps.apple.com/gb/app/tripshift/id1537958896')}
                    className="new_play_store"
                  />
                  <Image
                    src={imagesAll.newPlayStore}
                    onClick={() => window.open('https://play.google.com/store/apps/details?id=com.tripshift')}
                    className="new_play_store"
                  />
                </Stack>
              </Box>
            </Box>
          </ContentStyle>
        </Container>
      </Box>
    </>
  );
}
