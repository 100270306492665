/* eslint-disable no-debugger */
/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */

import { Container, Box, styled } from '@mui/material';
// routes
// hooks
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { useEffect, useState } from 'react';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { useNavigate } from 'react-router';
import { useMsal } from '@azure/msal-react';
import AxiosAll from 'src/service/AxiosAll';

import useTabs from '../hooks/useTabs';
import useSettings from '../hooks/useSettings';
// _mock_
// components

import Page from '../components/Page';
// import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
// sections
import SetupAccount from './SetupAccount';

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function Profile() {
  const { instance, accounts } = useMsal();
  const { token, userguid } = useSelector((state) => state.loginReducer);
  const params = new URLSearchParams(window.location.search);
  const userguids = params.get('id');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const { themeStretch } = useSettings();

  const { currentTab, onChangeTab } = useTabs('general');

  const UpdateRegister = async (values) => {
    setUpdateLoading(true);
    try {
      const res = await AxiosAll('post', `/api/user/update-user-registered?userguid=${userguid}`, '');
      if (res?.status === 200) {
        setUpdateLoading(false);
        navigate(`/successfull-setup`);

        // dispatch(getToken(''));
      }
    } catch (error) {
      setUpdateLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (userguids === null) {
      UpdateRegister();
    }
  }, [userguid]);
  console.log('userguid,', userguid);

  return (
    <>
      {userguids === null ? (
        <Page title="set up account" className="setup-loader publicprofile">
          <LoadingOverlay
            // eslint-disable-next-line react/jsx-boolean-value
            active
            spinner
          >
            <RootStyle>
              <>
                <LogoOnlyLayout />
                <Container className="profile_section" sx={{}}>
                  <ContentStyle className="container-style-profile">
                    <Box sx={{ mb: 5 }} />
                  </ContentStyle>
                </Container>{' '}
              </>
            </RootStyle>
          </LoadingOverlay>
        </Page>
      ) : (
        <SetupAccount />
      )}
    </>
  );
}
