/* eslint-disable import/no-unresolved */
import merge from 'lodash/merge';
import PropTypes from 'prop-types';

// @mui
import { Card, CardHeader } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// utils
import ReactApexChart from 'react-apexcharts';
import { fNumber } from 'src/utils/formatNumber';

// components
import { BaseOptionChart } from '../../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 250;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentDownload.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartData: PropTypes.array.isRequired,
};

export default function AppCurrentDownload({ title, subheader, chartData, chartColors, ...other }) {
  const theme = useTheme();
  // eslint-disable-next-line no-debugger
  const chartLabels = chartData?.map((i) => i.label);

  const chartSeries = chartData?.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: {
              formatter: (val) => fNumber(val),
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals?.reduce((a, b) => a + b, 0);
                return fNumber(sum);
              },
            },
          },
        },
      },
    },
  });

  return (
    <Card {...other} className="six-card">
      <>
        <CardHeader title={title} subheader={subheader} className="dashboard-card-heading" />
        <ChartWrapperStyle dir="ltr">
          {chartSeries ? (
            <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={190} />
          ) : null}
          {/* <Typography
            variant="body2"
            component="span"
            noWrap
            sx={{ color: 'text.secondary, ' }}
            className="past-dayes-text"
          >
            past 30 days
          </Typography> */}
        </ChartWrapperStyle>
      </>
    </Card>
  );
}
