import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function CreateTeamSkeleton() {
  return (
      <div className='tableskeleton'>
    <Stack spacing={1}>
    <table>
            <tr>
            <td className='delbtn' style={{marginLeft:"10px"}}>
                <Skeleton variant="rectangular" className="delbox"   />
                
                </td>
               
                <td className='proname'>
                    <Skeleton variant="text" className='name'/>
                </td>
                <td className='proname'>
                <Skeleton variant="text" className="price"  />
                </td>
            </tr>
    </table>
    <table>
            <tr>
            <td className='delbtn' style={{marginLeft:"10px"}}>
                <Skeleton variant="rectangular" className="delbox"   />
                
                </td>
               
                <td className='proname'>
                    <Skeleton variant="text" className='name'/>
                </td>
                <td className='proname'>
                <Skeleton variant="text" className="price"  />
                </td>
            </tr>
    </table>
    <table>
            <tr>
            <td className='delbtn' style={{marginLeft:"10px"}}>
                <Skeleton variant="rectangular" className="delbox"   />
                
                </td>
               
                <td className='proname'>
                    <Skeleton variant="text" className='name'/>
                </td>
                <td className='proname'>
                <Skeleton variant="text" className="price"  />
                </td>
            </tr>
    </table>
    <table>
            <tr>
            <td className='delbtn' style={{marginLeft:"10px"}}>
                <Skeleton variant="rectangular" className="delbox"   />
                
                </td>
               
                <td className='proname'>
                    <Skeleton variant="text" className='name'/>
                </td>
                <td className='proname'>
                <Skeleton variant="text" className="price"  />
                </td>
            </tr>
    </table>  
       
       

     
    </Stack> 
    </div>
  );
}