import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import PdfCard from '../../components/pdfCard/PdfCard';
import BannerCard from '../../components/carousel/BannerCard';
import AccordionUsage from '../../components/accordion/CustomAccordion';
import { imagesAll } from '../../assets/Images_index';
import { urls, FAQs } from './Constants';

export default function Resources() {
  const {
    userDetails: {
      data: { data: userData },
    },
  } = useSelector((state) => state.loginReducer);
  const para =
    'Here you’ll find access to step-by-step instructions, support and quick answers to questions you may have. If you can’t find what you’re looking for, you may find it in our set up manual!';

  const onSubmitRequestClick = () => {
    window.open(urls.trumpet, '_blank', 'noopener');
  };

  return (
    <div className="resources">
      <div className="resources-title">
        <Typography variant="h5">Resources</Typography>
        <Typography variant="body1" color="#637381">
          TripShift Platform
        </Typography>
      </div>
      <BannerCard para={para} onSubmitRequestClick={onSubmitRequestClick} userName={userData?.firstName || ''} />
      <div className="resources-board">
        <div className="resources-board-left-side">
          <Typography variant="h5" sx={{ mb: '38px' }}>
            Onboarding Material
          </Typography>
          <div className="pdf-link">
            <PdfCard title="Admin" pdfUrl={urls.pdfAdmin} imgUrl={imagesAll.pdfCard} />
            <PdfCard title="Team Lead" pdfUrl={urls.pdfTeamLead} imgUrl={imagesAll.pdfCard} />
            <PdfCard title="Member / Traveller" pdfUrl={urls.pdfMember} imgUrl={imagesAll.pdfCard} />
          </div>
        </div>
        <div className="resources-board-right-side faq_field-section">
          <Typography variant="h5" sx={{ mb: '38px' }}>
            FAQ
          </Typography>
          <AccordionUsage customClass="resources-faq" FAQs={FAQs} />
        </div>
      </div>
    </div>
  );
}
