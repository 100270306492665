/* eslint-disable react/jsx-boolean-value */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-debugger */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
// utils
// eslint-disable-next-line import/no-unresolved
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// eslint-disable-next-line import/no-unresolved
import useSettings from 'src/hooks/useSettings';
// eslint-disable-next-line import/no-unresolved
import Page from 'src/components/Page';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/service/AxiosAll';

// components
import { FormProvider, RHFTextField } from '../../components/hook-form';
// eslint-disable-next-line import/order, import/no-unresolved
import axios from 'axios';
import { useMemo } from 'react';
import { imagesAll } from 'src/assets/Images_index';
import Image from 'src/components/Image';
import EditLicenseDailog from 'src/sections/mui/dialog/EditLicenseDailog';
// import currentURL from '../../env';

// ----------------------------------------------------------------------

CustomerDetail.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function CustomerDetail() {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const Organisation = ['Marketing', 'Sales', 'Digital', 'IT'];
  const port = process.env.PORT || 'http://localhost:5000';
  // Initialize state variables using useState hook
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [customerDetail, setcustomerDetail] = useState();
  const setting = localStorage.getItem('settings');
  const [open, setOpen] = useState(false);
  const [editLisenceValue, setEditLisenceValue] = useState('');
  const [editnote, setEditNote] = useState(false);
  const [editnoteValue, setEditNoteValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [imageError, setImageError] = useState('');
  const [uploadurl, setUploadUrl] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const mode = JSON.parse(setting);

  // Define the default form values using an object
  const defaultValues = {
    address1: '',
    address2: '',
    zipcode: '',
    city: '',
    state: '',
    country: '',
  };

  // Define default edit values using useMemo
  const defaultEditValues = useMemo(
    () => ({
      address1: customerDetail?.address1,
      address2: customerDetail?.address2,
      zipcode: customerDetail?.postCode,
      city: customerDetail?.town,
      state: customerDetail?.stateCounty,
      country: customerDetail?.country,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editnote]
  );

  // Set up form validation schema using Yup
  useEffect(() => {
    if (editnote) {
      reset(defaultEditValues);
    }
  }, [editnote]);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Team name is required'),
    Organisation: Yup.string().required('Function in Organisation* is required'),
  });

  // Initialize form methods using useForm
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    defaultEditValues,
  });

  // Destructure methods and formState from useForm
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  const location = useLocation();
  const id = location?.state?.Id;
  const View = location?.state?.View;

  // Define a function to fetch customer details
  const getCustomerDetail = async () => {
    setLoadingPage(true);
    const res = await AxiosAll('get', `/api/Company/getcustomerdetail?companyId=${id}`, {}, token);

    if (res?.status === 200) {
      setcustomerDetail(res.data.data);
      setLoadingPage(false);
    }
    setLoadingPage(false);
  };

  // Define a callback function for handling file drops
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  // Define a function to update customer details
  const UpdateCustomerDetail = async () => {
    // address1 in case user change his address without enter press
    const address1 = values?.address1 === inputValue ? values?.address1 : inputValue;

    setLoading(true);
    const data = {
      companyId: id,
      licencePurchased: editLisenceValue !== '' ? editLisenceValue : customerDetail?.totalLicence,
      note: editnoteValue !== '' ? editnoteValue : customerDetail?.note,
      address1,
      address2: values?.address2,
      town: values?.city,
      stateCounty: values?.state,
      country: values?.country,
      postCode: values?.zipcode,
      imageUrl: typeof values?.avatarUrl === 'object' ? null : values?.avatarUrl,
    };
    const res = await AxiosAll('post', `/api/Company/update-customer`, data, token);

    if (res?.status === 200) {
      setLoading(false);
      setEditNote(false);
      getCustomerDetail();
    }
    setLoading(false);
  };

  // Set initial values for 'notes' field when customerDetail changes
  useEffect(() => {
    setValue('notes', customerDetail?.note);
  }, [customerDetail]);

  // Fetch customer details when the 'id' parameter changes
  useEffect(() => {
    if (id) {
      getCustomerDetail(id);
    }
  }, [id]);

  // Define a function to enable editing
  const handleEdit = () => {
    setEditNote(true);
  };

  // Define a function to cancel editing
  const handleCancle = () => {
    setEditNote(false);
  };

  // Define a function to handle changes in the 'note' field
  const handleNoteChnage = (e) => {
    setValue('notes', e.target.value);
    setEditNoteValue(e.target.value);
  };

  // Define a function to handle image uploads
  const handleUpload = async () => {
    // eslint-disable-next-line no-debugger
    setUploadUrl(true);
    setImageError('');
    const formData = new FormData();
    formData.append('file', values?.avatarUrl);
    // eslint-disable-next-line no-debugger
    try {
      const res = await AxiosAll(
        'post',
        '/api/utilities/upload-image',
        formData,
        userDetails?.data?.token,
        'multipart/form-data'
      );
      if (res?.status === 200) {
        setValue('avatarUrl', res?.data?.data?.imageUrl);
        setImageError('');
        setUploadUrl(false);
      } else {
        setUploadUrl(false);
        setValue('avatarUrl', customerDetail?.photoURL);
        setImageError(res?.response?.data?.message);
        const timer1 = setTimeout(() => setImageError(''), 3 * 1000);
        return () => {
          clearTimeout(timer1);
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Automatically handle image uploads when the 'avatarUrl' changes
  // useEffect(() => {
  //   if (typeof values?.avatarUrl === 'object') {
  //     handleUpload();
  //   }
  // }, [values?.avatarUrl]);

  // Define a function to handle input changes
  const handleInputChange = (e) => {
    if (e?.target?.value) {
      setInputValue(e?.target?.value);
    } else {
      setInputValue('');
      setPredictions([]);
      setValue('address1', '');

      setValue('zipcode', '');

      setValue('city', '');

      setValue('country', '');

      setValue('state', '');
    }
  };

  // Define a function to handle key presses for autocomplete
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAutocomplete();
    }
  };

  // Set the initial input value based on customerDetail and editnote
  useEffect(() => {
    if (customerDetail && editnote) {
      setInputValue(customerDetail?.address1);
    }
  }, [customerDetail, editnote]);

  const handleAutocomplete = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/Location/GetPlaces?text=${inputValue}`)
      .then((response) => {
        if (response?.data?.autoCompletePlaces && response?.data?.autoCompletePlaces?.length > 0) {
          const placePredictions = response?.data?.autoCompletePlaces;
          setPredictions(placePredictions);
        } else {
          setPredictions([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // Function to handle autocomplete when typing in the input field
  const handlePlaceSelect = (placeId) => {
    if (placeId) {
      axios
        .get(`${process.env.REACT_APP_SERVER}/api/Location/GetPlaceDetails?placeId=${placeId}`)
        .then((response) => {
          if (response.data.result && response.data.result.geometry && response.data.result.geometry.location) {
            const { location } = response.data.result.geometry;

            // Retrieve the formatted address using the selected location
            axios
              .get(
                `${process.env.REACT_APP_SERVER}/api/Location/GetFormatedAddress?lattitude=${location.lat}&longitude=${location.lng}`
              )
              .then((response) => {
                const addressComponents = response.data.results[0]?.address_components;
                setValue('address1', response.data.results[0].formatted_address);
                setInputValue(response.data.results[0].formatted_address);
                const postalCode = addressComponents?.find((component) => component.types.includes('postal_code'));
                const city = addressComponents?.find((component) => component.types.includes('postal_town'));
                const country = addressComponents?.find((component) => component.types.includes('country'));
                const state = addressComponents?.find((component) =>
                  component.types.includes('administrative_area_level_2')
                );

                if (postalCode) {
                  setValue('zipcode', postalCode.long_name);
                } else {
                  setValue('zipcode', '');
                }
                if (city) {
                  setValue('city', city.long_name);
                } else {
                  setValue('city', '');
                }
                if (country) {
                  setValue('country', country.long_name);
                } else {
                  setValue('country', '');
                }
                if (state) {
                  setValue('state', state.long_name);
                } else {
                  setValue('state', '');
                }
              })
              .catch((error) => {
                setValue('zipcode', '');

                setValue('city', '');

                setValue('country', '');

                setValue('state', '');
              });
          } else {
            setValue('zipcode', '');

            setValue('city', '');

            setValue('country', '');

            setValue('state', '');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Page title={View === true ? 'Customer Details - View' : 'Customer Details - View/Edit'} className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div>
          <Typography
            variant="h4"
            style={{ fontWeight: '700 !important' }}
            sx={{ pl: 0.5, mb: 0 }}
            className={mode?.themeMode === 'dark' ? 'light_link_text' : 'header_dark_heading customer-text-heading'}
          >
            {userRole?.data === 4 ? 'TripShift Customer Management Portal' : null}
          </Typography>

          {userRole?.data === 4 ? (
            <Typography
              variant="h5"
              sx={{ mt: 4, mb: 4, pl: 0.5 }}
              // noWrap
              className={mode?.themeMode === 'dark' ? 'header_light_heading' : 'header_dark_heading'}
            >
              Welcome {userDetails?.data?.data?.firstName} {userDetails?.data?.data?.lastName}{' '}
            </Typography>
          ) : null}
        </div>
        <HeaderBreadcrumbs
          heading={View === true ? 'Customer Details - View' : 'Customer Details - View/Edit'}
          links={[]}
        />
        <LoadingOverlay
          // eslint-disable-next-line react/jsx-boolean-value
          active={loadingPage}
          spinner
        >
          <FormProvider methods={methods}>
            <div className="boder-upper customer-detail-border">
              <Grid container spacing={3}>
                {/*   <Grid item xs={12} md={5}>
                  <Box sx={{ mt: 2 }}>
                  <div className="detail-page-profile">
                    <Avatar  />
                  </div>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      sx={{ alignItems: 'center', justifyContent: 'center', mt: 2 }}
                      spacing={1}
                      className="change-profile-img-edit"
                    >
                      {editnote === false ? (
                        <Image src={imagesAll.pencil} onClick={handleEdit} className="pencil_img_profile" />
                      ) : null}
                      {editnote === false ? (
                        <Typography
                          variant="body1"
                          sx={{ textTransform: 'capitalize', color: 'text.secondary' }}
                          className="heading12_edit"
                        >
                          Change Logo
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          className="heading12_edit"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                          }}
                        >
                          Allowed *.jpeg, *.jpg, *.png
                          <br /> max size of 3.1 MB
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                  {imageError !== '' && <p className="error-message profile_error_message">{imageError}</p>}
                </Grid> */}
                <Grid item xs={12} md={7}>
                  <div className="">
                    <Card sx={{ boxShadow: 'none', pt: 1, backgroundColor: 'transparent' }}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <Box sx={{ mb: 5 }}>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'capitalize' }}
                                className="heading12_edit"
                              >
                                Customer Name
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {customerDetail?.companyName !== null
                                  ? customerDetail?.companyName
                                  : 'not yet provided'}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Primary Admin Name
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 3 }}
                                className="heading12_edit"
                              >
                                {customerDetail?.primaryAdminName !== null
                                  ? customerDetail?.primaryAdminName
                                  : 'not yet provided'}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Primary Admin Email{' '}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 3 }}
                                className="heading12_edit"
                              >
                                {customerDetail?.primaryAdminEmail !== null
                                  ? customerDetail?.primaryAdminEmail
                                  : 'not yet provided'}
                              </Typography>
                              {/* location add    */}
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                  variant="overline"
                                  sx={{ textTransform: 'initial', mb: 1 }}
                                  className="heading12_edit"
                                >
                                  Main team location
                                </Typography>
                                <Image
                                  src={imagesAll.pencil}
                                  onClick={handleEdit}
                                  className="pencil_img_customer"
                                  sx={{ ml: 4 }}
                                />
                              </Box>
                              {editnote === false ? (
                                <>
                                  {customerDetail?.address1 ? (
                                    <Typography
                                      variant="body1"
                                      sx={{ color: 'text.secondary' }}
                                      className="heading12_edit"
                                    >
                                      {customerDetail?.address1}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      sx={{ color: 'text.secondary' }}
                                      className="heading12_edit"
                                    >
                                      not yet provided
                                    </Typography>
                                  )}
                                  <Typography
                                    variant="body1"
                                    sx={{ color: 'text.secondary' }}
                                    className="heading12_edit"
                                  >
                                    {customerDetail?.address2}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: 'text.secondary' }}
                                    className="heading12_edit"
                                  >
                                    {customerDetail?.town}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: 'text.secondary' }}
                                    className="heading12_edit"
                                  >
                                    {customerDetail?.stateCounty}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: 'text.secondary' }}
                                    className="heading12_edit"
                                  >
                                    {customerDetail?.postCode}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: 'text.secondary', mb: 3 }}
                                    className="heading12_edit"
                                  >
                                    {customerDetail?.country}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <br />
                                  <div className="google-place">
                                    <Autocomplete
                                      freeSolo
                                      options={predictions.map((prediction) => prediction.description)}
                                      inputValue={inputValue}
                                      onInputChange={handleInputChange}
                                      onKeyPress={handleKeyPress}
                                      open={true}
                                      name="address1"
                                      renderInput={(params) => (
                                        <TextField {...params} label="Enter a location" variant="outlined" />
                                      )}
                                      renderOption={(option) => <div>{option}</div>}
                                      getOptionSelected={(option, value) => option === value}
                                      onChange={(event, value) => {
                                        if (value) {
                                          const selectedPrediction = predictions.find(
                                            (prediction) => prediction.description === value
                                          );
                                          if (selectedPrediction) {
                                            handlePlaceSelect(selectedPrediction?.placeId);
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                  <RHFTextField
                                    name="address2"
                                    label=""
                                    placeholder="Address 2 (optional)"
                                    sx={{ mb: 2, mt: 2 }}
                                    // className="text-field-width"
                                  />
                                  {values?.city !== '' ? (
                                    <RHFTextField
                                      name="city"
                                      label=""
                                      placeholder="city"
                                      sx={{ mb: 2 }}
                                      // className="text-field-width"
                                    />
                                  ) : null}
                                  {values?.state !== '' ? (
                                    <RHFTextField
                                      name="state"
                                      label=""
                                      placeholder="state"
                                      sx={{ mb: 2 }}
                                      // className="text-field-width"
                                    />
                                  ) : null}
                                  {values?.zipcode !== '' ? (
                                    <RHFTextField
                                      name="zipcode"
                                      label=""
                                      placeholder="postal code"
                                      sx={{ mb: 2 }}
                                      // className="text-field-width"
                                    />
                                  ) : null}
                                  {values?.country !== '' ? (
                                    <RHFTextField
                                      name="country"
                                      label=""
                                      placeholder="country"
                                      sx={{ mb: 2 }}
                                      // className="text-field-width"
                                    />
                                  ) : null}
                                </>
                              )}
                              {/* location end  */}

                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Company size{' '}
                              </Typography>
                              <br />
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 3 }}
                                className="heading12_edit"
                              >
                                {customerDetail?.companysize !== null
                                  ? customerDetail?.companysize
                                  : 'not yet provided'}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Company Sector{' '}
                              </Typography>
                              <br />
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 3 }}
                                className="heading12_edit"
                              >
                                {customerDetail?.companySector ? customerDetail?.companySector : 'not yet provided'}
                              </Typography>
                              <Stack direction={{ xs: 'row', sm: 'row' }}>
                                <div style={{ marginRight: '20px', width: '50%' }}>
                                  <Typography
                                    variant="overline"
                                    sx={{ textTransform: 'initial' }}
                                    className="heading12_edit"
                                  >
                                    Licences <br /> Purchased{' '}
                                  </Typography>
                                  <br />
                                  <Typography
                                    variant="body1"
                                    sx={{ color: 'text.secondary', mb: 3.5, pt: 2 }}
                                    className="heading12_edit"
                                  >
                                    {editLisenceValue ? editLisenceValue : customerDetail?.totalLicence}
                                    {View !== true && customerDetail?.totalLicence !== null ? (
                                      <Button
                                        onClick={() => setOpen(true)}
                                        sx={{ ml: 2 }}
                                        size="small"
                                        variant="contained"
                                        className="newflowbtn"
                                      >
                                        Edit
                                      </Button>
                                    ) : null}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    variant="overline"
                                    sx={{ textTransform: 'initial' }}
                                    className="heading12_edit"
                                  >
                                    Licences <br /> Remaining{' '}
                                  </Typography>
                                  <br />
                                  <Typography
                                    variant="body1"
                                    sx={{ color: 'text.secondary', mb: 3.5, pt: 2 }}
                                    className="heading12_edit"
                                  >
                                    {customerDetail?.remainingLicence !== ''
                                      ? customerDetail?.remainingLicence
                                      : 'not yet provided'}
                                  </Typography>
                                </div>
                              </Stack>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                TripShift Owner
                              </Typography>
                              <br />
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 3 }}
                                className="heading12_edit"
                              >
                                {customerDetail?.tripshiftOwner ? customerDetail?.tripshiftOwner : 'not yet provided'}
                              </Typography>
                              {editnote === false && View !== true ? (
                                <fieldset
                                  style={{
                                    border: '1px solid #00AB55',
                                    width: '80%',
                                    height: '9.375rem',
                                    borderRadius: '0.625rem',
                                    padding: '0 15px',
                                    position: 'relative',
                                    overflowY: 'auto',
                                  }}
                                >
                                  <legend style={{ width: '100%', padding: '0' }}>
                                    <div style={{ display: 'inline-block', lineHeight: '1.2' }}>
                                      <Typography
                                        variant="overline"
                                        style={{ float: 'left', padding: '0 5px', textTransform: 'initial' }}
                                      >
                                        Internal notes on Customer
                                      </Typography>
                                      <div
                                        style={{
                                          float: 'left',
                                          height: '1px',
                                          backgroundColor: '#00AB55',
                                          marginTop: '11px',
                                        }}
                                        className="multi-legend-div"
                                      />
                                      <div style={{ float: ' left', padding: '0px 5px' }}>
                                        <Iconify
                                          onClick={handleEdit}
                                          icon={'material-symbols:edit-outline-rounded'}
                                          sx={{ cursor: 'pointer', position: 'absolute' }}
                                          style={{ right: '10px' }}
                                        />
                                      </div>
                                    </div>
                                  </legend>
                                  {customerDetail?.note}
                                </fieldset>
                              ) : (
                                <RHFTextField
                                  onChange={handleNoteChnage}
                                  name="notes"
                                  multiline
                                  rows={5}
                                  label="Internal notes on Customer"
                                />
                              )}
                              {editnote === true ? (
                                <Stack
                                  direction={{ xs: 'column', sm: 'row' }}
                                  alignItems={{ xs: 'normal', sm: 'flex-end' }}
                                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                                  sx={{ mt: 3 }}
                                  spacing={2}
                                  className="save-chnage-new"
                                >
                                  {uploadurl === false ? (
                                    <LoadingButton
                                      onClick={UpdateCustomerDetail}
                                      size="large"
                                      loading={loading}
                                      variant="contained"
                                    >
                                      Save Changes
                                    </LoadingButton>
                                  ) : (
                                    <LoadingButton size="large" disabled variant="contained">
                                      Save Changes
                                    </LoadingButton>
                                  )}

                                  <LoadingButton
                                    size="large"
                                    variant="contained"
                                    onClick={handleCancle}
                                    className={
                                      mode?.themeMode === 'dark'
                                        ? 'cancle_btn-team cancle_btn_shadow'
                                        : ' cancle_btn-team '
                                    }
                                  >
                                    Cancel
                                  </LoadingButton>
                                </Stack>
                              ) : null}
                            </Box>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Card>
                  </div>
                </Grid>
              </Grid>
              {/* </fieldset>
               */}
            </div>
          </FormProvider>
        </LoadingOverlay>
      </Container>
      <EditLicenseDailog
        open={open}
        Value={customerDetail?.totalLicence}
        editLisenceValue={editLisenceValue}
        setEditLisenceValue={setEditLisenceValue}
        setOpen={setOpen}
        editnoteValue={editnoteValue}
        note={customerDetail?.note}
        getCustomerDetail={getCustomerDetail}
        id={id}
      />
    </Page>
  );
}
