/* eslint-disable arrow-body-style */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable no-debugger */
/* eslint-disable no-else-return */
/* eslint-disable radix */
/* eslint-disable object-shorthand */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, TextField, Typography } from '@mui/material';
// import TimeField from 'react-simple-timefield';
import InputMask from 'react-input-mask';

import { useState } from 'react';
import CustomHoursDialog from './customHours';
// eslint-disable-next-line import/newline-after-import
export default function WorkingTimeDialog({
  setWorkingTime,
  TimeOpne,
  setTimeOpne,
  updateDays,
  setUpdateDays,
  setHourOpen,
  newdays,
  setNewDays,
}) {
  // const [open, setOpen] = React.useState(false);
  const setting = localStorage.getItem('settings');

  const [errorStart, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const [startTime, setStartTime] = React.useState(updateDays && updateDays[0]?.startTime);
  const [endTime, setEndTime] = React.useState(updateDays && updateDays[0]?.endTime);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const timeRegex = /^([0]?[1-9]|1[0-2]):([0-5]\d) ([AP][M])?$/;
  const mode = JSON.parse(setting);
  const regex = /(am|pm)\s*(?=\1)/gi; // Create a regular expression that matches duplicate "am" or "pm" strings

  const handleClose = () => {
    setTimeOpne(false);
    setHourOpen(false);
    setNewDays([]);
    setEndError(false);
    setError1(false);
  };

  const handleStartTimeChange = (event, period) => {
    const value = event?.target?.value;
    const updatedArray = updateDays?.map((obj) => {
      return {
        ...obj,
        startTime: `${value} ${period}`.replace(regex, ''),
      };
    });
    setUpdateDays(updatedArray);
    setStartTime(value);
    if (
      event?.target?.value === '0' ||
      event?.target?.value === '00' ||
      event?.target?.value === '00:' ||
      event?.target?.value === '00:0' ||
      event?.target?.value === '00:00' ||
      event?.target?.value === ''
    ) {
      setStartError(true);
      setError2(true);
    } else {
      const [hours, minutes, meridiem] = `${value} ${period}`.replace(regex, '').split(/[ :]/);

      if ((meridiem === 'PM' && hours < 12) || (meridiem === 'AM' && hours === '12') || hours > 12 || minutes > 59) {
        event.target.setCustomValidity('Invalid time value');
        setStartError(true);
        setError2(true);
      } else {
        event.target.setCustomValidity('');
        setStartError(false);
        setError2(false);
      }
    }
  };

  const handleEndTimeChange = (event, period) => {
    const value = event?.target?.value;
    const startValue = updateDays[0].startTime;

    const updatedArray = updateDays?.map((obj) => {
      return {
        ...obj,
        endTime: `${value} ${period}`.replace(regex, ''),
      };
    });
    setUpdateDays(updatedArray);
    setEndTime(value);
    if (
      event?.target?.value === '0' ||
      event?.target?.value === '00' ||
      event?.target?.value === '00:' ||
      event?.target?.value === '00:0' ||
      event?.target?.value === '00:00' ||
      event?.target?.value === ''
    ) {
      setEndError(true);
      setError1(true);
    } else {
      const [hours, minutes, meridiem] = `${value} ${period}`.replace(regex, '').split(/[ :]/);
      const [Starthours, Startminutes, Startmeridiem] = startValue?.split(/[ :]/);
      if (
        (meridiem === 'PM' && hours < 12) ||
        (meridiem === 'AM' && hours === '12') ||
        hours > 12 ||
        minutes > 59 ||
        (meridiem === Startmeridiem && Starthours > hours) ||
        (meridiem === Startmeridiem && Starthours === hours)
      ) {
        event.target.setCustomValidity('Invalid time value');
        setEndError(true);
        setError1(true);
      } else {
        event.target.setCustomValidity('');
        setEndError(false);
        setError1(false);
      }
    }
  };
  const handleAmPmClick = (type, period) => {
    const updatedArray = updateDays?.map((obj) => {
      let startTime = obj.startTime;
      let endTime = obj.endTime;
      if (type === 'startTime') {
        const Hour1 = startTime.replace(/ am| pm/, '');
        startTime = `${Hour1} ${period}`.replace(regex, '');
      } else {
        const Hour2 = endTime.replace(/ am| pm/, '');
        endTime = `${Hour2} ${period}`.replace(regex, '');
      }

      return {
        ...obj,
        startTime,
        endTime,
      };
    });

    setUpdateDays(updatedArray);

    const startTimeValue = updatedArray[0].startTime;
    const endTimeValue = updatedArray[0].endTime;
    const startHours = parseInt(startTimeValue.split(':')[0]);
    const endHours = parseInt(endTimeValue.split(':')[0]);

    const startAMPM = startTimeValue.split(' ')[1];
    const endAMPM = endTimeValue.split(' ')[1];
    if ((startAMPM === endAMPM && startHours > endHours) || (startAMPM === endAMPM && startHours === endHours)) {
      setEndError(true);
      setError2(true);
    } else {
      setEndError(false);
      setError2(false);
    }
  };

  const handleSaveTime = () => {
    if (updateDays?.length === 5) {
      const regex = /\s*(am|pm)\s*/i; // Regular expression to match AM/PM indicator and whitespace
      const updatedArray = updateDays?.map((obj) => {
        return {
          ...obj,
          startTime: `${obj?.startTime}`,
          endTime: `${obj?.endTime}`,
        };
      });
      setNewDays(updatedArray);
      setTimeOpne(false);
    } else {
      const update = updateDays.filter((user) => user.selected === true);
      const regex = /\s*(am|pm)\s*/i; // Regular expression to match AM/PM indicator and whitespace

      const updatedArray = update?.map((obj) => {
        return {
          ...obj,
          startTime: `${obj?.startTime}`,
          endTime: `${obj?.endTime}`,
        };
      });

      setNewDays(updatedArray);

      setTimeOpne(false);
    }
  };

  React.useEffect(() => {
    if (TimeOpne) {
      setHourOpen(false);
    }
  }, [TimeOpne]);

  React.useEffect(() => {
    if (newdays?.length === 5) {
      setStartTime(newdays[0]?.startTime);
      setEndTime(newdays[0]?.endTime);
    }
  }, [newdays]);

  React.useEffect(() => {
    if (updateDays?.length === 5) {
      setStartTime(updateDays[0]?.startTime);
      setEndTime(updateDays[0]?.endTime);
    }
  }, [updateDays]);

  return (
    <div>
      <Dialog
        open={TimeOpne}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="working-hour-dialogbox"
      >
        <DialogTitle id="alert-dialog-title">Please select your working hours:</DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
            <div>
              <Typography variant="body1">Start time</Typography>
              <div className="time-inner-box">
                <InputMask
                  maskChar={null}
                  mask="99:99"
                  pattern={timeRegex}
                  value={startTime}
                  onChange={(e) => handleStartTimeChange(e, startTime?.includes('am') ? 'am' : 'pm')}
                  maskPlaceholder="HH:MM"
                >
                  {(inputProps) => <TextField {...inputProps} fullWidth placeholder="HH:MM" />}
                </InputMask>
                <button
                  onClick={() => handleAmPmClick('startTime', startTime?.includes('am') ? 'pm' : 'am')}
                  className={startTime?.includes('am') ? 'ambtn activetime' : 'pmbtn disabletime'}
                >
                  AM
                </button>
                <button
                  onClick={() => handleAmPmClick('startTime', startTime?.includes('am') ? 'pm' : 'am')}
                  className={startTime?.includes('am') ? ' pmbtn disabletime' : 'ambtn activetime'}
                >
                  PM
                </button>
              </div>
              {errorStart ? (
                <p className="error-message license-error" style={{ marginLeft: '14px' }}>
                  {'Please Enter Valid Time '}
                </p>
              ) : null}
            </div>

            <div>
              <Typography variant="body1">End time</Typography>
              <div className="time-inner-box">
                <InputMask
                  maskChar={null}
                  mask="99:99"
                  pattern={timeRegex}
                  value={endTime}
                  onChange={(e) => handleEndTimeChange(e, endTime?.includes('am') ? 'am' : 'pm')}
                >
                  {(inputProps) => <TextField {...inputProps} fullWidth placeholder="HH:MM" />}
                </InputMask>
                <button
                  onClick={() => handleAmPmClick('endTime', endTime?.includes('am') ? 'pm' : 'am')}
                  className={endTime?.includes('am') ? 'ambtn activetime' : 'pmbtn disabletime'}
                >
                  AM
                </button>
                <button
                  onClick={() => handleAmPmClick('endTime', endTime?.includes('am') ? 'pm' : 'am')}
                  className={endTime?.includes('am') ? ' pmbtn disabletime' : 'ambtn activetime'}
                >
                  PM
                </button>
              </div>
              {endError ? (
                <p className="error-message license-error" style={{ marginLeft: '14px' }}>
                  {'Please Enter Valid Time '}
                </p>
              ) : null}
            </div>
          </Stack>
          <div style={{ textAlign: 'end' }}>
            <CustomHoursDialog
              updateDays={updateDays}
              setUpdateDays={setUpdateDays}
              setTimeOpne={setTimeOpne}
              setNewDays={setNewDays}
              newdays={newdays}
            />
          </div>
        </DialogContent>
        <Stack
          direction={{ xs: 'row', sm: 'row' }}
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{ mt: 3, px: 2, pb: 2 }}
          spacing={2}
          // className="form-step-btnend"
        >
          <Button
            size="medium"
            onClick={handleClose}
            variant="contained"
            className={mode?.themeMode === 'dark' ? 'cancle_btn-team cancle_btn_shadow' : ' cancle_btn-team '}
          >
            Cancel
          </Button>
          {error1 === false && error2 === false ? (
            <Button
              type="button"
              onClick={handleSaveTime}
              size="medium"
              variant="contained"
              className="next-select-btn"
              sx={{ textTransform: 'inherit' }}
            >
              Save changes
            </Button>
          ) : (
            <Button disabled size="medium" variant="contained" sx={{ textTransform: 'inherit' }}>
              Save changes
            </Button>
          )}
        </Stack>
      </Dialog>
    </div>
  );
}
