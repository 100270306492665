/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
// form
import { Controller, useForm, useFormContext } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import PasswordValidation from 'src/pages/auth/PasswordValidation';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, endAdornment, ...other }) {
  const { control } = useFormContext();
  const {
    formState: { error },
  } = useForm();
  // console.log('errors', error);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              {...field}
              fullWidth
              endA
              value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
              error={!!error&&!!error?.message}
              helperText={error?.message}
              {...other}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment,
              }}
            />
            <PasswordValidation values={field.value} name={field.name} />
          </>
        )}
      />
    </>
  );
}
