/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-debugger */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */

import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Container,
  Typography,
  Stack,
  Grid,
  Button,
  TableContainer,
  Table,
  TableBody,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import AxiosAll from 'src/service/AxiosAll';
import Image from 'src/components/Image';
import { imagesAll } from 'src/assets/Images_index';
import { LoadingButton } from '@mui/lab';
import 'react-daterange-picker/dist/css/react-calendar.css';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import DatePickerDialog from 'src/sections/mui/dialog/DatePicker';
import ReportToolbar from 'src/sections/@dashboard/invoice/list/ReportToolbar';
import Scrollbar from 'src/components/Scrollbar';
import LoadingOverlay from 'react-loading-overlay';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import ReportTableRow from 'src/sections/@dashboard/invoice/list/ReportTableRow';
import useTable, { getComparator } from 'src/hooks/useTable';
import useTabs from 'src/hooks/useTabs';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'createdAt', label: 'Date', align: 'right' },
  { id: 'fromDate', label: 'Selected Range', align: 'left' },
  { id: 'templateName', label: 'Template Type', align: 'left' },
  { id: 'status', label: 'Status', align: 'center', width: 140 },
  { id: '', label: 'Download', align: 'center', width: 140 },
];

export default function RequestReport() {
  const theme = useTheme();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    setRowsPerPage,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
  } = useTable({ defaultOrderBy: 'createdAt' });
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  const [temploading, setTempLoading] = useState(false);

  const [customLoading, setCustomLoading] = useState(false);
  const [reaload, setReaload] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedValues, setSelectedValues] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [stateError, setStateError] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [templateList, setTemplateList] = useState();
  const [filterService, setFilterService] = useState('all');
  const [tableData, setTableData] = useState([]);
  const [orderValue, setOrder] = useState('desc');
  const [orderByValue, setOrderBy] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [reload, setReload] = useState(false);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [count, setCount] = useState('');
  const [DeleteUserIds, setDeleteIds] = useState([]);
  const [toDate, setToDate] = useState(null);
  const { themeStretch } = useSettings();
  const [emailCheck, setEmailCheck] = useState(true);
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(userDetails?.data?.data?.signupDate),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [dateFilter, setFilterDate] = useState([]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [btnshow, setBtnShow] = useState(false);
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');

  // Handle filtering by name
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };
  // Handle filtering by service
  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };

  // Apply sorting and filtering to table data
  const dataFiltered = applySortFilter(
    {
      tableData,
      comparator: getComparator(order, orderBy),
      filterService,
      filterStatus,
      filterStartDate,
      filterEndDate,
    },
    [tableData]
  );

  // Handle loading more data
  const handleLoadMore = () => {
    setPage(page === 0 ? 2 : page + 1);
  };

  // Check if data is not found
  const isNotFound = !dataFiltered?.length;

  // Effect to reset 'toDate' when 'fromDate' changes
  useEffect(() => {
    if (fromDate) {
      setToDate(null);
    } else {
      setToDate(null);
    }
  }, [fromDate]);

  // Function to get template
  const handleGetETemplate = async () => {
    if (userRole?.data === 1 || userRole?.data === 5) {
      const res = await AxiosAll('get', `/api/Report/get-template`, {}, token);
      if (res?.status === 200) {
        setTemplateList(res?.data?.data);
      }
      setTempLoading(false);
    }
  };

  const handleEmailcheck = (event) => {
    const { value, checked } = event.target;
    setEmailCheck(checked);
  };
  // handle checkbox in change
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setStateError(false);
      // Check if the value is not already in the selectedValues array
      if (!selectedValues.includes(value)) {
        setSelectedValues([...selectedValues, value]);
      }
    } else {
      // Remove the value from the selectedValues array
      setStateError(true);
      setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  // Function to handle report generation
  const handleReport = async () => {
    setStateError(false);
    if (selectedValues?.length) {
      setTempLoading(true);
      // Create an empty array to hold the request data
      const requestData = [];
      // Iterate through selectedValues and create objects to add to requestData
      selectedValues.forEach((value) => {
        const data = {
          id: 0,
          fromDate: moment(selectedDate[0]?.startDate)?.format('YYYY-MM-DD'),
          toDate: moment(selectedDate[0]?.endDate)?.format('YYYY-MM-DD'),
          companyId: userDetails?.data?.data?.companyId,
          status: 1,
          fileName: '',
          templateId: value, // Use the selected value as templateId
          isSendEmail: emailCheck,
          createdBy: userDetails?.data?.data?.id,
          createdAt: moment(selectedDate[0]?.startDate)?.format('YYYY-MM-DD'),
        };

        requestData.push(data);
      });
      const res = await AxiosAll('post', `/api/Report/create-request`, requestData, token);
      if (res?.status === 200) {
        // navigate('/success-report', { state: { generate: true } });
        window.scrollTo({
          top: window.scrollY + 800,
          behavior: 'smooth',
        });
        setTableData([]);
        getReportList();
        setTempLoading(false);
      }
    } else {
      setStateError(true);
      setErrorMessage('Please Select Template Type');
    }
    // setOrder('asc');
    setTempLoading(false);
  };

  // Function to handle custom report generation
  const handleCustomReport = async () => {
    setCustomLoading(true);
    // Create an empty array to hold the request data
    // Iterate through selectedValues and create objects to add to requestData
    const data = {
      companyId: userDetails?.data?.data?.companyId,
      emailBody: 'string',
      requestDate: '2023-11-01T13:10:40.998Z',
    };

    const res = await AxiosAll('post', `/api/Report/send-custom-report`, data, token);
    if (res?.status === 200) {
      navigate('/success-report');
      setCustomLoading(false);
    }

    setCustomLoading(false);
  };

  useEffect(() => {
    if (orderBy === 'fromDate' || orderBy === 'createdAt') {
      const sortedData = [...tableData]; // Create a copy of the original data to avoid mutating the original array

      sortedData.sort((p1, p2) => {
        const value1 = new Date(p1[orderBy]);
        const value2 = new Date(p2[orderBy]);

        // Compare dates for sorting
        if (value1 < value2) {
          return order === 'asc' ? -1 : 1;
        } else if (value1 > value2) {
          return order === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });

      // Update the state with the sorted data
      setTableData(sortedData);
    } else {
      const sortedData = [...tableData]; // Create a copy of the original data to avoid mutating the original array
      sortedData.sort((p1, p2) => {
        const value1 = p1[orderBy];
        const value2 = p2[orderBy];

        // Check if the values are numbers
        if (!isNaN(value1) && !isNaN(value2)) {
          // If both values are numbers, compare them directly
          return order === 'asc' ? value1 - value2 : value2 - value1;
          // eslint-disable-next-line no-else-return
        } else {
          // If values are not numbers, convert them to lowercase and compare as strings
          const lowerCaseValue1 = String(value1).toLowerCase();
          const lowerCaseValue2 = String(value2).toLowerCase();

          if (lowerCaseValue1 < lowerCaseValue2) {
            return order === 'asc' ? -1 : 1;
            // eslint-disable-next-line no-else-return
          } else if (lowerCaseValue1 > lowerCaseValue2) {
            return order === 'asc' ? 1 : -1;
          } else {
            return 0;
          }
        }
      });

      // Update the state with the sorted data
      setTableData(sortedData);
    }
  }, [order, orderBy]);

  // Effect to control button visibility based on selected date range
  useEffect(() => {
    if (selectedDate[0]?.startDate && selectedDate[0]?.endDate) {
      setBtnShow(true);
    } else {
      setBtnShow(false);
    }
  }, [selectedDate[0]?.startDate, selectedDate[0]?.endDate]);

  // Function to toggle date picker visibility
  const handleOpenDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  useEffect(() => {
    getReportList();
  }, [page]);

  useEffect(() => {
    handleGetETemplate();
  }, []);

  useEffect(() => {
    if (reaload === true) {
      getReportList();
    }
  }, [reaload]);

  const getReportList = async () => {
    /* The above code is not complete and does not provide enough information to determine what it is
    doing. It appears to be a function or method call named "setLoading" but without the
    implementation or context, it is not possible to determine its purpose. */
    if (userRole?.data === 1 || userRole?.data === 5) {
      setLoading(true);
      const res = await AxiosAll(
        'get',
        `/api/Report/get-requestreportlist?Companyid=${
          userDetails?.data?.data?.companyId
        }&query=${filterName}&sortBy=${orderByValue}&currentPage=${page === 0 ? 1 : page}&fromDate=${moment(
          userDetails?.data?.data?.signupDate
        ).format('YYYY-MM-DD')}&toDate=${moment(new Date()).format('YYYY-MM-DD')}&pageSize=${
          rowsPerPage === 5 ? 7 : rowsPerPage
        }&sortOrder=${orderValue === 'asc' ? 'ASC' : 'DESC'}`,
        {},
        token
      );
      if (res?.status === 200) {
        if (res?.data?.data?.result?.length !== 0) {
          if (tableData?.length !== 0 && filterName === '' && page !== 0) {
            // Merge the two arrays
            const mergedArray = tableData?.concat(res?.data?.data?.result);
            // Remove duplicates based on a unique key
            const resultArray = mergedArray?.reduce((accumulator, currentObject) => {
              // Check if the current object already exists in the accumulator array based on a unique key (in this case, 'id')
              const existingObject = accumulator.find((object) => object?.id === currentObject?.id);
              // If the current object doesn't exist in the accumulator array, add it to the accumulator
              if (!existingObject) {
                accumulator.push(currentObject);
              }
              return accumulator;
            }, []);

            setTableData(resultArray);
            setLoading(false);
            setReload(false);
            setReaload(false);
          } else {
            setReload(false);
            if (res?.data?.data?.result && res?.data?.data?.result.length > 0) {
              setTableData(res?.data?.data?.result);
            } else {
              setTableData([]);
            }
            setCount(res?.data?.data?.total);
            setLoading(false);
            setReaload(false);
          }
        } else {
          setLoading(false);
          setReaload(false);

          setTableData([]);
        }
      } else {
        setLoading(false);
        setReaload(false);

        setTableData([]);
      }
    }
  };
  // console.log('selectedDate', selectedDate);
  return (
    <Page title="Request Report" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ mt: 5 }}>
        <HeaderBreadcrumbs
          heading="Request Report"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Report', href: '/report' },
            { name: 'Request Report' },
          ]}
        />

        {userRole?.data === 1 || userRole?.data === 5 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10.8}>
              <Card
                sx={{
                  p: 5,
                  boxShadow: 'none',
                  border: '1px solid #DFE3E8',
                  borderRadius: '0.625rem',
                  mb: 3,
                  overflow: 'inherit',
                }}
              >
                <Typography variant="h6" sx={{ mb: 4, color: 'text.secondary' }}>
                  {' '}
                  Request a tailored report
                </Typography>
                <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                  Please check the automatic Report Templates available below. If you require something different, we
                  are happy to help! Submit your request and your TripShift Customer Success Manager will be in touch to
                  understand what you need.
                </Typography>
                <Stack
                  spacing={2}
                  className="stack-center"
                  justifyContent="flex-end"
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="center"
                  sx={{ mb: 0 }}
                >
                  {btnshow ? (
                    <LoadingButton
                      type="button"
                      onClick={handleCustomReport}
                      size="large"
                      variant="contained"
                      className="enable_btn_report"
                      loading={customLoading}
                    >
                      Submit Request
                    </LoadingButton>
                  ) : (
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      className="disable_btn_report"
                      loading={customLoading}
                    >
                      Submit Request
                    </Button>
                  )}
                </Stack>
              </Card>
              <Card
                sx={{
                  p: 5,
                  boxShadow: 'none',
                  border: '1px solid #DFE3E8',
                  borderRadius: '0.625rem',
                  mb: 10,
                  overflow: 'inherit',
                }}
              >
                <Typography variant="h6" sx={{ mb: 4, color: 'text.secondary' }}>
                  {' '}
                  Generate an Automatic Report
                </Typography>

                {/* </div> */}

                <Typography variant="body1" sx={{ mb: 5, color: 'text.secondary' }}>
                  {' '}
                  Select your date range, and the type of report you want (you can select multiple types) to generate a
                  standard report. It will automatically appear in your Report List below.
                </Typography>

                <Typography variant="h6" sx={{ mb: 2, color: 'text.secondary' }}>
                  {' '}
                  Select date range{' '}
                </Typography>

                <>
                  <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }}>
                    <Stack spacing={2} className="report-date-picker" direction={{ sm: 'row' }}>
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {' '}
                        From:{' '}
                      </Typography>
                      <input
                        name="startDate"
                        placeholder="DD/MM/YYYY"
                        className="range-date-picker"
                        value={
                          selectedDate[0]?.startDate ? moment(selectedDate[0]?.startDate)?.format('DD/MM/YYYY') : null
                        }
                        onClick={handleOpenDatePicker}
                        readOnly
                      />
                    </Stack>

                    <Stack spacing={2} className="report-date-picker datepicker-second" direction={{ sm: 'row' }}>
                      {' '}
                      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        {' '}
                        To:{' '}
                      </Typography>
                      <input
                        name="endDate"
                        placeholder="DD/MM/YYYY"
                        value={selectedDate[0]?.endDate ? moment(selectedDate[0]?.endDate)?.format('DD/MM/YYYY') : null}
                        onClick={handleOpenDatePicker}
                        className="range-date-picker"
                        readOnly
                      />
                    </Stack>
                  </Stack>

                  {openDatePicker && (
                    <DatePickerDialog
                      startDatePlaceholder="DD/MM/YYYY"
                      endDatePlaceholder="DD/MM/YYYY"
                      dateDisplayFormat="dd/m/yyyy"
                      showDateDisplay={false}
                      openDatePicker={openDatePicker}
                      ranges={selectedDate}
                      setOpenDatePicker={setOpenDatePicker}
                      setSelectedDate={setSelectedDate}
                    />
                  )}
                </>

                <FormControl component="fieldset" className="required-email">
                  <Typography variant="h6" sx={{ mb: 3, mt: 6, color: 'text.secondary' }}>
                    Select report type
                  </Typography>
                  <FormGroup aria-label="position" row className="enagagement_new_sec">
                    {templateList?.map((temp) => {
                      return (
                        <FormControlLabel
                          key={temp?.id}
                          value={temp?.id}
                          control={<Checkbox onChange={handleCheckboxChange} />}
                          label={temp?.templateName}
                          labelPlacement="start"
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
                {errorMessage && stateError && (
                  <p className="error-message profile_error_message required-email-error">{errorMessage}</p>
                )}

                {/* <Typography variant="h6" sx={{ mb: 3, mt: 6, color: 'text.secondary' }}>
                  Email notification
                </Typography>

                <FormControlLabel
                  // eslint-disable-next-line react/jsx-boolean-value
                  value={emailCheck}
                  control={<Checkbox onChange={handleEmailcheck} />}
                  labelPlacement="start"
                  label="Enable email notification"
                  className="required-notification"
                /> */}

                <Stack
                  direction={{ xs: 'row', sm: 'row' }}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  sx={{ mt: 8 }}
                  spacing={2}
                >
                  {btnshow ? (
                    <LoadingButton
                      type="button"
                      onClick={handleReport}
                      size="large"
                      variant="contained"
                      className="enable_btn_report"
                      loading={temploading}
                    >
                      Generate{' '}
                    </LoadingButton>
                  ) : (
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      className="disable_btn_report"
                      loading={temploading}
                    >
                      Generate
                    </Button>
                  )}
                </Stack>
              </Card>
            </Grid>
            <Container maxWidth={themeStretch ? false : 'lg'}>
              <HeaderBreadcrumbs heading="Report List" links={[]} />

              <ReportToolbar noDebounce setFilterDateSelected={setFilterDate} dateFilterSelected={dateFilter} />
              <Card className="member-card-section-new">
                <Scrollbar>
                  <TableContainer
                    sx={{ minWidth: 800, position: 'relative' }}
                    className="member-new-table report-table-new"
                  >
                    <LoadingOverlay
                      // eslint-disable-next-line react/jsx-boolean-value
                      active={loading}
                      spinner
                    >
                      <Table size={dense ? 'small' : 'medium'} className="memberinner-table">
                        <TableHeadCustom
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={tableData?.length}
                          numSelected={selected?.length}
                          setReload={setReload}
                          setOrder={setOrder}
                          onSort={onSort}
                          team
                          star={true}
                          user={userDetails}
                          setOrderBy={setOrderBy}
                          // onSelectAllRows={(checked) =>
                          //   onSelectAllRows(
                          //     checked,
                          //     tableData?.map((row) => row?.teamId)
                          //   )
                          // }
                        />

                        <>
                          <TableBody>
                            {tableData?.map((row, index) => (
                              <ReportTableRow
                                key={index}
                                row={row}
                                Index={index}
                                setReaload={setReaload}
                                user={userDetails}
                                selected={selected.includes(row?.id)}
                                onSelectRow={() => onSelectRow(row?.id)}
                                setReload={setReload}
                                setDeleteIds={setDeleteIds}
                                DeleteUserIds={DeleteUserIds}
                              />
                            ))}
                            {isNotFound && loading === false ? <TableNoData isNotFound={isNotFound} /> : null}
                          </TableBody>
                        </>
                      </Table>
                      {count === tableData?.length || tableData?.length === 0 || loading === true ? null : (
                        <Box sx={{ mt: 3.5, mb: 5 }} textAlign={{ xs: 'left', sm: 'left', md: 'center' }}>
                          <Button
                            type="submit"
                            onClick={handleLoadMore}
                            size="medium"
                            variant="contained"
                            sx={{ textTransform: 'inherit' }}
                          >
                            Load more
                          </Button>
                        </Box>
                      )}
                    </LoadingOverlay>
                  </TableContainer>
                </Scrollbar>
              </Card>
            </Container>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <Card sx={{ py: 3, px: 5, boxShadow: 'none', border: '1px solid #DFE3E8', borderRadius: '0.625rem' }}>
                <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }} className="request_new_head">
                  {' '}
                  Request a Report{' '}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{ mb: 0, color: 'text.secondary', textAlign: 'center' }}
                  className="request_new_head"
                >
                  If you need something more specific than what is reported on your{' '}
                  <Link
                    variant="body1"
                    className="heading_14 greenclo textstyl"
                    sx={{ color: 'primary.main' }}
                    to="/dashboard"
                  >
                    {' '}
                    Dashboard
                  </Link>
                  , you can ask an Admin to request a specific report for you. Please visit the{' '}
                  <Link
                    variant="body1"
                    className="heading_14 greenclo textstyl"
                    sx={{ color: 'primary.main' }}
                    to="/members"
                  >
                    {' '}
                    Member Management{' '}
                  </Link>{' '}
                  area of the platform to find an Admin who can help you get the report you are looking for.{' '}
                </Typography>
                <div className="error404">
                  <Image src={imagesAll.seo} className="error404_img2" style={{ marginBottom: '10px' }} />
                </div>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}) {
  // const stabilizedThis = tableData?.map((el, index) => [el, index]);

  // stabilizedThis?.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });

  // tableData = stabilizedThis?.map((el) => el[0]);

  // if (filterName) {
  //   tableData = tableData?.filter(
  //     (item) =>
  //       item.invoiceNumber?.toLowerCase().indexOf(filterName?.toLowerCase()) !== -1 ||
  //       item.invoiceTo.name?.toLowerCase().indexOf(filterName?.toLowerCase()) !== -1
  //   );
  // }

  return tableData;
}
