/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Alert, Checkbox, Divider, MenuItem, Snackbar, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
// components
import Tooltip from '@mui/material/Tooltip';

// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import MemberDeleteDialog from 'src/sections/mui/dialog/MemberDeleteDialog';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
// eslint-disable-next-line import/no-unresolved
import AlertDialog from 'src/sections/mui/dialog/deleteAlert';
// eslint-disable-next-line import/no-unresolved
import NudgeDialoge from 'src/sections/mui/dialog/NudgeDialoge';
// eslint-disable-next-line import/no-unresolved
import DeleteSuccess from 'src/sections/mui/dialog/DeleteSuccess';
// eslint-disable-next-line import/no-unresolved
import RemoveLeadConfirm from 'src/sections/mui/dialog/removeTeam/removeLeadConfirm';
import { useNavigate } from 'react-router';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';
// eslint-disable-next-line import/no-named-as-default
import WarningDialog from '../../../mui/dialog/warning';

// ----------------------------------------------------------------------

InvoiceTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function InvoiceTableRow({
  row,
  user,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  setReload,
  setIds,
  selectedUserIds,
  setRoleKey,
  DeleteUserIds,
  setDeleteIds,
}) {
  const theme = useTheme();

  const {
    appStatus,
    userId,
    teamName,
    userEmail,
    role,
    teamId,
    roleId,
    status,
    name,
    isTeamLeadAvailable,
    activityLevel,
  } = row;
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [openMenu, setOpenMenuActions] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [makeTeamOpen, setTeamOpen] = useState(false);
  const [makeAdminOpen, setAdminOpen] = useState(false);
  const [RemoveAdminOpen, setRemoveAdminOpen] = useState(false);
  const [RemoveTeamOpen, setRemoveTeamOpen] = useState(false);
  const [RemoveTeamLeadOpen, setRemoveTeamLeadOpen] = useState(false);
  const [openNudge, setOpenNudge] = useState(false);
  const [opensuceessModal, setOpenSuccessModal] = useState(false);
  const [openConfirmationRemoveLead, setOpenConfirmationRemoveLead] = useState(false);
  const [deleteFromRow, setDeleteFromRow] = useState(false);
  const [successRemove, setSuccessRemove] = useState(false);
  const navigate = useNavigate();
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };
  // eslint-disable-next-line no-debugger
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleNudge = async (row) => {
    // eslint-disable-next-line no-debugger
    if (row?.userId) {
      const dataObject = {
        teamId: [],
        userId: [row?.userId],
      };
      const res = await AxiosAll('post', '/api/user/nudge', dataObject, token);
      if (res?.status === 200) {
        setOpenNudge(true);
      } else {
        // setOpen('2');
      }
    }
  };

  const Name = name === ' ' ? userEmail : name;
  // eslint-disable-next-line no-debugger
  return (
    <>
      {' '}
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          {userId === userDetails?.data?.data?.id ? (
            <Checkbox checked={selected} disabled />
          ) : (
            <Checkbox checked={selected} onClick={onSelectRow} />
          )}
        </TableCell>
        <TableCell align="left" className="pending_tab_row">
          <Label
            variant={theme.palette.mode === 'light' ? 'outlined' : 'outlined'}
            color={(status === 'Pending' && 'warning') || (status === 'Registered' && 'success') || 'default'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>
        {Name.length >= 18 ? (
          <TableCell
            align="left"
            onClick={() => {
              status === 'Registered' && navigate('/profile', { state: { memberId: userId } });
            }}
          >
            {/* <Stack> */}
            <Tooltip
              disableFocusListener
              disableInteractive
              disableTouchListener
              title={Name}
              arrow
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              }}
            >
              <Typography variant="subtitle2" noWrap>
                {Name}
              </Typography>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell
            align="left"
            className={status === 'Registered' ? 'pointertest' : ''}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              status === 'Registered' &&
                navigate('/profile', {
                  state: {
                    memberId: userId,
                    iconshow:
                      userRole?.data === 1 || (userRole?.data === 5 && roleId !== 1)
                        ? true
                        : userDetails?.data?.data?.assignTeamIdTeamLead === teamId && roleId !== 1,
                  },
                });
            }}
          >
            {/* <Stack> */}

            <Typography variant="subtitle2" noWrap>
              {Name}{' '}
            </Typography>
          </TableCell>
        )}
        <TableCell
          align="left"
          className={teamName !== 'unassigned' ? 'pointertest' : ''}
          onClick={() => {
            teamName !== 'unassigned' &&
              navigate('/team-detail', {
                state: {
                  Id: teamId,
                  editIcon:
                    userRole?.data === 1 || userRole?.data === 5
                      ? true
                      : userDetails?.data?.data?.assignTeamIdTeamLead === teamId,
                },
              });
          }}
        >
          {teamName}
        </TableCell>
        <TableCell align="left">{role}</TableCell>
        <Tooltip
          disableFocusListener
          disableInteractive
          disableTouchListener
          title={appStatus === 'No App' ? 'no app' : 'app'}
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                marginLeft: '-30px',
              },
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -30],
                },
              },
            ],
          }}
        >
          <TableCell align="left" className="noapp_tab_section" style={{ paddingLeft: '3.5vw' }}>
            {appStatus === 'No App' ? (
              <Label
                variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                color={'error'}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '50%',
                  height: '14px',
                  minWidth: '14px',
                  padding: '0',
                }}
              >
                {''}
              </Label>
            ) : (
              <Label
                variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                color={'success'}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '50%',
                  height: '14px',
                  minWidth: '14px',
                  padding: '0',
                }}
              >
                {''}
              </Label>
            )}
          </TableCell>
        </Tooltip>
        <Tooltip
          disableFocusListener
          disableInteractive
          disableTouchListener
          title={`${
            appStatus === 'App' && status === 'Registered'
              ? activityLevel === 1
                ? 'high'
                : activityLevel === 2
                ? 'medium'
                : activityLevel === 3
                ? 'low'
                : 'no'
              : 'no'
          } activity`}
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                marginLeft: '-30px',
              },
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -30],
                },
              },
            ],
          }}
        >
          <TableCell align="left" className="noapp_tab_section" style={{ paddingLeft: '3.5vw' }}>
            {appStatus === 'App' && status === 'Registered' ? (
              <Label
                variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                color={
                  (activityLevel === 1 && 'success') ||
                  (activityLevel === 2 && 'warning') ||
                  (activityLevel === 3 && 'error') ||
                  'default'
                }
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '50%',
                  height: '14px',
                  minWidth: '14px',
                  padding: '0',
                }}
              >
                {''}
              </Label>
            ) : (
              // 'N/A'
              <Label
                variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                color={'default'}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '50%',
                  height: '14px',
                  minWidth: '14px',
                  padding: '0',
                }}
              >
                {''}
              </Label>
            )}
          </TableCell>
        </Tooltip>
        <TableCell align="right" className="test-user-siderbar lastdot-section">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
            }}
            actions={
              <>
                {(userRole?.data === 2 && row?.roleId === 2) ||
                (userRole?.data === 2 && row?.roleId === 1) ||
                (userRole?.data === 2 && row?.roleId === 5) ||
                (userRole?.data === 1 && userId == userDetails?.data?.userId) ||
                (userRole?.data === 5 && userId == userDetails?.data?.userId) ||
                (userRole?.data === 1 && row.isPrimaryAdmin === true) ||
                (userRole?.data === 5 && row.isPrimaryAdmin === true) ? (
                  <MenuItem disabled>Send Nudge</MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      handleNudge(row);
                      handleCloseMenu();
                    }}
                  >
                    Send Nudge
                  </MenuItem>
                )}

                {userRole?.data === 1 || userRole?.data === 5 ? (
                  <>
                    {row?.roleId === 1 || row?.roleId === 5 ? (
                      status === 'Registered' &&
                      'success' &&
                      row.isPrimaryAdmin === false &&
                      // eslint-disable-next-line eqeqeq
                      userId != userDetails?.data?.userId ? (
                        <MenuItem
                          onClick={() => {
                            setRemoveAdminOpen(true);
                            handleCloseMenu();
                          }}
                        >
                          Remove Admin{' '}
                        </MenuItem>
                      ) : (
                        <MenuItem disabled>Remove Admin</MenuItem>
                      )
                    ) : (row?.roleId === 1 ||
                        row?.roleId === 5 ||
                        row?.roleId === 2 ||
                        row?.roleId === 3 ||
                        (userRole?.data === 1 && row.isPrimaryAdmin === false)) &&
                      status === 'Registered' &&
                      'success' ? (
                      <MenuItem
                        onClick={() => {
                          setAdminOpen(true);
                          handleCloseMenu();
                        }}
                      >
                        {' '}
                        Make Admin
                      </MenuItem>
                    ) : (
                      <MenuItem disabled>Make Admin</MenuItem>
                    )}
                  </>
                ) : null}

                {userRole?.data === 1 || userRole?.data === 5 ? (
                  <>
                    {row?.roleId === 2 || row?.roleId === 5 ? (
                      status === 'Registered' && 'success' ? (
                        <MenuItem
                          onClick={() => {
                            setRemoveTeamLeadOpen(true);

                            handleCloseMenu();
                          }}
                        >
                          Remove Team Lead
                        </MenuItem>
                      ) : (
                        <MenuItem disabled>Remove Team Lead</MenuItem>
                      )
                    ) : row?.isDefaultTeam === true &&
                      (row?.roleId === 1 || row?.roleId === 5 || row?.roleId === 2 || row?.roleId === 3) &&
                      status === 'Registered' &&
                      'success' ? (
                      <MenuItem
                        onClick={() => {
                          setTeamOpen(true);
                          handleCloseMenu();
                        }}
                      >
                        Make Team Lead
                      </MenuItem>
                    ) : (
                      <MenuItem disabled>Make Team Lead</MenuItem>
                    )}
                  </>
                ) : null}
                {status === 'Pending' ? (
                  <MenuItem disabled className="view_member">
                    View Profile
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      navigate('/profile', {
                        state: {
                          memberId: userId,
                          iconshow:
                            userRole?.data === 1 || (userRole?.data === 5 && roleId !== 1)
                              ? true
                              : userDetails?.data?.data?.assignTeamIdTeamLead === teamId && roleId !== 1,
                        },
                      });
                      handleCloseMenu();
                    }}
                    className="view_member"
                  >
                    View Profile
                  </MenuItem>
                )}
                <Divider />
                {(userRole?.data === 2 && row?.roleId === 2) ||
                (userRole?.data === 2 && row?.roleId === 1) ||
                (userRole?.data === 2 && row?.roleId === 5) ? (
                  <MenuItem className="delete_member" sx={{ color: 'error.main' }} disabled>
                    Remove From Team
                  </MenuItem>
                ) : userRole?.data === 2 ? (
                  <MenuItem
                    className="delete_member"
                    sx={{ color: 'error.main' }}
                    onClick={() => {
                      setRemoveTeamLeadOpen(true);
                      handleCloseMenu();
                      setDeleteFromRow(true);
                    }}
                  >
                    Remove From Team
                  </MenuItem>
                ) : null}
                {userRole?.data === 1 || userRole?.data === 5 ? (
                  <>
                    {row?.roleId === 2 ? (
                      <MenuItem
                        className="delete_member"
                        sx={{ color: 'error.main' }}
                        onClick={() => {
                          setRemoveTeamLeadOpen(true);
                          handleCloseMenu();
                          setDeleteFromRow(true);
                        }}
                      >
                        Delete Member
                      </MenuItem>
                    ) : (userRole?.data === 1 && userId == userDetails?.data?.userId) ||
                      (userRole?.data === 5 && userId == userDetails?.data?.userId) ||
                      (userRole?.data === 1 && row.isPrimaryAdmin === true) ||
                      (userRole?.data === 5 && row.isPrimaryAdmin === true) ? (
                      <MenuItem className="delete_member" sx={{ color: 'error.main' }} disabled>
                        Delete Member
                      </MenuItem>
                    ) : (
                      <MenuItem
                        className="delete_member"
                        sx={{ color: 'error.main' }}
                        onClick={() => {
                          setOpenDeleteModal(true);
                          handleCloseMenu();
                        }}
                      >
                        Delete Member
                      </MenuItem>
                    )}
                  </>
                ) : null}
              </>
            }
          />
        </TableCell>
        {makeTeamOpen && (
          <AlertDialog
            userId={row?.userId}
            userName={row?.name}
            userEmail={row?.userEmail}
            roleId={row?.roleId}
            setReload={setReload}
            setAlert={setTeamOpen}
            alertOpne={makeTeamOpen}
            title="Make Team Lead"
            setIds={setIds}
            selectedUserIds={selectedUserIds}
            setRoleKey={setRoleKey}
          />
        )}
        {RemoveAdminOpen && (
          <AlertDialog
            userId={row?.userId}
            userName={row?.name}
            userEmail={row?.userEmail}
            roleId={row?.roleId}
            setReload={setReload}
            setAlert={setRemoveAdminOpen}
            alertOpne={RemoveAdminOpen}
            title="Remove Admin"
            setIds={setIds}
            selectedUserIds={selectedUserIds}
            setRoleKey={setRoleKey}
          />
        )}
        {makeAdminOpen && (
          <AlertDialog
            userId={row?.userId}
            userName={row?.name}
            userEmail={row?.userEmail}
            roleId={row?.roleId}
            setReload={setReload}
            setAlert={setAdminOpen}
            alertOpne={makeAdminOpen}
            title="Make Admin"
            setIds={setIds}
            selectedUserIds={selectedUserIds}
            setRoleKey={setRoleKey}
          />
        )}
        {RemoveTeamLeadOpen && (
          <WarningDialog
            userId={row?.userId}
            userName={row?.name}
            userEmail={row?.userEmail}
            roleId={row?.roleId}
            teamName={row?.teamName}
            setReload={setReload}
            // eslint-disable-next-line jsx-a11y/aria-role
            role={userRole?.data}
            RemoveTeamLeadOpen={RemoveTeamLeadOpen}
            setAlert={setRemoveTeamOpen}
            setRemoveTeamLeadOpen={setRemoveTeamLeadOpen}
            alertOpne={RemoveTeamOpen}
            setOpenConfirmationRemoveLead={setOpenConfirmationRemoveLead}
            selectedUserIds={selectedUserIds}
            setRoleKey={setRoleKey}
            DeleteUserIds={DeleteUserIds}
            setDeleteIds={setDeleteIds}
          />
        )}
        {openNudge && <NudgeDialoge open={openNudge} row={row} setOpen={setOpenNudge} setReload={setReload} />}
        {openDeleteModal && (
          <MemberDeleteDialog
            userId={row?.userId}
            userName={row?.name}
            userEmail={row?.userEmail}
            roleId={row?.roleId}
            setReload={setReload}
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            setOpenSuccessModal={setOpenSuccessModal}
            DeleteUserIds={DeleteUserIds}
            setDeleteIds={setDeleteIds}
          />
        )}
        {opensuceessModal && (
          <DeleteSuccess
            row={row}
            setOpenSuccessModal={setOpenSuccessModal}
            opensuceessModal={opensuceessModal}
            successRemove={successRemove}
            setReload={setReload}
          />
        )}
        {openConfirmationRemoveLead && (
          <RemoveLeadConfirm
            userId={row?.userId}
            userName={row?.name}
            userEmail={row?.userEmail}
            roleId={row?.roleId}
            setOpenSuccessModal={setOpenSuccessModal}
            setReload={setReload}
            DeleteUserIds={DeleteUserIds}
            deleteFromRow={deleteFromRow}
            openConfirmationRemoveLead={openConfirmationRemoveLead}
            setOpenConfirmationRemoveLead={setOpenConfirmationRemoveLead}
            setSuccessRemove={setSuccessRemove}
            setIds={setIds}
            selectedUserIds={selectedUserIds}
            setRoleKey={setRoleKey}
          />
        )}
      </TableRow>
    </>
  );
}
