export const urls = {
  trumpet: 'https://tripshift.trumpet.app/pods/edit/65cf7d004ba152f7ecbabc06',
  pdfAdmin: `${process.env.REACT_APP_SERVER}/StaticFiles/helpdeskPDF/TripShift_Successful_onboarding%201.pdf`,
  pdfTeamLead: `${process.env.REACT_APP_SERVER}/StaticFiles/helpdeskPDF/TripShift_Successful_use%201.pdf`,
  pdfMember: `${process.env.REACT_APP_SERVER}/StaticFiles/helpdeskPDF/TripShift_Internal_Buy_In%201.pdf`,
};
export const FAQs = [
  {
    id: 1,
    question: 'I have just joined the TripShift platform – why is my dashboard empty?',
    answer:
      "<p>Upon joining TripShift, your dashboard will initially appear empty. To populate it, start by adding team members under the 'Members' tab. Once your organisation's members begin logging their commuting and business travel activities on the TripShift app, this data will begin to appear on your dashboard.</p>",
  },
  {
    id: 2,
    question: 'What should I look for in a TripShift team lead?',
    answer:
      '<p>Anyone can be a team lead, but Ideal candidates for are those with a keen interest in sustainability and are well-recognised within your organisation. It is a bonus if your team leads regularly utilise sustainable transportation methods (e.g., walking, cycling, public transport) as their insights can significantly influence organisational change. You can nominate team leads by using the menus in the members tab.</p>',
  },
  {
    id: 3,
    question: 'Is it possible to customise teams within TripShift?',
    answer:
      '<p>You can set up and manage teams according to your organisational structure, departments, sectors, location or emissions reduction working groups.</p>',
  },
  {
    id: 4,
    question: 'My dashboard was showing data, but now it’s all 0’s?',
    answer:
      "<p>Should your dashboard unexpectedly show zeros across the board, refresh the page as a first step. If the issue persists, logging out and then back in may resolve it. For ongoing issues, reach out to our support team at <a href='mailto:support@tripshift.co.uk'>support@tripshift.co.uk</a>.</p>",
  },
  {
    id: 5,
    question: 'My TripShift platform is completely blank – what should I do?',
    answer:
      "<p>A simple refresh may fix the issue, press F5 on your keyboard or click the refresh button on your browser. If this doesn’t work, try logging out and back into the platform. Still having problems? please contact <a href='mailto:support@tripshift.co.uk'>support@tripshift.co.uk</a>.</p>",
  },
  {
    id: 6,
    question: 'How often is the TripShift platform updated?',
    answer:
      '<p>The platform updates your emissions data every two hours, ensuring you have access to the most current information. The last update time is visible at the top right of the dashboard.</p>',
  },
  {
    id: 7,
    question: "My data is showing – but it doesn't seem quite right?",
    answer:
      "<p>To see your organisations' travel emissions in more detail, you can generate a report from one of our templates, or request a custom report via the report tab. If you have any further queries with your numbers or the way they are calculated, contact your TripShift admin <a href='mailto:sebastien@tripshift.co.uk'>sebastien@tripshift.co.uk</a>.</p>",
  },
  {
    id: 8,
    question: 'Can I view individual performance on sustainability?',
    answer:
      '<p>Of course! Navigate to the members tab to see engagement and activity levels across your organisation. The ‘App status’ icon indicates whether the individual has downloaded the TripShift app, and the ‘Activity level’ indicates how many trips they are recording on the app.</p>',
  },
  {
    id: 9,
    question: 'Can I view team performance on sustainability?',
    answer:
      '<p>Team performance can be viewed in the teams tab. Here you can see engagement status and an indication of the carbon levels across your teams.</p>',
  },
  {
    id: 10,
    question: 'How does TripShift ensure data privacy and security?',
    answer:
      "<p>TripShift is designed with privacy and security at its core, ensuring all data, including GPS and activity data, is handled in compliance with relevant regulations. You can read our data privacy statement <a href='https://tripshift.eco/privacy-policy' target='_blank' rel='noopener noreferrer'>here</a>.</p>",
  },
  {
    id: 11,
    question: 'Haven’t found what you’re looking for?',
    answer: "<p>Please contact us at <a href='mailto:support@tripshift.co.uk'>support@tripshift.co.uk</a>.</p>",
  },
];
