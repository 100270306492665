/* eslint-disable no-restricted-globals */
/* eslint-disable no-debugger */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */
import sumBy from 'lodash/sumBy';
import { useEffect, useState } from 'react';
// @mui
import { Box, Button, Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// routes
// eslint-disable-next-line import/no-duplicates
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/service/AxiosAll';
import useSettings from 'src/hooks/useSettings';
import useTable, { getComparator } from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData } from '../../components/table';
import { TeamTableRow, TeamTableToolbar } from '../../sections/@dashboard/invoice/list';

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD = [
  { id: 'engagement', label: 'Engagement', align: 'left' },
  { id: 'teamName', label: 'Team Name', align: 'left' },
  { id: 'teamLead', label: 'Team Lead', align: 'left' },
  { id: 'membersCount', label: 'Members', align: 'center', width: 140 },
  { id: 'address', label: 'Main location', align: 'center', width: 140 },
  { id: 'carbonLevels', label: 'Carbon levels', align: 'left' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function TeamList() {
  // Import necessary dependencies and custom hooks
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const navigate = useNavigate();

  // Initialize state variables
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    setRowsPerPage,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
  } = useTable({ defaultOrderBy: 'status' });

  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterService, setFilterService] = useState('all');
  const [orderValue, setOrder] = useState('desc');
  const [orderByValue, setOrderBy] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [reload, setReload] = useState(false);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [count, setCount] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateData, setUpdateData] = useState();
  const [DeleteUserIds, setDeleteIds] = useState([]);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState();
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');

  // Handle filtering by name
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };
  // Handle filtering by service
  const handleFilterService = (event) => {
    setFilterService(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText || '');
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  // Apply sorting and filtering to table data
  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterService,
    filterStatus,
    search,
    filterStartDate,
    filterEndDate,
  });

  // Check if data is not found
  const isNotFound = !dataFiltered?.length;

  const denseHeight = dense ? 56 : 76;

  // Calculate the length, total price, and percent by status
  const getLengthByStatus = (status) => tableData?.filter((item) => item.status === status).length;
  const getTotalPriceByStatus = (status) =>
    sumBy(
      tableData?.filter((item) => item.status === status),
      'totalPrice'
    );

  const getPercentByStatus = (status) => (getLengthByStatus(status) / tableData?.length) * 100;

  const TABS = [
    { value: 'all', label: 'All', color: 'info', count: tableData?.length },
    { value: 'paid', label: 'Paid', color: 'success', count: getLengthByStatus('paid') },
    { value: 'unpaid', label: 'Unpaid', color: 'warning', count: getLengthByStatus('unpaid') },
    { value: 'overdue', label: 'Overdue', color: 'error', count: getLengthByStatus('overdue') },
    { value: 'draft', label: 'Draft', color: 'default', count: getLengthByStatus('draft') },
  ];

  // Fetch team list data from the server
  const getTeamList = async () => {
    setLoading(true);
    const res = await AxiosAll(
      'post',
      `/api/team/team-list?companyid=${userDetails?.data?.data?.companyId}&query=${
        search === ' ' ? '' : search || ''
      }&sortBy=${orderByValue}&RoleId=${userRole?.data}&pageNo=${page === 0 ? 1 : page}&perPageLimit=${
        rowsPerPage === 5 ? 7 : rowsPerPage
      }&sortOrder=${orderValue === 'asc' ? 'ASC' : 'DESC'}`,
      {},
      token
    );

    if (res?.status === 200) {
      if (tableData?.length !== 0 && search === '') {
        // Merge the two arrays
        const mergedArray = tableData.concat(res?.data?.result);
        // Remove duplicates based on a unique key
        const resultArray = mergedArray.reduce((accumulator, currentObject) => {
          // Check if the current object already exists in the accumulator array based on a unique key (in this case, 'id')
          const existingObject = accumulator.find((object) => object?.teamId === currentObject?.teamId);
          // If the current object doesn't exist in the accumulator array, add it to the accumulator
          if (!existingObject) {
            accumulator.push(currentObject);
          }
          return accumulator;
        }, []);

        setTableData(resultArray.sort((a, b) => a.teamId - b.teamId));
        setReload(false);
        localStorage.setItem('teamId', res?.data?.result[0]?.teamId ? res?.data?.result[0]?.teamId : '');
        setLoading(false);
      } else {
        setReload(false);
        setTableData([]);
        setTableData(res?.data?.result?.length ? res?.data?.result : []);
        setCount(res?.data?.total);
        localStorage.setItem('teamId', res?.data?.result[0]?.teamId ? res?.data?.result[0]?.teamId : '');
        setLoading(false);
      }
      setLoading(false);
    } else {
      setLoading(false);
      setTableData([]);
    }
  };

  // Handle removing selected users
  const handleRemoveSelectedUsers = () => {
    const filteredUsers = tableData.filter(
      (user) => !DeleteUserIds.find((selectedUser) => selectedUser === user.teamId)
    );
    if (filteredUsers?.length) {
      setUpdateData(filteredUsers);
    } else {
      setTableData([]);
    }

    setDeleteIds([]);
    setReload(false);
  };

  // Use effect to handle removing selected users when DeleteUserIds changes
  useEffect(() => {
    if (DeleteUserIds?.length) {
      handleRemoveSelectedUsers();
    }
  }, [reload]);

  // Use effect to update table data when updateData changes
  useEffect(() => {
    if (updateData?.length) {
      setTableData([]);
      setTableData(updateData);
      setReload(false);
    }
  }, [updateData]);

  // Handle loading more data
  const handleLoadMore = () => {
    setPage(page === 0 ? 2 : page + 1);
  };

  useEffect(() => {
    if (searchText === '') {
      setTableData([]);
    }
  }, [search, searchText]);

  // custome table sorting
  useEffect(() => {
    const sortedData = [...tableData]; // Create a copy of the original data to avoid mutating the original array

    sortedData.sort((p1, p2) => {
      const value1 = p1[orderBy];
      const value2 = p2[orderBy];

      // Check if the values are numbers
      if (!isNaN(value1) && !isNaN(value2)) {
        // If both values are numbers, compare them directly
        return order === 'asc' ? value1 - value2 : value2 - value1;
        // eslint-disable-next-line no-else-return
      } else {
        // If values are not numbers, convert them to lowercase and compare as strings
        const lowerCaseValue1 = String(value1).toLowerCase();
        const lowerCaseValue2 = String(value2).toLowerCase();

        if (lowerCaseValue1 < lowerCaseValue2) {
          return order === 'asc' ? -1 : 1;
          // eslint-disable-next-line no-else-return
        } else if (lowerCaseValue1 > lowerCaseValue2) {
          return order === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    });

    // Update the state with the sorted data
    setTableData(sortedData);
  }, [order, orderBy]);

  // Use effects to fetch team list data and handle selected items
  useEffect(() => {
    if (selected?.length) {
      const rowData = dataFiltered?.filter((person) => selected?.includes(person?.teamId));
      setSelectedIds(rowData);
    }
  }, [selected]);

  useEffect(() => {
    if (page) {
      getTeamList();
    }
  }, [page]);

  useEffect(() => {
    if (search) {
      getTeamList();
    }
  }, [search]);

  return (
    <Page title="Manage Members" className="manage-member membertitlepage">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Manage Teams"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Manage Teams', href: '/teams' },
            { name: 'Teams' },
          ]}
        />

        <TeamTableToolbar
          filterName={searchText}
          filterService={filterService}
          filterStartDate={filterStartDate}
          filterEndDate={filterEndDate}
          selected={selected.length}
          teamLength={dataFiltered?.length}
          noDebounce
          tableData={selectedIds}
          setSelected={setSelected}
          selectedRow={selected}
          setReload={setReload}
          user={userDetails}
          setDeleteIds={setDeleteIds}
          setFilterName={setSearchText}
          onFilterName={setSearchText}
          onFilterService={handleFilterService}
          onFilterStartDate={(newValue) => {
            setFilterStartDate(newValue);
          }}
          onFilterEndDate={(newValue) => {
            setFilterEndDate(newValue);
          }}
          optionsService={SERVICE_OPTIONS}
        />
        <Card className="member-card-section-new">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }} className="member-new-table">
              <LoadingOverlay
                // eslint-disable-next-line react/jsx-boolean-value
                active={loading}
                spinner
              >
                <Table size={dense ? 'small' : 'medium'} className="memberinner-table">
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={selected?.length}
                    setReload={setReload}
                    setOrder={setOrder}
                    onSort={onSort}
                    team
                    user={userDetails}
                    setOrderBy={setOrderBy}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData?.map((row) => row?.teamId)
                      )
                    }
                  />

                  <>
                    <TableBody>
                      {dataFiltered?.map((row, index) => (
                        <TeamTableRow
                          key={index}
                          row={row}
                          user={userDetails}
                          selected={selected.includes(row.teamId)}
                          onSelectRow={() => onSelectRow(row.teamId)}
                          setReload={setReload}
                          setDeleteIds={setDeleteIds}
                          DeleteUserIds={DeleteUserIds}
                        />
                      ))}
                      {isNotFound && loading === false ? <TableNoData isNotFound={isNotFound} /> : null}{' '}
                    </TableBody>{' '}
                  </>
                </Table>
                {count === tableData?.length || tableData?.length === 0 || loading === true ? null : (
                  <Box sx={{ mt: 3.5, mb: 5 }} className="btnleft_right">
                    <Button
                      type="submit"
                      onClick={handleLoadMore}
                      size="medium"
                      variant="contained"
                      sx={{ textTransform: 'inherit' }}
                    >
                      Load more
                    </Button>
                  </Box>
                )}
              </LoadingOverlay>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData?.map((el, index) => [el, index]);

  // stabilizedThis?.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1].toLowerCase() - b[1].toLowerCase();
  // });

  // tableData = stabilizedThis?.map((el) => el[0]);

  // if (filterName) {
  //   tableData = tableData?.filter(
  //     (item) =>
  //       item.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
  //       item.invoiceTo.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
  //   );
  // }

  return tableData;
}
