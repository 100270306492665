/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-debugger */
/* eslint-disable no-useless-escape */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
// @mui
// eslint-disable-next-line no-unused-vars
import {  Card, Container, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useLayoutEffect, useState } from 'react';
// eslint-disable-next-line camelcase
import { useSelector } from 'react-redux';
import AppWidgetSummaryNew from 'src/sections/@dashboard/general/app/AppWidgetSummaryNew';
import { BankingExpensesCategories } from 'src/sections/@dashboard/general/banking';
import WelcomeTripshiftDialogs from 'src/sections/mui/dialog/welcomeToTripshift';
import AxiosAll from 'src/service/AxiosAll';
import moment from 'moment/moment';
import useSettings from '../../hooks/useSettings';
// _mock_
// import { _appFeatured } from '../../_mock';
// components
import Page from '../../components/Page';
// sections
import { AnalyticsWebsiteVisits, AnalyticsWidgetSummary } from '../../sections/@dashboard/general/analytics';
import {
  AppCurrentDownload,
  // AppFeatured,
  AppTopAuthors,
  // AppWelcome,
  AppWidgetSummary,
} from '../../sections/@dashboard/general/app';
// assets
// eslint-disable-next-line import/order
import Image from 'src/components/Image';
// eslint-disable-next-line import/order
import { imagesAll } from 'src/assets/Images_index';
// eslint-disable-next-line import/order
import { EcommerceWidgetSummary } from 'src/sections/@dashboard/general/e-commerce';
import DashboardSkeleton from 'src/components/skeleton/DashboardSkeleton';
// import AnalyticsCurrentVisits from 'src/sections/@dashboard/general/analytics/AnalyticsCurrentVisits';
import { useNavigate } from 'react-router';
import ChartDataModel from 'src/sections/@dashboard/general/BarChart';
import BarChart from 'src/sections/@dashboard/general/BarChart/BarChart';

// ----------------------------------------------------------------------

export default function GeneralApp() {
  // State variables
  const [open, setOpen] = useState(false);
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const { userDetails, userRole, profileName, token } = useSelector((state) => state.loginReducer);
  const [engagementOverview, setEngagementOverview] = useState();
  const [carbonInsightsOrganisation, setcarbonInsightsOrganisation] = useState();
  const [carbonInsight, setgetCarbonInsight] = useState();
  const [loading, setLoading] = useState(false);
  const getTeamId = localStorage.getItem('teamId');

  const navigate = useNavigate();
  // Effect hook to handle changes in userDetails
  useEffect(() => {
    if (
      userDetails?.data?.data?.profileCompleted &&
      userDetails?.data?.data?.noOfEmployees !== '' &&
      userDetails?.data?.data?.businessSector !== ''
    ) {
      // eslint-disable-next-line no-debugger
      setOpen(false);
    } else {
      // eslint-disable-next-line no-debugger
      setOpen(true);
    }
  }, [userDetails]);

  // Function to fetch engagement overview data
  const getEngagementOverview = async () => {
    const date = new Date();
    const dateAsString = date.toString();
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
    const teamId =
      getTeamId === null || getTeamId === 'undefined' ? userDetails?.data?.data?.assignTeamIdTeamLead : getTeamId;
    // eslint-disable-next-line no-debugger
    setLoading(true);
    const res = await AxiosAll(
      'get',
      `/api/Dashboard/engagement_overview?teamId=${
        userRole?.data === 2 ? teamId : 0
      }&timezoneName=${timezone}&compnayId=${
        userRole?.data === 1 || userRole?.data === 5 ? userDetails?.data?.data?.companyId : 0
      }`,
      {},
      token
    );
    if (res?.status === 200) {
      setEngagementOverview(res?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const MemberPage = () => {
    navigate('/members');
  };

  // Function to fetch carbon insights for the organization
  const getcarbonInsightsOrganisation = async () => {
    // eslint-disable-next-line no-debugger
    const teamId =
      getTeamId === null || getTeamId === 'undefined' ? userDetails?.data?.data?.assignTeamIdTeamLead : getTeamId;

    const res = await AxiosAll(
      'get',
      `/api/Dashboard/Carbon_insights_organisation?teamId=${userRole?.data === 2 ? teamId : 0}&compnayId=${
        userRole?.data === 1 || userRole?.data === 5 ? userDetails?.data?.data?.companyId : 0
      }`,
      {},
      token
    );
    if (res?.status === 200) {
      setcarbonInsightsOrganisation(res?.data);
    } else {
      // setOpen(true)
    }
  };

  // Function to fetch carbon insights
  const getCarbonInsights = async () => {
    // eslint-disable-next-line no-debugger
    const teamId =
      getTeamId === null || getTeamId === 'undefined' ? userDetails?.data?.data?.assignTeamIdTeamLead : getTeamId;

    const res = await AxiosAll(
      'get',
      `/api/Dashboard/Carbon_insights?teamId=${userRole?.data === 2 ? teamId : 0}&compnayId=${
        userRole?.data === 1 || userRole?.data === 5 ? userDetails?.data?.data?.companyId : 0
      }`,
      {},
      token
    );
    if (res?.status === 200) {
      setgetCarbonInsight(res?.data);
    } else {
      // setOpen(true)
    }
  };
  // useLayoutEffect hook to trigger data fetching functions when 'userRole' changes.
  useLayoutEffect(() => {
    getEngagementOverview();
    getcarbonInsightsOrganisation();
    getCarbonInsights();
  }, [userRole]);
  // Determine the user's display name.
  const userName = profileName
    ? profileName
    : `${userDetails?.data?.data?.firstName} ${userDetails?.data?.data?.lastName}`;

  return (
    <Page title="General: App" className="dashboard">
      {loading ? (
        <DashboardSkeleton />
      ) : (
        <>
          {userRole?.data === 1 && userDetails?.data?.data?.isPrimary ? (
            <WelcomeTripshiftDialogs open={open} setOpen={setOpen} />
          ) : null}
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant='h6'>Welcome back {userName||""}!</Typography>
            <Typography variant='caption' sx={{color:'#637381', mb:4}}>TripShift Platform</Typography>
           </Grid>
           
              {/*
              Old Dashboard header
              <Grid item xs={12} md={8}>
                <AppWelcome
                  title={`Welcome back! \n  ${userName} `}
                  description={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.`}
                  img={
                    <Image
                      src={imagesAll.seo}
                      // SeoIllustration
                      sx={{
                        p: 3,
                        width: 360,
                        margin: { xs: 'auto', md: 'inherit' },
                      }}
                    />
                  }
                  action={<Button variant="contained">Go Now</Button>}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <AppFeatured list={_appFeatured} />
              </Grid> */}
              <Grid item xs={12}>
                <div className="only_dashboard_section">
                  <fieldset>
                    <legend className={mode?.themeMode === 'dark' ? 'light_color_heading' : ''}>
                      Engagement overview
                    </legend>
                  </fieldset>
                </div>
                <Typography variant="subtitle1" sx={{ mt: 2, mb: 2, textAlign: 'right' }}>
                  Last updated on {moment(engagementOverview?.result?.createdDate).format('DD/MM/YYYY')} at{' '}
                  {moment(engagementOverview?.result?.createdDate).format('hh:mm a ')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <AppWidgetSummaryNew
                  title={userRole?.data === 2 ? 'Registered Team Members' : 'Registered Members'}
                  total={engagementOverview?.result?.registeredMembers}
                  title1="Have downloaded the App"
                  chartColor={theme.palette.primary.main}
                  percent={engagementOverview?.result?.downloadedApp}
                  title2="Have logged trips in past 3 days"
                  percent1={engagementOverview?.result?.loggedTripsPast3Days}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <EcommerceWidgetSummary
                  title="Members Active"
                  percent={
                    engagementOverview?.result?.membersActive ? engagementOverview?.result?.membersActive?.percent : 0
                  }
                  total={
                    engagementOverview?.result?.membersActive?.total
                      ? engagementOverview?.result?.membersActive?.total
                      : 0
                  }
                  chartColor={theme.palette.primary.main}
                  chartData={
                    engagementOverview?.result?.membersActive?.active
                      ? engagementOverview?.result?.membersActive?.active
                      : 0
                  }
                  sx={{ mb: 1 }}
                />

                <AppWidgetSummary
                  title="Total trips logged this month"
                  percent={engagementOverview?.result?.tripsLoggedThisMonth?.percent}
                  total={
                    engagementOverview?.result?.tripsLoggedThisMonth
                      ? engagementOverview?.result?.tripsLoggedThisMonth?.total
                      : 0
                  }
                  chartColor={theme.palette.chart.blue[0]}
                  chartData={
                    engagementOverview?.result?.tripsLoggedThisMonth
                      ? engagementOverview?.result?.tripsLoggedThisMonth?.logged
                      : 0
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {engagementOverview?.result?.activityLevelAcrossOrganisation === null ||
                (engagementOverview?.result?.activityLevelAcrossOrganisation[0]?.value === 0 &&
                  engagementOverview?.result?.activityLevelAcrossOrganisation[1]?.value === 0 &&
                  engagementOverview?.result?.activityLevelAcrossOrganisation[2]?.value === 0 &&
                  engagementOverview?.result?.activityLevelAcrossOrganisation[3]?.value === 0) ? (
                  <>
                    <Card className="six-card">
                      <Typography variant="h6" sx={{ ml: 3, mt: 1.5 }}>
                        {` Activity level across ${userRole?.data === 2 ? 'team' : 'Organisation'}`}
                      </Typography>
                      {userRole?.data === 2 ? (
                        <>
                          <Image src={imagesAll.activity_level} sx={{ mt: 5 }} className="error404_img" />

                          <Typography
                            onClick={MemberPage}
                            variant="body1"
                            className="heading_14 greenclo textstyle"
                            sx={{ textAlign: 'end', mt: 6, mb: 2, pr: 3 }}
                          >
                            Invite team members{' '}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Image src={imagesAll.maintenance} sx={{ mt: 5 }} className="error404_img" />
                        </>
                      )}
                    </Card>
                  </>
                ) : (
                  <AppCurrentDownload
                    title={
                      userRole?.data === 2 ? 'Activity level across Team  ' : 'Activity level across organisation '
                    }
                    chartColors={[
                      theme.palette.primary.dark,
                      theme.palette.primary.lighter,
                      theme.palette.primary.main,
                      theme.palette.primary.light,
                    ]}
                    chartData={engagementOverview?.result?.activityLevelAcrossOrganisation}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {engagementOverview?.result?.activityLevelAcrossOrganisation === null ||
                (engagementOverview?.result?.activityLevelAcrossOrganisation[0]?.value === 0 &&
                  engagementOverview?.result?.activityLevelAcrossOrganisation[1]?.value === 0 &&
                  engagementOverview?.result?.activityLevelAcrossOrganisation[2]?.value === 0 &&
                  engagementOverview?.result?.activityLevelAcrossOrganisation[3]?.value === 0) ? (
                  <>
                    {userRole?.data === 1 || userRole?.data === 5 ? (
                      <Typography
                        onClick={MemberPage}
                        variant="body1"
                        className="heading_14 greenclo textstyle"
                        sx={{ textAlign: 'end', mb: 1.5, mt: 2 }}
                      >
                        Manage members
                      </Typography>
                    ) : null}
                  </>
                ) : null}
                <div className="only_dashboard_section">
                  <fieldset>
                    <legend className={mode?.themeMode === 'dark' ? 'light_color_heading' : ''}>
                      Carbon Insights - Organisation (past 30 days)
                    </legend>
                  </fieldset>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <AnalyticsWebsiteVisits
                  className="analytic_chart_option"
                  title="Average carbon (kg) per member"
                  subheader={`(${carbonInsightsOrganisation?.result?.averageCarbonPerEmployee?.percent}%) than last year`}
                  chartLabels={carbonInsightsOrganisation?.result?.averageCarbonPerEmployee?.label}
                  chartData={carbonInsightsOrganisation?.result?.averageCarbonPerEmployee?.chartData}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Grid container spacing={3} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <AnalyticsWidgetSummary
                      title="Total Carbon (Kg)"
                      subTitle=" "
                      total={carbonInsightsOrganisation?.result?.totaClarbon}
                      icon={'eva:trending-down-fill'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <AnalyticsWidgetSummary
                      title="Ave Carbon/Member (Kg)"
                      subTitle=""
                      total={carbonInsightsOrganisation?.result?.aveCarbonMember}
                      icon={'eva:trending-up-fill'}
                      color="error"
                    />
                  </Grid>
                </Grid>
                <Stack spacing={3}>
                  <BankingExpensesCategories
                    title={
                      carbonInsightsOrganisation?.result?.travelModeImpactFootprint?.length
                        ? 'Travel mode footprint (km)'
                        : ''
                    }
                    chartData={carbonInsightsOrganisation?.result?.travelModeImpactFootprint}
                    chartColors={[
                      theme.palette.primary.main,
                      theme.palette.chart.blue[0],
                      theme.palette.chart.yellow[0],
                      theme.palette.chart.violet[0],
                      theme.palette.info.darker,
                      theme.palette.chart.red[0],
                      theme.palette.chart.violet[2],

                      theme.palette.success.darker,
                      theme.palette.chart.green[0],
                    ]}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {carbonInsightsOrganisation?.result?.travelModeImpactFootprint?.length ? null : userRole?.data ===
                  2 ? null : (
                  <Typography
                    onClick={MemberPage}
                    variant="body1"
                    className="heading_14 greenclo textstyle"
                    sx={{ textAlign: 'end', mb: 1.5, mt: 1 }}
                  >
                    Manage members
                  </Typography>
                )}
                <div className="only_dashboard_section">
                  <fieldset>
                    <legend className={mode?.themeMode === 'dark' ? 'light_color_heading' : ''}>
                      Carbon Insights - Teams
                    </legend>
                  </fieldset>
                </div>
              </Grid>
              {carbonInsight?.result?.appAuthors && carbonInsight?.result?.tripTotalTeamWise?.length ? (
                <>
                  <Grid item xs={12} md={4.5} lg={4.5}>
                    <AppTopAuthors
                      className="authors_card_section"
                      title="Top Teams (member ave & top mode) "
                      list={carbonInsight?.result?.appAuthors}
                    />
                  </Grid>

                  <Grid item xs={12} md={7.5} lg={7.5}>
                    {carbonInsight?.result?.tripTotalTeamWise?.length ? (
                      // <AnalyticsCurrentVisits
                      //   title={`${userRole?.data === 2 ? 'Team' : 'Teams'} Carbon ‘Shape’ - Total & Mode`}
                      //   chartData={carbonInsight?.result?.teamsCarbonTotalMod?.chartData}
                      //   chartColors={[
                      //     theme.palette.primary.main,
                      //     theme.palette.chart.blue[0],
                      //     theme.palette.chart.violet[0],
                      //     theme.palette.chart.yellow[0],
                      //   ]}
                      // />
                      <BarChart
                        title={carbonInsight?.result?.tripTotalTeamWise?.length ? 'Number of trips by team' : ''}
                        chartData={carbonInsight?.result?.tripTotalTeamWise}
                        chartColors={[
                          theme.palette.primary.main,
                          theme.palette.chart.blue[0],
                          theme.palette.chart.yellow[0],
                          theme.palette.chart.violet[0],
                          theme.palette.info.darker,
                          theme.palette.chart.red[0],
                          theme.palette.chart.violet[2],
                          theme.palette.success.darker,
                          theme.palette.chart.green[0],
                        ]}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={4.5} lg={4.5} />
                  <Grid item xs={12} md={7.5} lg={7.5} className="statistics_card">
                    <ChartDataModel
                      title="Emissions by team (kg)"
                      chartData={[...carbonInsight?.result?.barsCarbonData?.barsData]}
                      categoriesData={carbonInsight?.result?.barsCarbonData?.label}
                      ChartColor={[
                        '#00AB55',
                        '#005249',
                        '#FF6C40',
                        '#2D99FF',
                        '#2CD9C5',
                        '#826AF9',
                        '#D0AEFF',
                        '#FFE700',
                        '#FFBD98',
                      ]}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Card sx={{ p: 2, pb: 12 }} style={{ width: '96.3%', margin: '30px 0 16px auto' }}>
                    <Grid item xs={12} className="empty_img">
                      <Typography variant="h6" sx={{ mt: 0 }}>
                        Looking empty?
                      </Typography>

                      <Image src={imagesAll.maintenance} sx={{ mt: 5 }} className="error404_img" />
                    </Grid>
                  </Card>
                  <div style={{ textAlign: 'end', width: '100%' }}>
                    {carbonInsight?.result?.appAuthors?.length &&
                    carbonInsight?.result?.teamsCarbonTotalMod?.chartData?.length ? null : (
                      <Typography
                        onClick={MemberPage}
                        variant="body1"
                        className="heading_14 greenclo textstyle"
                        sx={{ textAlign: 'end', mb: 1.5, mt: 2 }}
                      >
                        Manage members
                      </Typography>
                    )}
                  </div>
                </>
              )}
            </Grid>
          </Container>
        </>
      )}{' '}
    </Page>
  );
}
