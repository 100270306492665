/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/extensions */
/* eslint-disable import/first */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Alert, MenuItem, Snackbar, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
// components
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
// eslint-disable-next-line import/no-unresolved
import NudgeDialoge from 'src/sections/mui/dialog/NudgeDialoge';
// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';
// eslint-disable-next-line import/no-named-as-default
import DeleteCustomer from 'src/sections/mui/dialog/DeleteCustomer';
// import { Envdev } from '../../../../../src/env';
// ----------------------------------------------------------------------

CompanyTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function CompanyTableRow({
  row,
  user,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  setReload,
  getCustomerList,
  sendNudeg,
  nudge,
}) {
  const theme = useTheme();

  const { companyName, companyEmail, status, remainingLicence, totalLicence, companyId, tripshiftOwner } = row;
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [openMenu, setOpenMenuActions] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [type, setType] = useState('');
  const [openNudge, setOpenNudge] = useState(false);

  const navigate = useNavigate();
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };
  // eslint-disable-next-line no-debugger
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleNudge = async (companyId) => {
    // eslint-disable-next-line no-debugger
    sendNudeg(true);
    if (companyId) {
      const res = await AxiosAll('post', `/api/Company/nudge?companyId=${companyId}`, {}, token);
      sendNudeg(false);

      if (res?.status === 200) {
        //  setOpen(true)
        setOpenNudge(true);
        // setMessage(res?.data?.message)
        // setReload('1');
      } else {
        // setOpen('2');
      }
    }
  };

  return (
    <>
      {' '}
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">{/* <Checkbox checked={selected} onClick={onSelectRow} /> */}</TableCell>
        <TableCell align="left" className="pending_tab_row">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={(status === 'Pending' && 'warning') || (status === 'Registered' && 'success') || 'default'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>

        <TableCell>
          {/* <Stack> */}
          <Typography variant="subtitle2" noWrap>
            {companyName}
          </Typography>
        </TableCell>
        <TableCell align="left">{companyEmail}</TableCell>
        <TableCell align="left">{tripshiftOwner}</TableCell>
        <TableCell align="left">{totalLicence}</TableCell>
        <TableCell align="left">{remainingLicence}</TableCell>
        <TableCell align="right" className="test-user-siderbar">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            sx={{
              p: 0,
              mt: 1.5,
              ml: 0.75,
            }}
            actions={
              <>
                {/* {status === 'Pending' ? <MenuItem disabled>  Send Nudge</MenuItem> :  */}
                <MenuItem
                  onClick={() => {
                    navigate('/customer-detail', { state: { Id: companyId } });
                    handleCloseMenu();
                  }}
                  // className="set_admin"
                >
                  {/* <Iconify icon={'ant-design:star-filled'} /> */}
                  View / Edit Customer
                </MenuItem>
                {/* <Iconify icon={'akar-icons:star'} /> */}
                {status === 'Pending' ? (
                  <MenuItem
                    disabled={!!(companyId === 2 || companyId === 31)}
                    onClick={() => {
                      handleNudge(companyId);
                      handleCloseMenu();
                    }}
                    className="view_member"
                    sx={{ mb: 1 }}
                  >
                    {/* <Iconify icon={'eva:trash-2-outline'} /> */}
                    Send Nudge
                  </MenuItem>
                ) : null}

                <MenuItem
                  disabled={
                    !!((companyId === 1) || (companyId === 155))
                  }
                  // This company cannot be deleted since it is a parent company and contains trip data.
                  onClick={() => {
                    setDeleteModalOpen(true);
                    handleCloseMenu();
                  }}
                  className="view_member"
                  sx={{ mb: 1 }}
                >
                  {/* <Iconify icon={'eva:trash-2-outline'} /> */}
                  Delete Customer
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
      <DeleteCustomer
        open={DeleteModalOpen}
        getCustomerList={getCustomerList}
        companyId={companyId}
        setOpen={setDeleteModalOpen}
        name={companyName}
      />
      {openNudge && (
        <NudgeDialoge
          company={true}
          companyEmail={companyEmail}
          open={openNudge}
          setOpen={setOpenNudge}
          setReload={setReload}
        />
      )}
    </>
  );
}
