/* eslint-disable camelcase */
/* eslint-disable no-debugger */
/* eslint-disable import/order */
/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */

import { styled } from '@mui/material';
// routes
// hooks
// eslint-disable-next-line import/no-unresolved
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import LoadingOverlay from 'react-loading-overlay';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { useNavigate } from 'react-router';
// _mock_
// components

import { Box, Container } from '@mui/system';
import { RedirectURl } from 'src/AuthConfig';
import Page from 'src/components/Page';
import AxiosAll from 'src/service/AxiosAll';
import { getToken } from 'src/redux/actions/login';

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
export default function SetupAccount() {
  // Import necessary dependencies and hooks
  const { instance, accounts } = useMsal();
  const { userguid } = useSelector((state) => state.loginReducer);
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const userGUID = params.get('id');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  // useEffect hook to handle token verification when the component mounts
  useEffect(() => {
    // Check if 'Email' and 'Usertoken' are present in the URL parameters
    if (userGUID) {
      // Dispatch actions to get the token and email
      dispatch(getToken(userGUID));
      // Call the TokenVerify function
      TokenVerify();
    } else {
      navigate('/login');
    }
  }, []);

  // Function to verify the user's token
  const TokenVerify = async (values) => {
    setLoading(true);
    try {
      const res = await AxiosAll('get', `/api/user/validate-user?userguid=${userGUID}`, '');
      if (res?.status === 200) {
        handleSignUp();
        // navigate('/successfull-setup');
      } else {
        console.log(res?.response?.data?.message || res?.response?.data?.title);
        setLoading(false);
        navigate('/expire-token');
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // Function to handle user sign-up
  const handleSignUp = () => {
    setLoading(false);
    const config = {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: `https://${process.env.REACT_APP_B2C_LOGIN}/${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_B2C_SIGNUP_FLOW}`,
      redirectUri: `${RedirectURl}/user-account-setup`,
      scopes: [
        `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_CLIENT_ID}/${process.env.REACT_APP_SCOPE} openid`,
      ],
      response_type: 'id_token',
    };
    const queryParams = new URLSearchParams({
      client_id: config.clientId,
      scope: config.scopes.join(' '),
      redirect_uri: config.redirectUri,
      response_type: config.response_type,
    });
    // Open the sign-up URL in the current window
    window.open(`${config.authority}/oauth2/v2.0/authorize?${queryParams}`, '_self');
  };

  return (
    <Page title="set up account" className="setup-loader publicprofile">
      <LoadingOverlay
        // eslint-disable-next-line react/jsx-boolean-value
        active
        spinner
      >
        <RootStyle>
          <>
            <LogoOnlyLayout />
            <Container className="profile_section" sx={{}}>
              <ContentStyle className="container-style-profile">
                <Box sx={{ mb: 5 }} />
              </ContentStyle>
            </Container>{' '}
          </>
        </RootStyle>
      </LoadingOverlay>
    </Page>
  );
}
