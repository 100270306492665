/* eslint-disable no-dupe-else-if */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-debugger */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// import { useAuth } from 'oidc-react';

// @mui
import { Alert, Button } from '@mui/material';
// routes
// hooks
// import useAuth from '../../../hooks/useAuth';
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { SignIn } from 'src/AuthConfig';

// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/service/AxiosAll';
import FormProvider from '../../../components/hook-form/FormProvider';
import { addLogin, getAccessToken, updateImage, updateRole } from '../../../redux/actions/login';
// ----------------------------------------------------------------------

export default function LoginForm({ setLoading, loading }) {
  // Initialize Redux dispatch and navigation utilities
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email;
  const [errorMessage, setErrorMessage] = useState(null);
  const { instance, accounts } = useMsal();
  const msalInstance = new PublicClientApplication(SignIn);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().trim().email('Email must be a valid email address').required('Email is required'),
    passwordLogin: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    passwordLogin: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();
  const onSubmit = async (data) => {
    const payload = {
      email: data.email.trim(),
      password: data.passwordLogin.replace(/\s/g, ''),
    };
    try {
      const response = await AxiosAll('post', '/api/user/login', payload);
      // eslint-disable-next-line no-debugger

      if (response?.status === 200) {
        await dispatch(addLogin(response));
        await dispatch(updateRole(response?.data?.data?.roleId));
        await dispatch(updateImage(response.data.data?.photoURL));

        if (
          response?.data?.data?.roleId === 2 &&
          response?.data?.data?.assignTeamId === response?.data?.data?.defaultTeamId
        ) {
          await navigate('/create-team-stepOne', { state: { createNew: true } });
        } else if (
          response?.data?.data?.roleId === 2 &&
          response?.data?.data?.assignTeamId !== response?.data?.data?.defaultTeamId
        ) {
          await navigate('/dashboard');
        } else {
          await navigate('/dashboard');
        }
      } else {
        setErrorMessage(response?.response?.data?.message);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  // Handle login with Microsoft Authentication Library (MSAL)
  const handleLogin = () => {
    setLoading(true);
    const loginRequest = {
      scopes: [
        `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_CLIENT_ID}/${process.env.REACT_APP_SCOPE}`,
      ],
      prompt: 'login',
      nonce: 'defaultNonce',
    };
    instance.loginRedirect(loginRequest).then((response) => {
      const accessToken = response.accessToken;
    });
  };
  // Fetch access token and data after successful login
  useEffect(() => {
    if (accounts?.length) {
      getToken();
    }
  }, [accounts]);

  const getToken = async () => {
    setLoading(true);
    if (accounts.length > 0) {
      try {
        const tokenRequest = {
          scopes: [
            `https://${process.env.REACT_APP_B2C_TENANT}/${process.env.REACT_APP_CLIENT_ID}/${process.env.REACT_APP_SCOPE}`,
          ],
          account: accounts[0], // Select the first account for simplicity
        };

        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        // Access the access token from the response

        const accessToken = response?.accessToken;
        if (response?.accessToken) {
          fetchData(accessToken);
          // Use the access token as needed
        }
      } catch (error) {
        console.error('Failed to acquire access token:', error);
      }
    }
  };

  // Function to fetch data with an authentication token
  const fetchData = async (authToken) => {
    try {
      const response = await AxiosAll('post', '/api/user/loginazure', '', `Bearer ${authToken}`);
      // eslint-disable-next-line no-debugger
      if (response?.status === 200 && response?.data?.data !== null) {
        if (response?.data?.isDeleted === false && response?.data?.isProfileCompleted) {
          await dispatch(addLogin(response));
          await dispatch(updateRole(response?.data?.data?.roleId));
          await dispatch(updateImage(response?.data?.data?.photoURL));
          await dispatch(getAccessToken(response?.data?.data?.token));

          if (
            response?.data?.data?.roleId === 2 &&
            response?.data?.data?.assignTeamId === response?.data?.data?.defaultTeamId
          ) {
            await navigate('/create-team-stepOne', { state: { createNew: true } });
          } else if (
            response?.data?.data?.roleId === 2 &&
            response?.data?.data?.assignTeamId !== response?.data?.data?.defaultTeamId
          ) {
            await navigate('/dashboard');
          } else {
            await navigate('/dashboard');
          }
        } else if (response?.data?.email && response?.data?.isProfileCompleted === false) {
          navigate(`/complete-profile?id=${response?.data?.userGuid}&platform=${true}`, {
            state: {
              Email: response?.data?.encryptedEmail,
              token: response?.data?.token,
              isMember: response?.data?.isMemberRole,
            },
          }); // Memeber Exist but profile not completed
        } else {
          setErrorMessage(
            'You do not have access to this page. Please contact TripShift support for further assistance'
          );
        }
      } else {
        console.log('response2', response);
        if (
          response?.data?.isMemberRole === false &&
          response?.data?.isProfileCompleted === false &&
          response?.data?.isUnauthorized === false
        ) {
          console.log('response3');

          navigate('/successfull-setup', {
            state: { isProfileCompleted: response?.data?.isProfileCompleted },
          }); // Memeber does not exist
        } else if (
          response?.data?.email &&
          response?.data?.isMemberRole &&
          response?.data?.isProfileCompleted === false
        ) {
          navigate(`/complete-profile?id=${response?.data?.userGuid}&platform=${true}`, {
            state: {
              Email: response?.data?.encryptedEmail,
              isMember: response?.data?.isMemberRole,
              token: response?.data?.token,
            },
          }); // Memeber Exist but profile not completed
        } else if (response?.data?.isMemberRole && response?.data?.isProfileCompleted) {
          console.log('response5');

          navigate('/successfull-setup', {
            state: { isProfileCompleted: response?.data?.isProfileCompleted },
          }); // Memeber Exist And  profile  completed
        } else {
          setErrorMessage(response?.response?.data?.message || response?.response?.data?.title);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error?.response?.data?.message);
      console.error(error);
    }
  };

  // Handle error message display and timeout
  useEffect(() => {
    if (errorMessage) {
      const timer1 = setTimeout(() => setErrorMessage(null), 5 * 1000);

      return () => {
        clearTimeout(timer1);
        handleLogOutSSo();
      };
    } else {
      if (accounts.length && errorMessage === null) {
        handleLogOutSSo();
      }
    }
  }, [errorMessage]);

  // Function to handle single sign-out
  const handleLogOutSSo = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: `${`${window.location.origin}/login`}`,
      mainWindowRedirectUri: `${window.location.origin}`, // Specify the main window redirect URI
    });
  };
  // Set email value in the form
  useEffect(() => {
    setValue('email', email);
  }, [email]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Button type="button" sx={{ mt: 3 }} fullWidth size="large" variant="contained" onClick={handleLogin}>
          {loading === false ? 'Login' : ' Logging In '}
        </Button>
        <div>
          {errorMessage ? (
            <Alert severity="error" sx={{ mb: 3 }}>
              <strong>{errorMessage}</strong>
            </Alert>
          ) : null}
        </div>{' '}
      </FormProvider>
    </>
  );
}
