/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Snackbar, Stack } from '@mui/material';
// hooks
// import useAuth from '../../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/service/AxiosAll';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
// import { Link } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
// eslint-disable-next-line import/order
// eslint-disable-next-line import/order
import { deleteLogin, updateRole } from '../../../redux/actions/login';

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  // const { register } = useAuth();
  const navigate = useNavigate();
  // eslint-disable-next-line no-undef
  const isMountedRef = useIsMountedRef();
  // console.log('isMountedRef', isMountedRef);
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState();

  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Password is required'),
    password: Yup.string()
      .optional()
      .notOneOf([Yup.ref('oldPassword')], 'Must be a different password')
      .required('New Password is required')
      .matches(/[a-z]+/, ' At least one lowercase character')
      .matches(/[A-Z]+/, ' At least one uppercase character')
      .matches(/[@$!%*#?&£]+/, ' At least one special character')
      .matches(/\d+/, ' At least one number')
      .min(8, 'Password must be at least 8 characters'),
  });

  const defaultValues = {
    oldPassword: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,

    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const handleLogOut = () => {
    dispatch(deleteLogin());
    dispatch(updateRole());
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
  };

  const onSubmit = async (values) => {
    const data = {
      currentPassword: values?.oldPassword,
      newPassword: values?.password,
    };
    try {
      const res = await AxiosAll('post', '/api/user/change-password', data, token);
      if (res?.status === 200) {
        setOpen(true);
        setType('success');
        setMessage(res?.data?.message);
        logout();
      } else {
        setOpen(true);
        setType('error');
        setMessage(res?.response?.data?.title || res?.response?.data?.message);
      }
    } catch (error) {
      console.error(error);
      reset();
    }
  };

  const logout = () => {
    const timer1 = setTimeout(() => handleLogOut(), 3 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  };

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={type || 'success'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField
            name="oldPassword"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: 'new-password',
            }}
          />

          <RHFTextField
            name="password"
            label="New password "
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Confirm
          </LoadingButton>
        </Stack>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </FormProvider>
    </>
  );
}
