/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { m } from 'framer-motion';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { imagesAll } from 'src/assets/Images_index';
import Image from 'src/components/Image';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  // maxWidth:'33.33vw',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const location = useLocation();
  const message = location?.state?.message;

  return (
    <Page title="404 Page Not Found" className="error404">
      <LogoOnlyLayout />
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }} className="container-style">
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph className="main_heading">
              {message || 'Sorry, page not found!'}
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }} className="heading_16">
              Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check
              your spelling.
            </Typography>
          </m.div>

          {/* <m.div variants={varBounce().in}>
            <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div> */}
          <Image src={imagesAll.error404} className="error404_img" />

          <Button to="/dashboard" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
