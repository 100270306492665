/* eslint-disable no-else-return */
/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/service/AxiosAll';
// import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function AllocateTeamDialogs({ setReload, TeamId, open, setOpen }) {
  const [error, setError] = useState('');
  const { userDetails, userRole, token } = useSelector((state) => state.loginReducer);
  const [userList, setUserList] = useState([]);
  const [userId, setUserIds] = useState([]);

  const handleChange = (event) => {
    setError('');
    setUserIds(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setReload(true);
  };

  const getMemberList = async () => {
    const res = await AxiosAll('get', `/api/user/assign-teamlead-member-list?teamId=${TeamId}`, {}, token);
    if (res?.data?.success === true) {
      setUserList(res?.data?.data);
    } else {
      setUserList([]);
    }
  };

  const handleUpdateRole = async () => {
    if (userId) {
      const dataObject = {
        userId: userId?.userId,
        teamId: TeamId,
        roleId: 2,
      };
      const res = await AxiosAll('post', '/api/user/update-team-lead-role', [dataObject], token);
      if (res?.status === 200) {
        setOpen(false);
        setReload(true);
        // setNotifyOpen(true)
        // setType("success")
        // setMessage(res?.data?.message)
        // setReload("7")
      } else {
        // setNotifyOpen(true)
        setError(res.response?.data.message);
        // setType("error")
        // setOpen(true)
      }
    }
  };
  // checking on every change on email list
  useEffect(() => {
    getMemberList();
  }, []);
  return (
    <>
      <Dialog open={open} onClose={handleClose} className="addmemberdialogs">
        <Grid>
          <Grid item xs={2} className="cross_section textright" sx={{ mr: 4, mt: 2 }}>
            <Iconify
              icon={'akar-icons:cross'}
              className="alert_cross"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
        <DialogTitle sx={{ pl: 4, mb: 2 }}>Allocate a Team Lead</DialogTitle>
        <DialogContent className="addmembers">
          <FormControl sx={{ m: 1, width: 300 }}>
            <Select
              //   labelId="demo-simple-select-autowidth-label"
              //   id="demo-simple-select-autowidth"
              className="select_input_field"
              value={userId}
              fullWidth
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return 'Select Team Lead';
                } else {
                  return `${selected?.firstName} ${selected?.lastName}`;
                }
              }}
              displayEmpty
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={handleChange}
            >
              <MenuItem value="" disabled className="select-range-text">
                Select Team Lead
              </MenuItem>

              {userList?.map((user, index) => (
                <MenuItem key={index} value={user} className="select-range-text">
                  {user?.firstName} {user?.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {userId?.userId ? (
            <LoadingButton variant="contained" loading={false} onClick={handleUpdateRole}>
              Assign
            </LoadingButton>
          ) : (
            <LoadingButton variant="contained" disabled>
              Assign
            </LoadingButton>
          )}
        </DialogActions>

        <div>
          {error !== '' ? (
            <Alert severity="error" sx={{ mb: 3 }}>
              <strong>{error}</strong>
            </Alert>
          ) : null}
        </div>
      </Dialog>
    </>
  );
}
