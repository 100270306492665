import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Checkbox, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import Iconify from 'src/components/Iconify';

export default function PasswordValidation({ values, name }) {
  // eslint-disable-next-line no-unused-vars
  const pattern = /^(?=.*[A-Z])/;
  const pattern1 = /^(?=.*[a-z])/;

  // eslint-disable-next-line no-unused-vars
  const pattern2 = /(?=.*[-+_!@#$%^&£*., ?])/;
  // eslint-disable-next-line no-unused-vars
  const pattterNo = /^(?=.*[0-9])/;

  return (
    <>
      {name === 'password' && values?.length ? (
        <FormGroup className="password-section-all">
          <Typography variant="subtitle2" sx={{ mb: 2, textAlign: 'left' }}>
            Password must be at least 8 characters and contain at least :{' '}
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                className="password-checkbox"
                checkedIcon={
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="checkicon" />
                }
                icon={<Iconify icon={'ic:sharp-error'} width={24} height={24} className="infoicon" />}
                checked={!!pattern.test(values)}
              />
            }
            label=" one uppercase letter "
            className={pattern.test(values) ? 'checkicon-section' : 'infoicon-section'}
          />
          <FormControlLabel
            control={
              <Checkbox
                className="password-checkbox"
                checkedIcon={
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="checkicon" />
                }
                icon={<Iconify icon={'ic:sharp-error'} width={24} height={24} className="infoicon" />}
                checked={!!pattern1.test(values)}
              />
            }
            label=" one lowercase letter "
            className={pattern1.test(values) ? 'checkicon-section' : 'infoicon-section'}
          />
          <FormControlLabel
            control={
              <Checkbox
                className="password-checkbox"
                checkedIcon={
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="checkicon" />
                }
                icon={<Iconify icon={'ic:sharp-error'} width={24} height={24} className="infoicon" />}
                checked={!!pattern2.test(values)}
              />
            }
            label="one special character (e.g £, %, ?) "
            className={pattern2.test(values) ? 'checkicon-section' : 'infoicon-section'}
          />
          <FormControlLabel
            control={
              <Checkbox
                className="password-checkbox"
                checkedIcon={
                  <Iconify icon={'ant-design:check-circle-filled'} width={24} height={24} className="checkicon" />
                }
                icon={<Iconify icon={'ic:sharp-error'} width={24} height={24} className="infoicon" />}
                checked={!!pattterNo.test(values)}
              />
            }
            label="one number"
            className={pattterNo.test(values) ? 'checkicon-section' : 'infoicon-section'}
          />
        </FormGroup>
      ) : null}
    </>
  );
}
