/* eslint-disable import/no-unresolved */
/* eslint-disable no-debugger */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import { deleteLogin, getAccessToken, updateImage, updateName, updateRole } from 'src/redux/actions/login';
import DashboardLayout from '../layouts/dashboard/index';
import PublicRoutes from './public';

const Routes = () => {
  const [auth, setAuth] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { instance, accounts } = useMsal();
  const { userDetails, token } = useSelector((state) => state.loginReducer);

  const handleLogOutSSo = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/user-account-setup`,
    });
  };

  useEffect(() => {
    if (window.location.pathname === '/user-account-setup' || window.location.pathname === '/create-password') {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(deleteLogin());
      dispatch(updateRole());
      dispatch(updateImage());
      dispatch(updateName());
      dispatch(getAccessToken());
    }
  }, [window.location.pathname]);

  return (
    <>
      {token ? (
        <>
          <DashboardLayout />
        </>
      ) : (
        <PublicRoutes />
      )}
    </>
  );
};

export default Routes;
