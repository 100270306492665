/* eslint-disable no-debugger */
/* eslint-disable import/named */
/* eslint-disable import/no-unresolved */

import { Container, Box, styled } from '@mui/material';
// routes
// hooks
// eslint-disable-next-line import/no-unresolved
import { useEffect, useState } from 'react';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout';
import { useLocation, useNavigate } from 'react-router';
import { useMsal } from '@azure/msal-react';
import AxiosAll from 'src/service/AxiosAll';
import useTabs from '../hooks/useTabs';
import useSettings from '../hooks/useSettings';
import useBrowserAndDeviceType from '../service/useBrowserAndDeviceType';
// _mock_
// components

import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
// sections
import { AccountGeneral } from '../sections/@dashboard/user/account';
import GuidVarify from './GuidVarify';

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default function ComplateProfile() {
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const UserGUID = params.get('id');
  const platform = params.get('platform');
  const UserEmail = location?.state?.email;
  const userToken = location?.state?.token;
  const Member = location?.state?.isMember;
  const { browserType, deviceType } = useBrowserAndDeviceType();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { themeStretch } = useSettings();
  const { currentTab, onChangeTab } = useTabs('general');
  const [currentUSer, setCurrentUser] = useState();
  // Function to verify the GUID
  // const GUIDVerify = async (values) => {
  //   setLoading(true);
  //   try {
  //     const res = await AxiosAll('get', `/api/user/validate-user?userguid=${UserGUID}`, '', '');
  //     console.log('res', res);
  //     if (res?.status === 200) {
  //       // navigate('/successfull-setup');
  //       console.log(res);
  //       if (res?.data?.firstName || res?.data?.lastName || res?.data?.jobTitle) {
  //         setCurrentUser({
  //           firstName: res?.data?.firstName,
  //           lastName: res?.data?.lastName,
  //           jobTitle: res?.data?.jobTitle,
  //         });
  //       }
  //       setLoading(false);
  //     } else {
  //       console.log(res?.response?.data?.message || res?.response?.data?.title);
  //       setLoading(false);
  //       navigate('/expire-token');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };

  const GUIDVerify = async (values) => {
    setLoading(true);
    try {
      const res = await AxiosAll('get', `/api/profile/guid/${UserGUID}`, '', userToken);
      console.log('res', res);
      if (res?.status === 200) {
      
        setCurrentUser({
          firstName: res?.data?.firstName||"",
          lastName: res?.data?.lastName||"",
          jobTitle: res?.data?.jobTitle,
          email:res?.data?.email||""
        });
        console.log(res);
        setLoading(false);
      } else {
        // console.log(res?.response?.data?.message || res?.response?.data?.title);
        // setLoading(false);
        // navigate('/expire-token');
        EmailVerify();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const EmailVerify = async (values) => {
    setLoading(true);
    try {
      const res = await AxiosAll('get', `/api/profile/${UserEmail}`, '', userToken);
      console.log('res', res);
      if (res?.status === 200) {
        navigate(`/complete-profile?id=${res?.data}`);
        console.log(res);
        setLoading(false);
      } else {
        console.log(res?.response?.data?.message || res?.response?.data?.title);
        setLoading(false);
        navigate('/expire-token');
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (UserGUID && UserGUID) {
      GUIDVerify();
    }
  }, [UserGUID]);
  console.log('userToken', userToken);
  return (
    <>
      {!loading ? (
        <Page title="User: Account Settings" className="publicprofile">
          <LogoOnlyLayout />
          <Container className="profile_section">
            {/* maxWidth="lg" */}
            <ContentStyle className="container-style-profile">
              <>
                <HeaderBreadcrumbs
                  heading="Please complete your TripShift profile"
                  links={[{ name: 'TripShift', href: '/' }, { name: 'Member', href: '/' }, { name: 'New Members' }]}
                />
              </>
              <AccountGeneral
                GUID={UserGUID}
                setLoading={setLoading}
                useremail={UserEmail}
                UserToken={userToken}
                platForm={platform}
                userData={currentUSer}
                Member={Member}
              />
              <Box sx={{ mb: 5 }} />
            </ContentStyle>
          </Container>
        </Page>
      ) : (
        <GuidVarify />
      )}
    </>
  );
}
