/* eslint-disable import/no-unresolved */
import { Grid, Skeleton, Stack } from '@mui/material';


// ----------------------------------------------------------------------

export default function EditProfileSkeleton() {
    return (
        <Grid container spacing={3}>
            {/* <Grid item xs={12} md={3} >
                <Skeleton variant="circular" width={180} height={180} style={{ margin: "0 auto 16px" }} />
                <Skeleton variant="text" height={20} width={180} style={{ margin: "0 auto 2px" }} />
                <Skeleton variant="text" height={20} width={100} style={{ margin: "0 auto" }} />
            </Grid> */}
            <Grid item xs={12} md={8}>
                <div className="team-detail-inner profile-inner" style={{ border: "1px solid #dfe3e8", borderRadius: "10px", padding: "15px 25px", marginBottom: "24px" }}>
                    <Stack spacing={0} className="form-first-input-pad">
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 2 }}>
                            <Skeleton variant="text" height={80} width="50%" />
                            <Skeleton variant="text" height={80} width="50%" />
                        </Stack>
                        <Skeleton variant="text" height={80} width="100%" />
                        <Skeleton variant="text" height={30} width={150} />
                        <Skeleton variant="text" height={80} width="100%" />
                        <Skeleton variant="text" height={30} width={150} />
                        <Skeleton variant="text" height={80} width="100%" />
                        <Stack
                            direction={{ xs: 'row', sm: 'row' }}
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            sx={{ mt: 3 }}
                            spacing={2}
                        >
                            <Skeleton variant="text" height={60} width="25%" />
                            <Skeleton variant="text" height={60} width="25%" />
                        </Stack>
                    </Stack>
                </div>
                <div className="team-detail-inner profile-inner" style={{ border: "1px solid #dfe3e8", borderRadius: "10px", padding: "15px 25px", marginBottom: "24px" }}>
                    <Skeleton variant="text" height={50} width={250} />
                    <Skeleton variant="text" height={30} width={150} />
                    <Skeleton variant="text" height={30} width={100} />
                    <Skeleton variant="text" height={30} width={150} />
                </div>
                <div className="team-detail-inner profile-inner" style={{ border: "1px solid #dfe3e8", borderRadius: "10px", padding: "15px 25px", marginBottom: "24px" }}>
                    <Skeleton variant="text" height={50} width={250} />
                    <Skeleton variant="text" height={30} width={150} />
                    <Skeleton variant="text" height={30} width={100} />
                    <Skeleton variant="text" height={30} width={150} />
                </div>
            </Grid>
        </Grid>
    );
}