/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
/* eslint-disable func-names */
/* eslint-disable import/extensions */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Card,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import AddTeamTableRow from 'src/sections/@dashboard/invoice/list/AddTeamTableRow';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import { Container } from '@mui/system';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import AddTeamHeader from 'src/sections/@dashboard/invoice/list/AddTeamHeader';
import AxiosAll from 'src/service/AxiosAll';
import CreateTeamSkeleton from 'src/components/skeleton/createTeamSkeleton';
import Scrollbar from '../../components/Scrollbar';
import useTabs from '../../hooks/useTabs';
import { TableEmptyRows, TableNoData } from '../../components/table';
import useTable, { emptyRows, getComparator } from '../../hooks/useTable';
import { FormProvider } from '../../components/hook-form';

// ----------------------------------------------------------------------

AllocateTeamLead.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD1 = [
  { id: 'status', label: 'Name', align: 'left' },
  { id: 'createDate', label: 'Team', align: 'left' },
  { id: 'createDate', label: '', align: 'left' },
];

const TABLE_HEAD2 = [
  { id: 'status', label: 'Name', align: 'left' },
  { id: 'createDate', label: 'Team ', align: 'left' },
];

export default function AllocateTeamLead({ isEdit, currentUser }) {
  const navigate = useNavigate();
  // Import necessary dependencies and custom hooks
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onCurrentSelectRow,
    currentSelected,
    setCurrentSelected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'status' });

  // Define state variables
  const [tableData, setTableData] = useState([]);
  const [tableCurrentData, setTableCurrentData] = useState([]);
  const { userDetails, token } = useSelector((state) => state.loginReducer);
  const { CreateTeam } = useSelector((state) => state.teamReducer);
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const denseHeight = dense ? 56 : 76;
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const [orderValue, setOrder] = useState(null);
  const [orderByValue, setOrderBy] = useState(null);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState();
  const [currentFilterName, setCurrentFilterName] = useState();
  const [filterData, setFilterData] = useState();
  const [filterService, setFilterService] = useState('all');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [teamFlowdata, setTeamFlowData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [currentLoading, setCurrentLoading] = useState(true);
  // Custom hook for managing tabs
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');
  const location = useLocation();
  const teamDetail = location?.state?.teamDetail;

  const handleFilterName = (filterName) => {
    // setFilterName(filterName);
    // setPage(0);
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterService,
    filterStatus,
    filterStartDate,
    filterEndDate,
  });
  // Check if data is not found based on applied filters
  const isNotFound =
    (!dataFiltered.length && !!search) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterService) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  // Function to fetch member list
  const getMemberList = async () => {
    setLoading(true);
    const res = await AxiosAll(
      'get',
      `/api/user/assign-teamlead-member-list?teamId=${teamDetail?.teamId}&search=${search || ''}`,
      {},
      token
    );
    if (res?.data?.success === true) {
      setTableData(res?.data?.data);
      setTeamFlowData(res?.data?.data);
      setLoading(false);
    } else {
      setTableData([]);
      setLoading(false);
    }
  };

  // Trigger the getMemberList function when the filterName changes
  useEffect(() => {
    getMemberList();
  }, [search]);

  // Function to get members not in the list
  function getNotInList(list1, list2) {
    const result = list1.filter((user) => !list2.some((userInList2) => userInList2.id === user.userId));
    setTableData(result);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchText || '');
      setPage(1);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [setSearch, searchText]);

  // Trigger the getNotInList function when either tableData or tableCurrentData changes
  useEffect(() => {
    if (tableCurrentData?.length && tableData?.length) {
      getNotInList(tableData, tableCurrentData);
    }
  }, [tableData?.length, tableCurrentData?.length]);

  return (
    <Page title="Assign Team Lead" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Manage Teams'}
          subHeading="Skip this step"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Manage', href: '/teams' },
            { name: 'Assign Team Lead' },
          ]}
        />
        <FormProvider>
          <div>
            <Grid container spacing={3} sx={{ py: 3, px: 3, pb: 0 }}>
              <Grid item xs={12} md={6.8}>
                <Typography variant="h4" sx={{ mb: 3 }} style={{ fontWeight: '400' }}>
                  {teamDetail?.teamName} I Assign Team Lead
                </Typography>

                <TextField
                  sx={{ mb: 2 }}
                  className="search_bar"
                  size="small"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Card sx={{ p: 3, boxShadow: 'none' }} className="boder-upper-inner first-section">
                  <Scrollbar>
                    <TableContainer>
                      {loading ? (
                        <CreateTeamSkeleton />
                      ) : (
                        <Table
                          size={dense ? 'small' : 'medium'}
                          className="memberinner-table edit-section-table addbutton_design allocated-team-table "
                        >
                          <AddTeamHeader
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD1}
                            rowCount={tableData.length}
                            numSelected={selected.length}
                            onSort={onSort}
                          />

                          <TableBody>
                            {teamFlowdata?.map((row, index) => (
                              <AddTeamTableRow
                                key={index}
                                row={row}
                                selected={selected.includes(row.userId)}
                                setFilterData={setFilterData}
                                selectedRow={selected}
                                filterName={search}
                                teamNameTeamFlow={teamDetail?.teamName}
                                id={teamDetail?.teamId}
                                onSelectRow={() => onSelectRow(row.userId, 'user')}
                                setTableCurrentData={setTableCurrentData}
                                tableData={tableData}
                                setTableData={setTableData}
                                getMemberList={getMemberList}
                              />
                            ))}
                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                            />
                            <TableNoData isNotFound={isNotFound} />
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>
                </Card>
              </Grid>
            </Grid>
          </div>
        </FormProvider>
      </Container>
    </Page>
  );
}

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item) =>
        item.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.invoiceTo.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  if (filterService !== 'all') {
    tableData = tableData.filter((item) => item.items.some((c) => c.service === filterService));
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter(
      (item) =>
        item.createDate.getTime() >= filterStartDate.getTime() && item.createDate.getTime() <= filterEndDate.getTime()
    );
  }

  return tableData;
}
