/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
// import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Card, Grid, Stack, Typography, Container } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import { FormProvider } from 'src/components/hook-form';
import Label from 'src/components/Label';
import AxiosAll from 'src/service/AxiosAll';

export default function Organisation({ isEdit, currentUser }) {
  // Import necessary dependencies and hooks
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [organizationDetail, setOrganizationDetail] = useState();
  const [loadingPage, setLoadingPage] = useState(false);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Team name is required'),
    Organisation: Yup.string().required('Function in Organisation* is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const { watch } = methods;

  const values = watch();

  // Function to fetch customer details
  const getCustomerDetail = async () => {
    setLoadingPage(true);
    const res = await AxiosAll(
      'get',
      `/api/Company/getcustomerdetail?companyId=${userDetails?.data?.data?.companyId}`,
      {},
      userDetails?.data?.token
    );

    if (res?.status === 200) {
      setOrganizationDetail(res.data.data);
      setLoadingPage(false);
    }
    setLoadingPage(false);
  };

  // Fetch customer details on component mount or when userDetails changes
  useEffect(() => {
    getCustomerDetail();
  }, [userDetails]);

  return (
    <Page title="Team Details" className="manage-member">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Manage Organisation"
          links={[
            { name: 'TripShift Platform', href: '/dashboard' },
            { name: 'Organisation' },
            { name: 'Manage Organisation' },
          ]}
        />
        <LoadingOverlay
          // eslint-disable-next-line react/jsx-boolean-value
          active={loadingPage}
          spinner
        >
          <FormProvider methods={methods}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <div className="team-detail-inner organisation-team">
                  <fieldset>
                    <legend>
                      <Typography variant="h4" sx={{ textTransform: 'capitalize' }} className="heading12_edit">
                        Organisation Details
                      </Typography>
                    </legend>

                    <Card sx={{ boxShadow: 'none', pt: 1, backgroundColor: 'transparent' }}>
                      {isEdit && (
                        <Label
                          color={values.status !== 'active' ? 'error' : 'success'}
                          sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                        >
                          {values.status}
                        </Label>
                      )}
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                        <Grid container spacing={3}>
                          {/* <Grid item xs={12} md={4}>
                            <Box sx={{ mb: 5, mt: 2 }}>
                              <div className="detail-page-profile">
                                <Avatar src={organizationDetail?.photoURL} />
                              </div>
                            </Box>
                          </Grid> */}
                          <Grid item xs={12} md={8}>
                            <Box sx={{ mb: 5, pl: 2 }}>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'capitalize' }}
                                className="heading12_edit"
                              >
                                Name of Organisation
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {organizationDetail?.companyName !== null
                                  ? organizationDetail?.companyName
                                  : 'not yet provided'}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Primary location
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {organizationDetail?.address1 ? organizationDetail?.address1 : 'not yet provided'}
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {organizationDetail?.address2 ? organizationDetail?.address2 : null}
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {organizationDetail?.town ? organizationDetail?.town : null}
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {organizationDetail?.stateCounty ? organizationDetail?.stateCounty : null}
                              </Typography>
                              <Typography variant="body1" sx={{ color: 'text.secondary' }} className="heading12_edit">
                                {organizationDetail?.postCode ? organizationDetail?.postCode : null}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {organizationDetail?.country ? organizationDetail?.country : null}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Primary Admin Name
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {organizationDetail?.primaryAdminName !== null
                                  ? organizationDetail?.primaryAdminName
                                  : 'not yet provided'}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Company size
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {organizationDetail?.companysize !== null
                                  ? organizationDetail?.companysize
                                  : 'not yet provided'}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Company Sector
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {organizationDetail?.companySector
                                  ? organizationDetail?.companySector
                                  : 'not yet provided'}
                              </Typography>
                              <Typography
                                variant="overline"
                                sx={{ textTransform: 'initial' }}
                                className="heading12_edit"
                              >
                                Licences Remaining
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: 'text.secondary', mb: 2 }}
                                className="heading12_edit"
                              >
                                {organizationDetail?.remainingLicence}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Card>
                  </fieldset>
                </div>

                <div className="manage-profile-fieldset">
                  <fieldset style={{ marginTop: '2rem' }}>
                    <legend style={{ textAlign: 'left' }}>
                      <Typography variant="h4" sx={{ textTransform: 'capitalize' }} className="heading12_edit">
                        TripShift Details
                      </Typography>
                    </legend>
                    <Card sx={{ boxShadow: 'none', pt: 2, backgroundColor: 'transparent' }}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
                        <Box sx={{ pl: 1.5 }}>
                          <Typography
                            variant="overline"
                            sx={{ textTransform: 'capitalize' }}
                            className="heading12_edit"
                          >
                            Tripshift Representative
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2 }}
                            className="heading12_edit"
                          >
                            Sebastien Thomas
                          </Typography>
                          <Typography
                            variant="overline"
                            sx={{ textTransform: 'capitalize' }}
                            className="heading12_edit"
                          >
                            Email Address
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: 'text.secondary', mb: 2.5, textDecoration: 'underline' }}
                            className="heading12_edit"
                          >
                            Sebastien@tripshift.co.uk
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ mb: 2 }}
                            className="heading12_edit"
                            style={{ color: '#919EAB' }}
                          >
                            If you wish to edit your organisation’s details, please contact your TripShift admin
                          </Typography>
                        </Box>
                      </Stack>
                    </Card>
                  </fieldset>
                </div>
              </Grid>
            </Grid>
          </FormProvider>
        </LoadingOverlay>
      </Container>
    </Page>
  );
}
