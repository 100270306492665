import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import ReactApexChart from 'react-apexcharts';
import Card from '@mui/material/Card';
import { CardHeader } from '@mui/material';

// ----------------------------------------------------------------------

export default function ChartDataModel({ title, subheader, chartData, categoriesData, ChartColor, chart, ...other }) {
  const theme = useTheme();

  const [seriesData, setSeriesData] = useState('Year');

  // calculate dynamic bar width
  function calculateBarWidth(dataPoints) {
    const minimumWidth = 10; // Minimum width you want
    const calculatedWidth = Math.max(minimumWidth, minimumWidth / dataPoints);
    return `${calculatedWidth}px`;
  }

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 370,
    },
    plotOptions: {
      bar: {
        columnWidth: calculateBarWidth(categoriesData?.length),
        distributed: true,
        horizontal: false,
        height: 'auto',
        endingShape: 'rounded',
        borderRadius: 5,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },

    series: chartData,
    xaxis: {
      categories: categoriesData,
      lines: {
        show: true,
      },
      labels: {
        style: {
          colors: '#919EAB',
          fontSize: '12px',
        },
      },
    },
    colors: ChartColor, // Specify default colors
    grid: {
      borderColor: '#D0AEFF',
      strokeDashArray: 2,
      opacity: 0.5,
      row: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5,
      },
      column: {
        colors: ['transparent', 'transparent'],
        opacity: 0.5,
      },
    },
  };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <ReactApexChart options={chartOptions} series={chartData} type="bar" height={370} />
    </Card>
  );
}

ChartDataModel.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
