/* eslint-disable import/order */
import PropTypes from 'prop-types';

// @mui
import { Box, Card, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
// utils
import { fNumber, fPercent } from '../../../../utils/formatNumber';
// components
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------

AppWidgetSummaryNew.propTypes = {
  chartColor: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.number).isRequired,
  percent: PropTypes.number.isRequired,
  percent1: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
  // color: PropTypes.string,
};

export default function AppWidgetSummaryNew({
  title,
  title1,
  title2,
  percent,
  percent1,
  total,
  chartColor,
  chartData,
  sx,
  ...other
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const MemberPage = () => {
    navigate('/members');
  };
  const chartOptions = {
    colors: [chartColor],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `@${seriesName}`,
        },
      },
      marker: { show: false },
    },
  };

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3, ...sx }} {...other} className="third-card">
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h3" className="main_heading" sx={{ mt: 2 }}>
          {fNumber(total)}
        </Typography>
        <Typography variant="subtitle2" className="heading_14 card-subheading">
          {title}
        </Typography>
        <Typography variant="h3" className="main_heading greenclo" color="success" sx={{ mt: 2 }}>
          {fPercent(percent)}
        </Typography>
        <Typography variant="subtitle2" className="heading_14 card-subheading">
          {title1}{' '}
        </Typography>
        <Typography variant="h3" className="main_heading yelloclo " sx={{ mt: 2 }}>
          {' '}
          {fPercent(percent1)}
        </Typography>
        <Typography variant="subtitle2" className="heading_14 card-subheading">
          {title2}
        </Typography>
        {percent && percent1 && total ? null : (
          <Typography
            variant="body1"
            className="heading_14 greenclo textstyle"
            onClick={MemberPage}
            sx={{ textAlign: 'end', mb: 1.5, mt: 2 }}
          >
            Manage members
          </Typography>
        )}{' '}
      </Box>
    </Card>
  );
}
